// 简体中文语言包
export default {
    testData: '测试',
    language: '简体中文',
    route: {
      home: '首页',
      temp: '模板',
      serve: '服务优势',
      ourselves: '关于我们',
      recruitment: '人才招聘'
      },
    mailbox: '邮箱',
    price:'价格',
    flow: '流量',
    quantity: '数量',
    applyNow: '立即申请',
    yaer: '年',
    month: '月',
    day: '日',
    a: '个',
    customCharge:'客制收费',
    customization: '客制化',
    languageSetting: '语言设置',
    viewDetails: '查看详情',
    viewTemp: '查看模板',
    knowDetails: '了解详情',
    LotteryTrial: '彩票试玩',
    GoToTrialPlay: '前往试玩',
    businessRelation: '商务联系',
    GoSports: '前往GO体育',
    contactUs: '联系我们',
    pleaseLookForwardTo: '试玩功能 敬请期待',
    theme: '主题',
    howToCharge: '请输入您的问题',
    contactEmail: '联系邮箱',
    yourContactEmail: '请输入您的邮箱',
    problemDescription: '问题描述',
    yourQuestion: '请描述您的问题',
    definiteSendOut: '确定送出',
    homePageLang: {
      productTitle: '综合生态产品',
      productIntroduce: 'GO包网多年技术沉淀，多款产品共筑产品矩阵',
      firmTitle: '合作厂商',
      firmIntroduce: 'GO包网多年技术沉淀，多款产品共筑产品矩阵',
      clientTitle: '服务客户',
      clientIntroduce: '以“客户成功”为而核心，真正为客户创造价值'
    },
    servePageLang: {
      funTitle: '服务',
      advantageTitle:'优势',
      funDataList: [
        {
          title: '最丰富的三方游戏'
        },
        {
          title: '支持多国语言'
        },
        {
          title: '全终端平台系统'
        },
        {
          title: '灵活全面自动代理系统'
        },
        {
          title: '大数据AI分析'
        },
        {
          title: '量身定制专属设计'
        }
      ],
      advantageDataList: [
        {
          title: '7*24小时监控'
        },
        {
          title: '多点式云资料'
        },
        {
          title: '可疑风险预警'
        },
        {
          title: '国安级防火墙'
        },
        {
          title: 'SSL加密凭证'
        },
        {
          title: '数据加密备份'
        },
        {
          title: '抗DDoS攻击'
        },
        {
          title: '全方位平台报表管理'
        }
      ]
    },
    tempPageLang: {
      AG: 'AG',
      Macau: '澳门',
      physicalEducation: '体育',
      chessAndCards: '棋牌',
      lottery: '彩票',
      esports: '电竞',
      catchfish: '捕鱼',
      realPerson: '真人',
      electron: '电子',
      //  子内容
      Kaifa: '凯发',
      J9: 'J9',
      Hutchison: '和记',
      newLisboa: '新葡京',
      GalaxyMacau: '澳门银河',
      SunCity: '太阳城',
      MGMMirage: '美高梅',
      Venetian: '威尼斯人',
      GoldenSands: '金沙',
      NewCentury: '开元',
      Yunyu: '云游',
      TangDynasty: '大唐',
      TianchengLottery: '天成彩票',
      PhoenixLottery: '凤凰彩票',
      apricot: '杏彩',
      panAsia: '泛亚',
      LightningFire: '雷火',
      OBfishing: 'OB捕鱼',
      BGfishing: 'BG捕鱼',
      skyhunter: '天际猎人',
      FishingKing3D: '捕鱼王3D',
      FishingKing: '捕鱼王',
      FishingPark: '捕鱼乐园',
      OBRealPerson: 'OB真人',
      AGRealPerson: 'AG真人',
      BGRealPerson: 'BG真人',
      OBelectron: 'OB电子',
      AGelectron: 'PG电子',
      LGDelectron: 'LGD电子'
    },
    ourselvesPageLang: {
      title: 'GO集团',
      contentText1: 'GO集团全称Great Online Group，志在打造博彩业顶尖包网团队。',
      contentText2: '十年经验淬炼，亚洲一流的项目方案解决团队，积极寻求创新技术及理念。拥有专业的产品团队规划需求，常年全天候不断探索市场发展脉络，提供近万台棋牌游戏娱乐、老虎机、体育真人等平台，并提供线上存取款。一键操作，提供核心运营数据，多维分析玩家数据、渠道数据、成本数据和收益数据，帮助营销人员做出正确的决策。',
      contentText3: '拥有自主产权的防劫持技术(已有专利申请)，一键批量审核，自动结算，活动发布简单高效 。整合同步账号和资料传输，达到随时随地不间断娱乐的享受概念。所有网站软硬件资源均独享，且配置多副本，智慧切换，给您一个可靠、稳定的环境。顶尖系统，因非凡而与众不同。期待您的垂询！',
      title2: '里程碑'
    },
    sonArticleContent: {
        goBao: {
          title: 'GO包网',
          homeText: '我们以技术为先导，不断推广先进适用技术的应用，推动客户业务成长，实现共创、共营、共赢',
          rowText1: 'GO博包网项目立项于2012年，累计已发展10余年，累计服务客户上百+位，技术团队目前已有百 位+ ，24小时保障项目稳定运行。开发出60+个不同类型模版，满足市场90%的需求。如您的想法，市面模板无法满足，我们将会从交互设计、界面设计到功能定制，我们提供全方位的解决方案，包括PC、H5、IOS签、服务器、域名等一条龙服务。',
          rowText2: '提供中文、英文、越南文等多国语言版本，以及任意语言定制服务；采用纯原生技术开发，彻底解决充值资金丢失、流量劫持等问题，保障网站安全；200多种游戏选择，无死角安全监控，全方位保驾护航！',
          netPrice: {
            title: '包网价格',
            openingCharge: {
              title: '开版费',
              dataList: [
                {
                  content: '专业团队技术配置指导开站事宜'
                },
                {
                  content: '高级UI设计师协助处理页面展示效果'
                },
                {
                  content: '专业技术团队高效快捷部署开站'
                },
                {
                  content: '免费对接三方支付、短信平台等'
                },
                {
                  content: '运维团队项目部署，域名解析等'
                }
              ]
            },
            BasicCharge: {
              title: '基本收费',
              content1: '最终洽谈后如期交付',
              content2: '原生APP、PC端、H5端',
              content3: '（定制化平台或者需求及时联系商务哦）',
            }
          },
        productAdvantage: {
          title: '产品优势',
          dataList: [
            {
              title: '快速定制高速产品',
              content: '高端设计品牌服务，技术过硬，更是行业新秀。只要你有流量，我们就能带您变现！'
            },
            {
              title: '优质的产品体验',
              content: '行业内最极致移动端三端齐全，多端加密告别劫持，全民推广无忧。'
            },
            {
              title: '运营服务7*24小时',
              content: '7*24小时专业客服，风控团队财务团队ACB全程对接，分秒必争。'
            }
          ]
        }
      },
      integrated: {
        title: '综合色站',
        homeText: '国产电影福利乱色，日韩av高清，国产电影比较色 日韩欧美国产免费成人高清在线AV电影视频'
      },
      video: {
        title: '视讯综合',
        homeText: '提供业界最专业的美女视讯',
        rowText1: '视讯综合给玩家带来前所未有的娱乐体验，是您提升业务的必备产品。为此GO集团与世界顶端的网络游戏软件供应商一起合作，让娱乐运营商可在自己的白标网页添加最新的内容。同时我们为玩家提供不同类型的游戏体验，无与伦比的图形和先进的技术，创造一种身临其境和互动的经验来吸引玩家的注意力。以高规格打造的国际厅配备最先进的设备以及富丽堂皇的装潢，为玩家打造绝佳真人视讯游戏体验。游戏选择多样化，包括备受欢迎的百家乐丶骰宝丶轮盘等，加上全天侯监控管理，为玩家提供稳定而流畅的游戏服务。',
        rowText2: '',
        dataList: [
          {
            pictitle: ' 经典百家乐',
            title: ' 经典百家乐',
            cont: '百家乐是世界上其中一款最受欢迎的游戏。首创六张牌先发百家乐，确保游戏公平，令玩家对平台的信心大增。另外，我们亦独家研发特色LED桌台竞咪百家乐、25秒极速百家乐，给予玩家一种置身实地赌场的真实快感。'
          },
          {
            pictitle: '竞咪百家乐',
            title: '竞咪百家乐',
            cont: '竞咪互动百家乐强势推出手动切牌功能，当局额度最大的玩家，即可享此限时20秒的优越功能。透过局部放大的视频及牌上显示的虚线以辅助切牌，确保牌局绝对公平公正，同时让玩家自主性大增，真实感比拟实地赌场。'
          },
          {
            pictitle: '保险百家乐',
            title: '保险百家乐',
            cont: '保险百家乐是近年最受玩家欢迎的一个新功能，顾名思义在游戏中加入保险元素，除了不影响百家乐游戏的结果外，同时亦能保障玩家不至于输掉所有的押注金额。'
          },
          {
            pictitle: '龙宝百家乐',
            title: '龙宝百家乐',
            cont: '龙宝会根据所投注一方胜出对方点数的情况，赔率会有所不同，最高彩金为30倍，且所赢得彩金不抽水。若投注其中一方获胜，并且符合下列两种之一即可获得派彩。'
          },
          {
            pictitle: 'VIP百家乐',
            title: 'VIP百家乐',
            cont: 'VIP包桌后可自行控制游戏节奏，尽享更换荷官、飞牌及换靴等优越功能，成就更高级及自主的娱乐享受。'
          },
          {
            pictitle: '龙虎',
            title: '龙虎',
            cont: '龙虎 加入真人游戏平台後，让更多玩家於电脑以及任何移动装置连接我们的娱乐平台，感受犹如置身现场的享乐新体验，一试自己的运气！'
          },
          {
            pictitle: '牛牛',
            title: '牛牛',
            cont: '牛牛 又名斗牛，是一款流行于广东、广西、湖南以及江浙一带的传统棋牌游戏。其中一家为庄家，其余为闲家，发完牌后马上开牌比牌，所有闲家与庄家一一进行比较确定输赢。游戏节奏紧凑刺激，规则简单。'
          },
          {
            pictitle: '21点',
            title: '21点',
            cont: '21点源于法国，只要玩家的扑克牌拥有最高21点数并大于荷官点数便获胜。牌局的最终目的，是要玩家手上拥有的牌总点数达到21点（或最接近21点），然后和荷官比大小。'
          },
          {
            pictitle: '炸金花',
            title: '炸金花',
            cont: '炸金花（诈金花）又称赢三张，是在全国广泛流传的一种民间多人纸牌游戏。玩家以手中的三张牌比大小，具有独特的比牌规则。'
          },
          {
            pictitle: '轮盘',
            title: '轮盘',
            cont: '最逼真的真人轮盘游戏。透过实时传递的现场画面，加上目不暇给的轮盘丶荷官熟练而专业的技巧，都为玩家缔造一个公平公正的博彩游戏。本游戏采用国际标准的单零轮盘。 玩家可以买单一数字或赌桌上的数字组合。'
          },
          {
            pictitle: '骰宝',
            title: '骰宝',
            cont: '真人骰宝游戏开拓出崭新领域，付予玩家前所未有的全新感受，令人乐而忘返。也叫赌大小，以猜测骰子开出的点数或是点数总合的博彩。多元化的投注种类及吸引的赔率，极之受玩家欢迎。'
          },
          {
            pictitle: '多台游戏',
            title: '多台游戏',
            cont: '在这里，你可按自己喜好选择不同类型的游戏并同时进行下注。你只须按‘选择桌台’按钮再安排桌台显示的位置便可。'
          }
        ]
      },
      CDN: {
        title: 'CDN',
        homeText: '透过互联网互相连接的电脑网络系统，利用最靠近每位用户的 服务器，更快、更可靠地将图片、视频、应用程序及其他文件 发送给用户',
        rowText1: '作为有着领先卓越技术的CDN供应商，GO包网早在2012年开始就构建了下一代内容交付架构。我们的使命是开发和设计可供尽可能多的人访问的内容交付解决方案。 不仅仅是性能、安全方面的CDN的基础服务，简单易用和高性价比对于方能CDN的用户亦是非常重要。我们的CDN只需点击几下即可让您入门，同时还提供多种配置选项，简单易用可以让您更专注于核心业务，也正是这样的简单易用性让我们的产品具有极高的性价比，在竞争对手中脱颖而出。',
        rowText2: '',
        dataList: [
          {
            title: 'CDN内容分发',
            contentText: 'CDN在五大洲拥有三十多个数据中心，其中国内我们拥有三线高防服务器机房，可以缓存您网站中的静态内容，可自动将用户指向最快的节点。',
          },
          {
            title: '网站保护',
            contentText: '高防CDN网站保护可使您的网站访问质量得到保护，可用性大幅提高，在面对DDoS、CC、劫持等常规攻击的时候，无需担心。',
          },
          {
            title: 'Web应用防火墙',
            contentText: '提供精确的网站指纹识别技术和高效的处理引擎，结合实时更新的高防防御策略，覆盖OWASP主流的攻击类型，保护您的网站免受黑客攻击。',
          },
          {
            title: '访问优化',
            contentText: 'CDN包含一套Web优化功能，可将您的网页压缩50%以上，优化包括最新的Web标准，结合我们的高防CDN，可大幅优化用户访问速度。',
          },
          {
            title: '无上限的DDos防御',
            contentText: '无论攻击的大小，类型或持续时间如何，方能都会为您在节点边缘抵挡任何DDOS攻击，并且没有上限。',
          },
          {
            title: '人机交互',
            contentText: '具备大数据智能业务防御能力，支持多维度自定义精准访问控制、配合人机识别和频率控制等对抗手段，可智能高效的过滤垃圾访问，有效防御 CC 攻击。',
          }
        ],
        serviceItem: '服务项目',
        chargeDetails: '收费详情',
        setMealTitle: '套餐与定价',
        setMealText1: '所有版本接可享受无限制接入三级域名，不额外收费!节点遍布东南亚国家无备案号域名可从香港、韩国、日本进入中国',
        setMealText2: '流量超用收费：● 每1GB收1.12 USD   ● USDT支付手续费为1%',
        dataTypeList: [
          {
            title: '创业版',
            titleExplain: '适用于5人以内的团队，享受100GB/月',
            scopeText1: '适用人数少的创业团队',
            scopeText2: '二级域名适用数量10个',
            scopeText3: '价格适用'
          },
          {
            title: '专业版',
            titleExplain: '适用于中小型团队，满足多人协作享受100GB/月',
            scopeText1: '适用于中小型的团队',
            scopeText2: '二级域名适用数量100个',
            scopeText3: '价格适用'
          },
          {
            title: '商业版',
            titleExplain: '适用于中型公司，满足个性化需求。',
            scopeText1: '适用于中型上升级别公司',
            scopeText2: '二级域名适用数量300个',
            scopeText3: '专业配套'
          },
          {
            title: '企业版',
            titleExplain: '适用一对一私人定制，满足个人企业独一无二的私人定制。',
            scopeText1: '适用于私人定制',
            scopeText2: '二级域联系客服咨询',
            scopeText3: '私人定制'
          }
        ]
      },
      etp: {
        title: '产品孵化',
        homeText: '一个产品从0到1的启动与实施',
        rowText1: '集团创业中心为中小型创业者成立的专业服务机构，我们免费提供指导技术业务、管理咨询等帮帮方案。',
        rowText2: '',
        dataList: [
          {
          title: '产品分析',
          contentText: '包含需求分析、市场分析、竞争对手分析、法规政策分析。拟定初步设计方案并提出初步开发周期计划。',
          },
          {
          title: '产品评估',
          contentText: '业内部技术负责人对产品申 请进行审核评估，进行项目初步投资分析。',
          },
          {
          title: '项目经理',
          contentText: '据业界丰富经验的项目经理，予以产品孵化过程的专业管理，将产品价值最大化。',
          },
          {
          title: '专业开发团队',
          contentText: '拥有产业丰富开发经验团队，以最短时间开发，达到最高品质的产品。',
          },
        ]
      },
      software: {
        title: '通讯软件',
        homeText: '原生通讯软件开发，提供使用者更好的聊天体验',
        rowText1: '客户端是自由及开放源代码软件，但服务端是专有软件。用户可以相互交换加密与自毁消息，发送照片、视频等所有类型文件。官方提供手机版、桌面版和网页版等多种平台客户端',
        rowText2: '',
        dataList: [
          {
              title: '贴上',
              text: '即时动态记事贴文，支援多种文件、照片或文字！',
          },
          {
              title: '阅后即焚',
              text: '提前安排寄送时间与内容，定时自动发送',
          },
          {
              title: '快速切换聊天室',
              text: '快速切换聊天室',
          },
          {
              title: '已读资讯',
              text: '观看讯息读取状态',
          },
          {
              title: '红包',
              text: '群发、私聊红包发送',
          },
          {
              title: '自动语音播放',
              text: '手机靠近即可听取语音讯息 ',
          },
        ]
      },
      waiter: {
        title: 'GO客服',
        homeText: '网络安全就是为了防范这种信息盗窃和商业竞争攻击所采取的措施',
        rowText1: 'GO客服系统是集软件、推广、营销、管理为一体的高效客服系统。 即时聊天快速接入，访客详细信息，智能机器人服务，多客服多平台对话分配，数据分析保存历史会话等全面稳定的功能。 系统专为海外客户优化，Tpds级防护，AI智能防御攻击。',
        rowText2: '',
        dataList: [
          {
              title: '整合简单 快速部署',
              text: '您只需要简单的几步可以使您的网站拥有客服功能，为您的客户提供最优服务。'
          },
          {
              title: '界面简洁 操作方便',
              text: 'GO客服系统，界面简洁，客服和管理员可以快速学习和使用，系统流程高效、快速稳定。',
          },
          {
              title: '安全高效 私有化部署',
              text: 'GO系統，全面支持私有化部署，幫助你的企業可以更加安全高效的進行客服作業。',
          },
          {
              title: '强大后台 高效管理',
              text: 'GO系统整合信息管理和客服管理，提高团队效率，工作质量，同时兼顾客户体验充分了解您的客户。',
          },
          {
              title: '服务保障 强大后盾',
              text: '客服系统为您提供优质的售后服务，精英团队为您解决使用中的一切困难，保障您使用的舒适稳定。',
          },
          {
              title: '多域名多平台',
              text: '全面支持多域名多平台简单部署，让您高效掌握全局，轻松管理多个客服。',
          },
      ]
      },
      //新增修改内容
      sport: {
        title: '体育API',
        homeText: '我们主要提供足球、篮球以及其他一些流行的体育比赛的数据。除了足球和篮球之外，我们还支持棒球、排球和羽毛球等多种体育项目。',
        rowText1: '主要提供足球、篮球以及其他一些流行的体育比赛的数据。除了足球和篮球之外，我们还支持棒球、排球和羽毛球等多种体育项目。 我们的API可以为您提供包括比赛得分、赛事数据等多种信息。使用我们的API，您可以轻松地获取最新的比赛数据，以及历史比赛数据和各种有用的统计信息。 无论您是体育网站、体育APP，还是其他体育相关企业，我们都能为您提供最全面、最准确的体育数据服务，帮助您提高用户体验和业务价值。',
        rowText2: '',
        subhead: '提供游戏类型',
        description: '',
        dataList: [
          {
            title: '英格兰超级联赛',
            cont: '通常被称为英超，是英格兰足球最高等级的赛事类别亦是世上最顶级的足球联赛之一，由英格兰足球总会于1992年2月20日确立，自1992–93球季面世'
          },
          {
            title: '意大利足球甲组联赛',
            cont: '意大利足球联赛系统的第1级别 ，亦是职业联赛的最高级别，联赛系统的最高级别和意大利顶级足球联赛，由意大利足球总会所管理，意甲职业联盟营运。'
          },
          {
            title: '法国足球甲级联赛',
            cont: '法国足球联赛系统的第1级别，亦是职业联赛的最高级别，联赛系统的最高级别和法国顶级足球联赛，由法国足球协会监管下的法国职业足球联赛所负责监督、组织及管理。'
          },
          {
            title: '德国足球甲级联赛',
            cont: '德国足球最高等级的赛事类别，由德国足球协会于1962年7月28日在多特蒙德确立，自1963-64赛季面世。'
          },
          {
            title: '西班牙足球甲级联赛',
            cont: '西班牙足球联赛系统的第1级别，亦是职业联赛的最高级别，联赛系统的最高级别和西班牙顶级足球联赛。目前西甲有 20 支球队，最后三名会降至西班牙足球乙级联赛。'
          },
          {
            title: 'FIFA世界杯',
            cont: '成立于 1904 年，负责监督比利时、丹麦、法国、德国、荷兰、西班牙国家协会之间的国际比赛、瑞典和瑞士。总部设在瑞士苏黎世，其成员现在包括211个国家协会。'
          },
          {
            title: '欧洲冠军联赛',
            cont: '欧洲足联主办的年度足球比赛，代表欧洲俱乐部足球最高荣誉，组织完善高效，被誉为全世界最高竞技水平的俱乐部杯赛，亦是世界上奖金最高的足球赛事和体育赛事之一。'
          },
          {
            title: '中国足球协会超级联赛',
            cont: '中国足球协会所主办的最高等级职业足球联赛。该联赛开始于2004年，脱胎自原中国足球甲级A组联赛。'
          },
          {
            title: 'DOTA2国际赛',
            cont: 'Dota 2开发商威乐公司举行的电子竞技比赛，比赛将由十六个受邀请的队伍完成。这项赛事在2011年5月25日科隆国际游戏展上首次进行，也是到今为止奖金额度最高的比赛。'
          },
          {
            title: '英雄联盟全球总决赛',
            cont: '2017年开始，英雄联盟全球总决赛扩大规模。外卡巡回赛被取消，改为由扩大的英雄联盟全球总决赛直接包含原外卡巡回赛的功能。'
          },
          {
            title: '穿越火线职业联赛',
            cont: '穿越火线在中国大陆地区的顶级赛事。由腾讯游戏主办，量子体育承办。自2012年首届联赛举办以来，CFPL已经创办了15个赛季。'
          },
          {
            title: 'CS:GO超级联赛',
            cont: 'CS:GO超级联赛。第一届于2016年3月分开启。是由国家体育总局体育信息中心主办，上海华奥电竞信息科技有限公司承办。'
          }
        ]
      },
      // 棋牌api
      chessAndCard: {
        title: '棋牌API',
        homeText: '提供多种经典的棋牌游戏，包括牛牛、炸金花、龙虎、跑得快、扫雷等游戏。玩法创新，更加注重游戏的安全性和可靠性。',
        rowText1: '提供多种经典的棋牌游戏，包括牛牛、炸金花、龙虎、跑得快、扫雷等游戏。我们的游戏界面设计美观、玩法创新，更加注重游戏的安全性和可靠性。无论您身在何处，只需要一部手机或电脑，就可以轻松进入我们的游戏大厅，和其他玩家一起竞技。',
        rowText2: '',
        subhead: '提供游戏类型',
        description: '',
        dataList: [
          {
            title: '牛牛',
            cont: '游戏中使用一副共五十四张的扑克牌，每个玩家手中分配五张牌。玩家需要将手中的五张牌分成三张牌和两张牌的两组，使得三张牌的点数之和是10的倍数，剩余两张牌的点数之和尽可能大。'
          },
          {
            title: '炸金花',
            cont: '游戏使用一副共五十四张的扑克牌（包括两个大小王），每个玩家手中分配三张牌。牌型从大到小依次为：豹子、顺金、金花、顺子、对子以及单张牌。'
          },
          {
            title: '斗神兽',
            cont: '游戏使用五十四张牌(不含大小王)，以青龙、白虎、朱雀、玄武四个闲家板块进行游戏，发牌为四板块与庄家发一张牌比较。'
          },
          {
            title: '三公',
            cont: '每个玩家手中分配三张牌，通过比较牌面大小来决定胜负。运用推断和心理战术，也有一定的挑战性。'
          },
          {
            title: '跑得快',
            cont: '戏中使用一副共五十四张的扑克牌，包括大小王。由一名玩家出牌，其他玩家需要依次出牌并按照规则进行跟牌或不跟牌。需要一定的运气和策略，是一种富有竞争性和娱乐性的卡牌游戏。'
          },
          {
            title: '十三水',
            cont: '游戏中使用一副共五十四张的扑克牌，包括大小王。牌型分为豹子、顺子、同花顺、同花、对子、高牌六种。'
          },
          {
            title: '斗地主',
            cont: '一副共五十四张的扑克牌（包括两个大小王），留下三张底牌，底牌确定地主前不能看，等待确定地主后，底牌归地主，且明示底牌。'
          },
          {
            title: '奔驰宝马',
            cont: '游戏有四个汽车品牌的八个布同赔率的下注板块，可在八个板块任意下注。'
          },
          {
            title: '麻将系列',
            cont: '共一百一十二张牌，游戏一开始只有庄家可以得到十四张牌，其余十三张牌。'
          },
          {
            title: '牌九',
            cont: '当前有”锅”、”大牌九”、”小牌九”、”鬼子和天九王”四种玩法。玩家需要具备一定的策略性和判断力，以便在游戏中获胜。'
          },
          {
            title: '百家乐',
            cont: '采用八副牌共416张进行游戏，开局庄闲个发两张牌，依据普牌规则决定是否补牌，补牌结束将所有牌点数加总取个位数，更接近9一方获胜。'
          },
          {
            title: '扫雷',
            cont: '有”庄与闲”、"发红包"、”抢红包”、”中雷赔付”四种玩法，扫雷游戏通常具有一定的游戏性和竞争性'
          }
        ],
        dataListTwo: [
          {
            title: '百人三公'
          },
          {
            title: '抢庄经典牛'
          },
          {
            title: '抢庄点子牛'
          },
          {
            title: '百人牛牛'
          },
          {
            title: '锅底点子牛'
          },
          {
            title: '无限公牛'
          },
          {
            title: '百人斗神兽'
          },
          {
            title: '锅底经典牛'
          },
          {
            title: '跑得快'
          },
          {
            title: '百人龙虎'
          },
          {
            title: '炸金花'
          },
          {
            title: '三公暗一'
          },
          {
            title: '奔驰宝马'
          },
          {
            title: '三公全暗'
          },
          {
            title: '三公比金花'
          },
          {
            title: '百家乐'
          },
          {
            title: '红中麻将(两人)'
          },
          {
            title: '红中麻将(四人)'
          }
        ]
      },
      // 彩票API
      lottory: {
        title: '彩票API',
        homeText: 'API包含了哈希彩、快开彩、境外彩及低频彩，可以帮助用户实现准确、高效、便捷的彩票出租需求。',
        rowText1: 'API包含了哈希彩、快开彩、境外彩及低频彩，可以帮助用户实现准确、高效、便捷的彩票出租需求。我们的API提供了多种数据接口，可根据用户的需求进行定制化的数据服务。我们的API数据全面、准确、及时，可以帮助用户了解市场动态、分析市场趋势、优化彩票出租业务。',
        rowText2: '',
        dataList: [
          {
            title: '哈希彩',
            content: '哈希值彩票是一种基于区块链技术的全新彩票玩法。开奖过程也是通过区块链技术进行的，系统会随机选取一条区块链，由于区块链的不可篡改性，这种彩票玩法可以保证彩票结果的公平和公正。'
          },
          {
            title: '快开彩',
            content: '是一种基于数字技术的高频彩票游戏，玩法类似于传统彩票的“即开型”游戏。开奖时间非常快，通常在几分钟内就能得到开奖结果。'
          },
          {
            title: '境外彩',
            content: '是指在中国以外的其他国家地区开展的彩票游戏。这些彩票游戏可能会提供比本地彩票更高的奖金、更多的玩法以及更多的选择。'
          },
          {
            title: '低频彩',
            content: '该类游戏通常是通过购买数字或符号等形式的彩票，在开奖时，根据所选数字或符号与开奖结果的匹配程度，来决定是否中奖的一种游戏。'
          }
        ]
      },
      // 老虎机API
      tiger: {
        title: '老虎机API',
        homeText: '我们的API，您可以轻松地创建自己的老虎机游戏。',
        rowText1: 'API是由我们自己的团队进行研发的，具有很高的灵活性和可扩展性。使用我们的API，您可以轻松地创建自己的老虎机游戏。',
        dataList: [
          {
            title: '主题老虎机',
            contentText: '基于各种不同的主题，包括古代神话、动物王国和历史时期等等。'
          },
          {
            title: '多线老虎机',
            contentText: '单次游戏中，玩家可用多条赔付线增加获胜机会。在任何一条赔付线上命中符号即可获得奖励。'
          },
          {
            title: '传统老虎机',
            contentText: '要在转轮上命中一组匹配的符号来获得奖励。通常有几种不同的符号和赔付线类型可以在游戏中使用。'
          }
        ]
      },
      // 彩票信用网
      credit: {
        title: '彩票信用网',
        homeText: '支持多种彩票类型，包括哈希彩、极速时时彩、极速赛车及六合彩等等。',
        rowText1: '支持多种彩票类型，包括哈希彩、极速时时彩、极速赛车及六合彩等等。不同的彩票类型提供了不同的玩法和奖金，用户可以根据自己的喜好和投注策略选择不同的彩票种类和投注方式。',
        dataList: [
          {
            title: '香港六合彩',
            contentText: '是香港地区唯一的合法彩票。由香港赛马会以香港马会奖券有限公司的名义开彩。开奖日期不固定，于北京时间晚上9:30开奖。',
          },
          {
            title: '幸运飞艇',
            contentText: '幸运飞艇是由马耳他共和国瓦莱塔福利联合委员会独家发行。每日开奖180期，每5分钟开奖1次。每期开奖结果都会通过多种渠道进行公示，确保公正公平。',
          },
          {
            title: '加拿大卑斯',
            contentText: '开奖结果来源于加拿⼤彩票公司BCLC的KENO开奖数据。开奖时间夏令与冬令时间不同，每3.5分开奖，每日开奖395期。',
          },
          {
            title: '斯洛伐克',
            contentText: '采用斯洛伐克共和国财政部独家授权-TIPOS国家乐彩公司发行的KENO快速开奖彩票。于北京时间10:55～次日5:45开奖，每5分开奖。',
          },
          {
            title: '极速赛车',
            contentText: '极速赛车为引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控。于北京时间7:59～次日7:57。每100秒开奖，每日开奖864期。',
          },
          {
            title: 'MD5时时彩',
            contentText: 'MD5时时彩为引进欧洲博彩公司开奖设备，由菲律宾博彩执照公司审核监控。于北京时间0:00～23:59开奖，每120秒开奖，每日开奖720期。',
          },
          {
            title: '哈西28',
            contentText: '奖号来源是在Tron区块链上的一笔真实交易，取交易所在的区块哈希值。每笔交易历程都可通过钱包查询得到，保证号源数据绝对的公平、公正且不可篡改。',
          },
          {
            title: '以太坊赛车',
            contentText: '以太坊区块链上所进行的交易，交易哈希是一个唯一的66字符的标识符。抓取固定时间内一笔未确认交易信息的哈希值作为随机种子。',
          }
        ]
      },
      // 平安支付
      payment: {
        title: '平安支付',
        homeText: '支付工具支持使用银行卡进行人民币支付，让您的支付过程更加便捷和快速。',
        rowText1: '我们的支付工具支持使用银行卡进行人民币支付，让您的支付过程更加便捷和快速。我们的支付系统安全可靠，能够保障您的支付安全和资金安全。目前支持USDT收款及人民币支付。您可以使用平安支付向他人支付人民币或收取USDT，还可以方便地将USDT兑换成人民币。',
        dataList: [
          {
            title: '安全性高',
            text: '其付款和交易过程受到严格的加密和安全措施保护。',
          },
          {
            title: '方便快捷',
            text: '支付过程非常简便，无需繁琐的银行转账或汇款流程。',
          },
          {
            title: '退款保障',
            text: '如我坊责任导致双向司法冻结，我方承诺全额赔付！',
          },
          {
            title: '费用低廉',
            text: '低于市场汇率，欧意买价汇率，代付1.5%。',
          }
        ]
      },
      // 人才招聘
      recruitment: {
        title: '人力资源(HRBP)',
        homeText: '我们致力于打造一个有活力、开放、多元化的企业文化，鼓励员工自我发展，为员工提供广阔的职业发展空间。'
      }
    },
    // 新增人才招聘
    recruitmentLang: {
      title: '人才招聘',
      rowText1: `在华创公司，我们致力于打造一个有活力、开放、多元化的企业文化，鼓励员工自我发展，为员工提供广阔的职业发展空间。作为HR Business Partner，您将与各级管理层、员工和业务部门建立良好的关系，推动企业的战略实施，为员工和企业的双赢不断努力。经过长期的磨合，和团队塑造，团队招募能力超过月30+人数，能在人员供应上，带来不可估量的帮助。`,
      dataList: [
        {
          title: '更好的人力资源管理',
          content: '确保组织在人力资源方面遵守最佳实践，并协助制定和执行政策和程序。'
        },
        {
          title: '战略性合作',
          content: '管理人员紧密合作，以确保人力资源策略与组织战略保持一致，并支持业务目标的实现。'
        },
        {
          title: '增强员工体验',
          content: '员工在工作中获得满意度和幸福感，并提供支持和发展机会，以增强员工对组织的忠诚度。'
        },
        {
          title: '增强组织绩效',
          content: '可以帮助组织通过提高员工绩效和发展计划来实现业务目标，并通过监测关键绩效指标来确定成功的策略。'
        },
        {
          title: '反应更快',
          content: '可以更快地反应并解决员工和管理人员的问题，从而帮助组织避免潜在的人力资源问题和风险。'
        }
      ]
    }
  }