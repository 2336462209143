// 简体英文语言包
export default {
    testData: 'test',
    language: 'English',
    route: {
      home: 'Home page',
      temp: 'template',
      serve: 'Service advantage',
      ourselves: 'About us',
      recruitment: 'Talent recruitment'
      },
    mailbox: 'mailbox',
    price:'price',
    flow: 'Flow rate',
    quantity: 'quantity',
    applyNow: 'Apply now',
    yaer: 'yaer',
    month: 'month',
    day: 'day',
    a: 'a',
    customCharge:'Custom charge',
    customization: 'customization',
    languageSetting: 'Language setting',
    viewDetails: 'View details',
    viewTemp: 'Viewing templates',
    knowDetails: 'Know the details',
    GoToTrialPlay: 'Go to trial play',
    businessRelation: 'Business contact',
    GoSports: 'GO Sports',
    contactUs: 'Contact us',
    pleaseLookForwardTo: 'Demo Experience  Coming Soon',
    theme: 'theme',
    howToCharge: 'Please enter your question',
    contactEmail: 'Contact email',
    yourContactEmail: 'Please enter your email address',
    problemDescription: 'Problem description',
    yourQuestion: 'Please describe your problem',
    definiteSendOut: 'Definite send out',
    homePageLang: {
      productTitle: 'Comprehensive ecological product',
      productIntroduce: 'GO package network technology precipitation for many years, a number of products to build product matrix',
      firmTitle: 'Partner manufacturer',
      firmIntroduce: 'GO package network technology precipitation for many years, a number of products to build product matrix',
      clientTitle: 'Serve customers',
      clientIntroduce: 'To "customer success" as the core, truly create value for customers'
    },
    servePageLang: {
      funTitle: 'service',
      advantageTitle:'advantage',
      funDataList: [
        {
            title: 'The richest three-way game'
        },
        {
            title: 'Support for multiple languages'
        },
        {
            title: 'All-terminal platform system'
        },
        {
            title: 'Flexible comprehensive automatic agent system'
        },
        {
            title: 'Big data AI analysis'
        },
        {
            title: 'Customized exclusive design'
        }
      ],
      advantageDataList: [
        {
          title: '7*24 hour monitoring'
        },
        {
          title: 'Multi-point cloud data'
        },
        {
          title: 'Suspicious risk warning'
        },
        {
          title: 'National security firewall'
        },
        {
          title: 'SSL encryption certificate'
        },
        {
          title: 'Data encryption backup'
        },
        {
          title: 'Anti-ddos attack'
        },
        {
          title: 'Comprehensive platform report management'
        }
      ]
    },
    tempPageLang: {
      AG: 'AG',
      Macau: 'Macau',
      physicalEducation: 'Physical education',
      chessAndCards: 'Chess and cards',
      lottery: 'lottery',
      esports: 'esports',
      catchfish: 'Catch fish',
      realPerson: 'Real person',
      electron: 'electron',
      //  子内容
      Kaifa: 'Kaifa',
      J9: 'J9',
      Hutchison: 'Hutchison',
      newLisboa: 'New Lisboa',
      GalaxyMacau: 'Galaxy Macau',
      SunCity: 'Sun City',
      MGMMirage: 'MGM Mirage',
      Venetian: 'Venetian',
      GoldenSands: 'Golden Sands',
      NewCentury: 'New century',
      Yunyu: 'Yunyu',
      TangDynasty: 'Tang Dynasty',
      TianchengLottery: 'Tiancheng lottery',
      apricot: 'apricot',
      panAsia: 'pan-asia',
      LightningFire: 'Lightning fire',
      OBfishing: 'OB fishing',
      BGfishing: 'BG fishing',
      skyhunter: 'skyhunter',
      FishingKing3D: 'Fishing king 3D',
      FishingKing: 'Fishing king',
      FishingPark: 'Fishing park',
      OBRealPerson: 'OB Real person',
      AGRealPerson: 'AG Real person',
      BGRealPerson: 'BG Real person',
      OBelectron: 'OB electron',
      AGelectron: 'AG electron',
      LGDelectron: 'LGD electron'
    },
    ourselvesPageLang: {
      title: 'GO GROUP',
      contentText1: `GO Group, whose full name is Great Online Group, aims to build a top package network team in the gaming industry.`,
      contentText2: `Ten years of experience, Asia's first-class project solution team, actively seeking innovative technologies and ideas.Has a professional product team planning needs, year-round all-weather constantly explore the development of the market, provides nearly 10,000 chess and card games entertainment, slot machines, sports reality and other platforms, and provides online deposit and withdrawal.One-click operation provides core operational data and multi-dimensional analysis of player data, channel data, cost data and revenue data to help marketers make the right decisions.`,
      contentText3: `With independent property rights of anti-hijacking technology (patent application), one-key batch audit, automatic settlement, simple and efficient activity release.Integrate and synchronize accounts and data transmission to achieve the concept of uninterrupted entertainment anytime and anywhere.All website hardware and software resources are exclusive, and configured with multiple copies, intelligent switching, to give you a reliable and stable environment.The top system is different because it's extraordinary.Looking forward to your inquiry!`,
      title2: 'MILESTONE'
    },
    sonArticleContent: {
        goBao: {
          title: 'GO packet network',
          homeText: `We take technology as the guide, constantly promote the application of advanced and applicable technologies, promote the growth of customers' business, and achieve co-creation, common business and win-win situation`,
          rowText1: 'The GO Bobao project was initiated in 2012, and has developed for more than 10 years. It has served more than 100 customers, and the technical team has reached more than 100, guaranteeing the stable operation of the project 24 hours a day.More than 60 different types of templates have been developed to meet 90% of market demand.If your idea, the market template can not meet, we will from interaction design, interface design to function customization, we provide a full range of solutions, including PC, H5, IOS signature, server, domain name and other one-stop services.',
          rowText2: 'Provide Chinese, English, Vietnamese and other multi-language versions, as well as any language customization services;Using pure original technology development, completely solve the loss of top-up funds, traffic hijacking and other problems, to ensure the safety of the website;More than 200 kinds of games to choose from, no dead corner security monitoring, all-round escort!',
          netPrice: {
            title: 'Net price',
            openingCharge: {
              title: 'Printing fee',
              dataList: [
                {
                  content: 'Professional team technical configuration guide the opening of the station'
                },
                {
                  content: 'The senior UI designer assists with the page presentation'
                },
                {
                  content: 'Efficient deployment of professional technical team'
                },
                {
                  content: 'Free connection to third-party payment, SMS platforms, etc'
                },
                {
                  content: 'Operation and maintenance team project deployment, domain name resolution, etc'
                }
              ]
            },
            BasicCharge: {
              title: 'Basic charge',
              content1: 'Deliver as scheduled after final negotiation',
              content2: 'Native APP, PC end, H5 end',
              content3: '(Customized platform or demand timely contact business oh)',
            }
          },
          productAdvantage: {
            title: 'Product advantage',
            dataList: [
              {
                title: 'Fast customization of high-speed products',
                content: `High-end design brand services, excellent technology, is the industry rookie.
                As long as you have traffic, we can take you to cash!`
              },
              {
                title: 'Quality product experience',
                content: 'The most extreme mobile terminal in the industry is complete, multi-terminal encryption bid farewell to hijacking, and the whole nation is free to promote.'
              },
              {
                title: 'Operating service 7*24 hours',
                content: '7*24 hours professional customer service, risk control team financial team ACB connection, every minute counts.'
              }
            ]
          }
        },
        integrated: {
          title: 'Integrated color station',
          homeText: 'Domestic film welfare disorderly color, Japan and South Korea av HD, domestic film comparison color Japan and South Korea and the United States free adult high-definition online AV movie video'
        },
        video: {
          title: 'Video synthesis',
          homeText: `Provide the industry's most professional beauty video`,
          rowText1: `Video integration brings players unprecedented entertainment experience, is your business to improve the essential product.
          To this end, the GO Group works with the world's leading online game software providers to allow entertainment operators to add the latest content to their white label web pages.
          At the same time, we offer players different types of gaming experiences, unparalleled graphics and advanced technology, creating an immersive and interactive experience to capture the player's attention.
          The International Hall has been built to a high standard, equipped with state-of-the-art equipment and magnificent decoration, providing players with an excellent live-action video game experience.
          A variety of game options, including popular baccarat, Sicbo, roulette and so on, plus round-the clock monitoring management, to provide stable and smooth game services for players.`,
          rowText2: '',
          dataList: [
            {
              pictitle: 'Classic baccarat',
              title: 'Classic baccarat',
              cont: 'Baccarat is one of the most popular games in the world. The first six cards of baccarat to ensure the game is fair, so that the players of the platform confidence. In addition, we also exclusive research and development features LED table competition Baccarat, 25 seconds speed Baccarat, giving players a real pleasure in the field of casino'
            },
            {
              pictitle: 'Competitive baccarat',
              title: 'Competitive baccarat',
              cont:   `Competitive interactive Baccarat launched the manual card cutting function, the maximum limit of the authorities of the player, can enjoy the superior function of 20 seconds.
              Partially enlarged video and dotted lines displayed on the cards are used to aid card cutting to ensure absolute fairness and fairness of the game, while allowing players to increase their autonomy and a sense of reality similar to the actual casino.`
            },
            {
              pictitle: 'Insurance baccarat',
              title: 'Insurance baccarat',
              cont: `Insurance Baccarat is one of the most popular new features in recent years. As the name implies, adding insurance elements to the game does not affect the outcome of the baccarat game, but also can ensure that the player does not lose all the bet amount.`
            },
            {
              pictitle: 'Lombo Baccarat',
              title: 'Lombo Baccarat',
              cont: `Longbao will be based on the betting of one party to win the other's points, the odds will be different, the highest prize is 30 times, and won gold does not pump.
              If either side of the bet wins, and one of the following two criteria is met, the pot will be awarded.`
            },
            {
              pictitle: 'VIP Baccarat',
              title: 'VIP Baccarat',
              cont: `After the VIP bag table can control the pace of the game, enjoy the superior functions such as changing charge officer, flying card and changing boots, achieve more advanced and independent entertainment.`
            },
            {
              pictitle: 'Dragon and tiger',
              title: 'Dragon and tiger',
              cont: `After joining the live action game platform, Dragon Tiger allows more players to connect to our entertainment platform from computers and any mobile devices, feel like being in the scene of a new experience of fun, try their own luck!`
            },
            {
              pictitle: 'Niuniu',
              title: 'Niuniu',
              cont: `Niuniu, also known as bullfighting, is a traditional board and card game popular in Guangdong, Guangxi, Hunan and Jiangsu and Zhejiang provinces.
              One of them is the dealer, the rest is idle home, after dealing cards immediately open card than card, all idle home and dealer one by one comparison to determine the win or lose.
              The game is fast-paced and exciting, and the rules are simple.`
            },
            {
              pictitle: 'blackjack',
              title: 'blackjack',
              cont: `Blackjack originated in France, where a player wins if his card has the highest number of 21 points and is greater than the number of credits.
              The ultimate goal of the game is to get the total number of cards in the player's hand up to 21 (or closest to it) and then compare them to the size of the card.`
            },
            {
              pictitle: 'Fried golden flower',
              title: 'Fried golden flower',
              cont: `Fried golden flower (cheat golden flower), also known as win three cards, is a folk multiplayer card game widely spread in the country.
              Players use three cards in their hands to compare the size, with a unique card rules.`
            },
            {
              pictitle: 'Roulette wheel',
              title: 'Roulette wheel',
              cont: `The most realistic live roulette game.
              Through the real-time transmission of the scene picture, coupled with the endless roulette, lotus officials skilled and professional skills, are for the players to create a fair and just gambling game.
              This game uses international standard single zero roulette.
              Players can buy single numbers or combinations of numbers at the table.`
            },
            {
              pictitle: 'Sicbo',
              title: 'Sicbo',
              cont: `Mortal Sicbo game opens up a new field, giving players unprecedented new feelings, make people happy to return.
              Also called the size of the bet, to guess the number of points on the dice or the total number of points on the bet.
              The diversified betting types and the odds attracted are very popular with players.`
            },
            {
              pictitle: 'Multi-console game',
              title: 'Multi-console game',
              cont: `Here, you can choose different types of games to your liking and place bets at the same time.
              You just press the 'Select Table' button and arrange the position displayed on the table.`
            }
          ]
        },
        CDN: {
          title: 'CDN',
          homeText: 'Computer network systems connected via the Internet deliver pictures, videos, applications and other files to users faster and more reliably using servers closest to each user',
          rowText1: 'As a leading technology provider, GO Package has been building the next generation content delivery architecture since 2012.Our mission is to develop and design content delivery solutions that are accessible to as many people as possible.It is not only the basic services of CDN in terms of performance and security, but also very important for users to use CDN simply and cost-effective.Our CDNS are just a few clicks away to get you started, but also offer a variety of configuration options. The simplicity of use allows you to focus on your core business. It is this simplicity that makes our products cost-effective and stand out from our competitors.',
          rowText2: '',
          dataList: [
            {
              title: 'CDN content distribution',
              contentText: 'CDN has more than 30 data centers in five continents, among which we have a three-line high-defense server room in China, which can cache static content in your website and automatically direct users to the fastest node.',
            },
            {
              title: 'Website protection',
              contentText: 'High defense CDN website protection protects the access quality of your website and improves the availability of your website. You do not need to worry about conventional attacks such as DDoS, CC, and hijacking.',
            },
            {
              title: 'Web application firewall',
              contentText: 'Provide accurate website fingerprint identification technology and efficient processing engine, combined with real-time update of high defense defense strategy, covering OWASP mainstream attack types, protect your website from hackers.',
            },
            {
              title: 'Access optimization',
              contentText: 'The CDN includes a set of Web optimization features that can compress your Web pages by more than 50%. The optimization includes the latest Web standards, combined with our high security CDN, which can greatly optimize user access speed.',
            },
            {
              title: 'DDos defense with no upper limit',
              contentText: 'No matter the size, type, or duration of the attack, it will defend you against any DDOS attack at the edge of the node, with no upper limit.',
            },
            {
              title: 'Human-computer interaction',
              contentText: 'It has the capability of big data intelligent business defense, supports multi-dimensional customized accurate access control, integrates man-machine identification and frequency control and other countermeasures, and can intelligently and efficiently filter garbage access and effectively defend against CC attacks.',
            }
          ],
          serviceItem: 'Service item',
          chargeDetails: 'Charge details',
          setMealTitle: 'Packages and Pricing',
          setMealText1: 'All versions can enjoy unlimited access to third level domains at no extra charge! Nodes throughout Southeast Asian countries without a record number of domain names can enter China from Hong Kong, South Korea, Japan',
          setMealText2: 'The total amount of traffic is charged per 1gb of 1.12 usd ● usdt payment fee is 1%',
          dataTypeList: [
            {
              title: 'Business edition',
              titleExplain: 'For groups of up to 5 people, enjoy 100GB/ month',
              scopeText1: 'Suitable for small entrepreneurial teams',
              scopeText2: 'The maximum number of second-level domain names is 10',
              scopeText3: 'Price applicable'
            },
            {
              title: 'Professional Edition',
              titleExplain: 'Suitable for small and medium-sized teams, meet the multi-person collaboration to enjoy 100GB/ month',
              scopeText1: 'Suitable for small and medium-sized teams',
              scopeText2: 'The maximum number of second-level domain names is 100',
              scopeText3: 'Price applicable'
            },
            {
              title: 'Business section',
              titleExplain: 'Suitable for medium-sized companies to meet individual needs.',
              scopeText1: 'Suitable for medium rise companies',
              scopeText2: 'The maximum number of second-level domain names is 300',
              scopeText3: 'Professional support'
            },
            {
              title: 'Enterprise edition',
              titleExplain: 'Suitable for one-to-one private customization, to meet the unique private customization of individual enterprises.',
              scopeText1: 'Suitable for private customization',
              scopeText2: 'Secondary domain contact customer service consultation',
              scopeText3: 'Private customization'
            }
          ]
        },
        etp: {
          title: 'Product incubation',
          homeText: 'Start and implement a product from 0 to 1',
          rowText1: 'Group Entrepreneurship Center is a professional service institution established for small and medium-sized entrepreneurs. We provide free technical guidance, business, management consulting and other help solutions.',
          rowText2: '',
          dataList: [
            {
            title: 'Product analysis',
            contentText: `Including demand analysis, market analysis, competitor analysis, laws and policies analysis.
            Draw up the preliminary design plan and propose the preliminary development cycle plan.`,
            },
            {
            title: 'Product evaluation',
            contentText: `Industry internal technical person in charge of product application review and evaluation, preliminary investment analysis of the project.`,
            },
            {
            title: 'Project manager',
            contentText: 'Professional management of product incubation process based on experienced project managers in the industry to maximize product value.',
            },
            {
            title: 'Professional development team',
            contentText: 'With rich industry development experience team, to develop in the shortest time, to achieve the highest quality products.',
            },
          ]
        },
        software: {
          title: 'Communication software',
          homeText: 'Native communication software development to provide users with better chat experience',
          rowText1: 'The client is free and open source software but the server is proprietary software.Users can exchange encrypted and self-destruct messages with each other, and send photos, videos, and all types of files.The official mobile version, desktop version and web version of a variety of platform client',
          rowText2: '',
          dataList: [
            {
                title: 'attach',
                text: 'Live post, support a variety of documents, photos or text!',
            },
            {
                title: 'Burn after reading',
                text: 'Arrange sending time and content in advance, and send automatically on time',
            },
            {
                title: 'Quick chat room switch',
                text: 'Quick chat room switch',
            },
            {
                title: 'Read information',
                text: 'Watch message reading status',
            },
            {
                title: 'Red envelope',
                text: 'Group, private chat red envelope sent',
            },
            {
                title: 'Automatic voice playback',
                text: 'The phone is close enough to listen to voice messages ',
            },
          ]
        },
        waiter: {
          title: 'GO customer service',
          homeText: 'Network security is to prevent this kind of information theft and commercial competition attacks to take measures',
          rowText1: 'GO customer service system is an efficient customer service system integrating software, promotion, marketing and management.Instant chat fast access, visitor details, intelligent robot service, multi-customer service and multi-platform dialogue allocation, data analysis and preservation of historical sessions and other comprehensive and stable functions.The system is optimized for overseas customers, Tpds level protection, AI intelligent defense against attacks.',
          rowText2: '',
          dataList: [
            {
                title: 'Integrate simple and rapid deployment',
                text: 'You only need a few simple steps to enable your website to have customer service capabilities, to provide the best service for your customers.'
            },
            {
                title: 'Simple interface and convenient operation',
                text: 'GO customer service system, simple interface, customer service and administrators can quickly learn and use, the system process is efficient, fast and stable.',
            },
            {
                title: 'Secure and efficient privatization deployment',
                text: 'GO system, fully support privatization deployment, help your enterprise can be more secure and efficient customer service operations.',
            },
            {
                title: 'Strong background and efficient management',
                text: 'GO system integrates information management and customer service management to improve team efficiency and work quality, while taking into account customer experience to fully understand your customers.',
            },
            {
                title: 'Service guarantee strong backing',
                text: 'Customer service system to provide you with high-quality after-sales service, elite team for you to solve all difficulties in use, to ensure your use of comfort and stability.',
            },
            {
                title: 'Multi-domain name multi-platform',
                text: 'It supports simple deployment of multiple domain names and multiple platforms, enabling you to effectively master the overall situation and easily manage multiple customer services.',
            },
        ]
        },
        //新增修改内容
        sport: {
          title: 'Sports API',
          homeText: 'We mainly provide data for football, basketball and other popular sports. In addition to football and basketball, we also support a variety of sports such as baseball, volleyball and badminton.',
          rowText1: 'It provides data on football, basketball and other popular sports. In addition to football and basketball, we also support a variety of sports such as baseball, volleyball and badminton. Our API can provide you with a variety of information including game scores, tournament data, and more. Using our API, you can easily access the latest race data, as well as historical race data and a variety of useful statistics. Whether you are a sports website, sports APP, or other sports-related enterprises, we can provide you with the most comprehensive and accurate sports data services to help you improve user experience and business value.',
          rowText2: '',
          subhead: 'Provide game types',
          description: '',
          dataList: [
            {
              title: 'English Premier League',
              cont: 'Commonly known as the Premier League, it is the highest division of English football and one of the top football leagues in the world. It was established by the English Football Association on 20 February 1992 and has been in existence since the 1992-93 season'
            },
            {
              title: 'Italian first division football league',
              cont: 'The first division of the Italian football League system, which is also the highest professional league, the highest division of the league system and the top division of Italian football, which is governed by the Italian Football Federation and operated by the Italian professional League Serie A.'
            },
            {
              title: 'The French first division football League',
              cont: 'The first division of the French Football League system, the highest of the professional leagues, the highest division of the league system and the top division of the French football League, is supervised, organized and managed by the French Professional Football League under the supervision of the French Football Association。'
            },
            {
              title: 'The first division of German football',
              cont: 'The highest level of competition in German football, established by the German Football Association on 28 July 1962 in Dortmund, has been played since the 1963-64 season.'
            },
            {
              title: 'The Spanish First division football League',
              cont: 'The first division of the Spanish football League system, the highest division of the professional league, the highest division of the league system and the top division of Spanish football. There are currently 20 teams in La Liga, with the bottom three relegated to Spanish footballs second division.'
            },
            {
              title: 'FIFA World Cup',
              cont: 'Founded in 1904, it oversees international competitions between the national associations of Belgium, Denmark, France, Germany, the Netherlands, Spain, Sweden and Switzerland. Headquartered in Zurich, Switzerland, its membership now includes 211 national associations.'
            },
            {
              title: 'Champions League',
              cont: 'The annual football competition hosted by UEFA represents the highest honor of European club football. With perfect organization and high efficiency, it is known as the highest competitive level club cup in the world and one of the highest prize money football and sports competitions in the world.'
            },
            {
              title: 'Chinese Football Association Super League',
              cont: 'The highest level of professional football league organized by the Chinese Football Association. The league started in 2004, out of the original Chinese football League A Group.'
            },
            {
              title: 'DOTA2 International',
              cont: 'Dota 2 developer Weller is hosting an esports competition, which will be completed by 16 invited teams. The competition was first held at Gamescom on May 25, 2011, and is the most lucrative competition to date.'
            },
            {
              title: 'The League of Legends Global Finals',
              cont: 'Since 2017, the League of Legends Global Finals have been expanded. The Wild Card Tour was cancelled, and instead the expanded League of Legends Global Finals directly included the features of the original Wild Card Tour.'
            },
            {
              title: 'Crossfire pro league',
              cont: 'Crossfire is a top event in mainland China. Sponsored by Tencent Games and hosted by Quantum Sports. The CFPL has been in existence for 15 seasons since the inaugural league was held in 2012.'
            },
            {
              title: 'CS:GO to the Super League',
              cont: 'CS:GO to the Super League. The first session was held in March 2016. It is sponsored by the Sports Information Center of the State General Administration of Sport and organized by Shanghai Huaao E-Sports Information Technology Co., LTD.'
            }
          ]
        },
      // 棋牌api
      chessAndCard: {
        title: 'Chess and cards API',
        homeText: 'Offer a variety of classic board and card games, including Niu Niu, fried golden flower, Dragon Tiger, Run fast, Minesweeper and other games. Gameplay innovation, more attention to the safety and reliability of the game.',
        rowText1: 'Offer a variety of classic board and card games, including Niu Niu, fried golden flower, Dragon Tiger, Run fast, Minesweeper and other games. Our game interface design beautiful, innovative gameplay, more attention to the safety and reliability of the game. No matter where you are, just need a mobile phone or computer, you can easily enter our game hall and compete with other players.',
        rowText2: '',
        subhead: 'Provide game types',
        description: '',
        dataList: [
          {
            title: 'Niuniu',
            cont: 'A deck of fifty-four playing cards is used in the game, with five cards allocated to each player. A player needs to divide the five cards in his hand into groups of three and two so that the sum of the points of the three cards is a multiple of 10 and the sum of the points of the remaining two cards is as large as possible.'
          },
          {
            title: 'Fried golden flower',
            cont: 'The game uses a deck of fifty-four playing cards (including two Kings), with three cards allocated to each player. The card types in descending order are: leopard, Shun gold, golden flower, Shun, pair and single card.'
          },
          {
            title: 'Dionysus',
            cont: 'The game uses fifty-four cards (not including big and small Wang), to green dragon, white tiger, Zhu Que, Xuanwu four idle plate game, licensing for four plates and dealer issued a card comparison.'
          },
          {
            title: 'A surname',
            cont: 'Each player is assigned three cards, and the winner is determined by comparing the size of the cards. The use of inference and psychological tactics can also be challenging.'
          },
          {
            title: 'Run fast',
            cont: 'The play uses a deck of fifty-four playing cards, including the king. One player plays the cards, and the other players play the cards in turn and follow the rules to follow or not follow the cards. It is a competitive and entertaining card game that requires a certain amount of luck and strategy.'
          },
          {
            title: 'Thirteen water',
            cont: 'The game USES a total of fifty-four CARDS, including the king of the size. The CARDS are divided into leopards, shunzi, tonghua, the same flowers, pairs of pairs, and six high CARDS.'
          },
          {
            title: 'Fight the landlord',
            cont: 'A deck of fifty-four cards (including two big Wang), leaving three bottom card, the bottom card to determine the landlord before can not see, waiting to determine the landlord, the bottom card to the landlord, and express the bottom card.'
          },
          {
            title: 'Mercedes-benz BMW',
            cont: 'The game has eight equal odds betting plates for four car brands. You can bet on any of the eight plates.'
          },
          {
            title: 'Mahjong series',
            cont: 'There are a hundred and twelve cards, and the game starts with only the dealer getting fourteen cards and the other thirteen cards.'
          },
          {
            title: 'Pai Gow',
            cont: 'At present, there are "pot", "big Pai Jiu", "small Pai Jiu", "devil and Tianjiu King" four ways of playing. The player needs a certain amount of strategy and judgment in order to win the game.'
          },
          {
            title: 'Baccarat',
            cont: 'Eight deck of 416 cards for the game, the opening of the Zhuang idle to issue two cards, according to the rules of the general card to decide whether to fill the card, fill the card at the end of the total number of cards to take a single digit, closer to 9 party win.'
          },
          {
            title: 'Mine clearance',
            cont: 'There are four kinds of gameplay: "Zhuang and idle", "sending red envelopes", "grabbing red envelopes", "thunder compensation". Minesweeper games usually have a certain degree of game and competition'
          }
        ],
        dataListTwo: [
          {
            title: 'A hundred men are three men'
          },
          {
            title: 'Grab the classic cow'
          },
          {
            title: 'Grab the big idea'
          },
          {
            title: 'One hundred people Niu Niu'
          },
          {
            title: 'The bottom of the pot is dotted with cows'
          },
          {
            title: 'Infinite bull'
          },
          {
            title: 'A hundred men fight against the beast'
          },
          {
            title: 'Classic beef on the bottom of the pot'
          },
          {
            title: 'Run fast'
          },
          {
            title: 'One hundred people are dragon and tiger'
          },
          {
            title: 'Fried golden flower'
          },
          {
            title: 'Three public and one dark'
          },
          {
            title: 'Mercedes-benz BMW'
          },
          {
            title: 'Three public all dark'
          },
          {
            title: 'The three princes are better than the golden flowers'
          },
          {
            title: 'Baccarat'
          },
          {
            title: 'Red Medium Mahjong (two persons)'
          },
          {
            title: 'Hong Zhong Mahjong (4 players)'
          }
        ]
      },
      // 彩票API
      lottory: {
        title: 'Lottery API',
        homeText: 'API includes hash lottery, quick opening lottery, overseas lottery and low frequency lottery, which can help users achieve accurate, efficient and convenient lottery rental needs.',
        rowText1: 'API includes hash lottery, quick opening lottery, overseas lottery and low frequency lottery, which can help users achieve accurate, efficient and convenient lottery rental needs. Our API provides a variety of data interfaces, which can be customized according to the needs of users. Our API data is comprehensive, accurate and timely, which can help users understand market dynamics, analyze market trends and optimize lottery rental business.',
        rowText2: '',
        dataList: [
          {
            title: 'Hash color',
            content: 'Hash lottery is a new lottery game based on blockchain technology. The lottery drawing process is also carried out through blockchain technology. The system will randomly select a block chain. Due to the immutable property of block chain, this kind of lottery play can ensure the fairness and justice of lottery results.'
          },
          {
            title: 'Quick opening',
            content: 'Is a high frequency lottery game based on digital technology, the gameplay is similar to the traditional lottery "instant" game. The drawing time is very fast, and results are usually available within minutes.'
          },
          {
            title: 'Foreign color',
            content: 'It refers to lottery games in countries and regions other than China. These lottery games may offer higher payouts, more ways to play, and more choices than local lotteries.'
          },
          {
            title: 'Low frequency color',
            content: 'This kind of game is usually a game in which lottery tickets in the form of numbers or symbols are purchased and lottery results are matched to determine whether to win.'
          }
        ]
      },
      // 老虎机API
      tiger: {
        title: 'Slot machine API',
        homeText: 'With our API, you can easily create your own slot games.',
        rowText1: 'The API was developed by our own team and is highly flexible and extensible. Using our API, you can easily create your own slot machine games.',
        dataList: [
          {
            title: 'Themed slot machines',
            contentText: 'Based on a variety of different themes, including ancient mythology, the animal kingdom, and historical periods.'
          },
          {
            title: 'Multi-line slot machines',
            contentText: 'In a single game, players can increase their chances of winning by multiple payouts. Hit the symbol on any of the paylines to get a reward.'
          },
          {
            title: 'Traditional slot machines',
            contentText: 'Hit a matching set of symbols on the wheel to earn a reward. There are usually several different symbols and payout line types that can be used in the game.'
          }
        ]
      },
      // 彩票信用网
      credit: {
        title: 'Lottery credit network',
        homeText: 'Support a variety of lottery types, including hash lottery, speed lottery, speed racing and lottery six.',
        rowText1: 'Support a variety of lottery types, including hash lottery, speed lottery, speed racing and lottery six. Different lottery types offer different kinds of play and bonuses, and users can choose different lottery types and betting methods according to their preferences and betting strategies.',
        dataList: [
          {
            title: 'Hong Kong lottery six',
            contentText: 'It is the only legal lottery in Hong Kong. To be drawn by the Hong Kong Jockey Club on behalf of the Hong Kong Jockey Club Lotteries Limited. The date of the drawing is not fixed. The drawing will be held at 9:30 PM Beijing time.',
          },
          {
            title: 'Lucky airship',
            contentText: 'Lucky Airship is issued exclusively by the Valletta Joint Council of Welfare, Republic of Malta. There are 180 daily draws, one every five minutes. The results of each drawing will be publicized through various channels to ensure fairness and fairness.',
          },
          {
            title: 'British Columbia',
            contentText: 'Drawing results are based on KENO drawing data from the Canadian lottery company BCLC. Lottery time is different in summer and winter. Prizes are divided every 3.5 days and 395 prizes are drawn daily.',
          },
          {
            title: 'Slovakia',
            contentText: 'KENO Quick Draw tickets issued under the exclusive license of the Ministry of Finance of the Slovak Republic -TIPOS National Lottery Company. From 10:55 Beijing time to 5:45 the next day, the prize will be split every 5 times.',
          },
          {
            title: 'Speed racer',
            contentText: 'Speed racing for the introduction of European lottery equipment, by the Philippine gaming license company review and monitoring. From 7:59 Beijing time to 7:57 the next day. Drawings are drawn every 100 seconds, and 864 are drawn daily.',
          },
          {
            title: 'MD5 constant color',
            contentText: 'MD5 Constant color for the introduction of European lottery equipment, by the Philippine gaming license company audit and monitoring. From 0:00 to 23:59 (Beijing time), the lottery will be drawn every 120 seconds, and 720 prizes will be drawn daily.',
          },
          {
            title: 'Hasi 28',
            contentText: 'The source of the prize number is a real transaction on the Tron blockchain, taking the block hash value of the exchange. Each transaction process can be queried through wallet, ensuring that the source data of the number is absolutely fair, just and immutable.',
          },
          {
            title: 'etherite',
            contentText: 'A transaction hash is a unique 66-character identifier for a transaction that takes place on the Ethereum blockchain. Grab the hash value of an unconfirmed transaction information within a fixed time as a random seed.',
          }
        ]
      },
      // 平安支付
      payment: {
        title: 'Ping An Payment',
        homeText: 'Payment tools support the use of bank cards for RMB payment, making your payment process more convenient and fast.',
        rowText1: 'Our payment tools support the use of bank cards for RMB payment, making your payment process more convenient and fast. Our payment system is secure and reliable to ensure your payment security and the security of your funds. Currently support USDT collection and RMB payment. You can use Ping An Pay to pay others in RMB or receive USDT, and you can also easily convert USDT into RMB.',
        dataList: [
          {
            title: 'High security',
            text: 'Its payment and transaction processes are protected by strict encryption and security measures.',
          },
          {
            title: 'Convenient and quick',
            text: 'The payment process is very simple and there is no cumbersome bank transfer or remittance process.',
          },
          {
            title: 'Money back protection',
            text: 'If our responsibility leads to two-way judicial freeze, we promise to pay in full!',
          },
          {
            title: 'Low cost',
            text: 'Below the market rate, EU-Italy buying rate, pay 1.5%.',
          }
        ]
      },
      // 人才招聘
      recruitment: {
        title: 'Human Resources (HRBP)',
        homeText: 'We are committed to creating a dynamic, open and diverse corporate culture that encourages self-development and provides broad space for career development.'
      }
      },
          // 新增人才招聘
    recruitmentLang: {
      title: 'Talent recruitment',
      rowText1: `At Huachuang Company, we are committed to building a dynamic, open and diversified corporate culture, encouraging employees to develop themselves and providing broad space for their career development. As a HR Business Partner, you will establish a good relationship with all levels of management, employees and business departments, promote the implementation of the enterprise strategy, and make continuous efforts for the win-win situation of employees and the enterprise. After long-term running-in and team shaping, the team recruitment capacity is more than 30+ people per month, which can bring immeasurable help in personnel supply.`,
      dataList: [
        {
          title: 'Better human resource management',
          content: 'Ensure that organizations adhere to best practices in human resources and assist in the development and implementation of policies and procedures.'
        },
        {
          title: 'Strategic cooperation',
          content: 'Management works closely to ensure that human resources strategies align with organizational strategies and support the achievement of business objectives.'
        },
        {
          title: 'Enhance employee experience',
          content: 'Employees gain satisfaction and happiness in their work, and support and development opportunities are provided to enhance employee loyalty to the organization.'
        },
        {
          title: 'Enhance organizational performance',
          content: 'Can help organizations achieve business goals by improving employee performance and developing programs, and identify strategies for success by monitoring key performance indicators.'
        },
        {
          title: 'Faster reaction',
          content: 'It can help organizations avoid potential human resource problems and risks by responding to and resolving employee and management problems more quickly.'
        }
      ]
    }
  }