import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path:'/',
    name:'homePage',
    component:()=>import('../views/home/homePage.vue'),
  },
  // 模板
  {
    path:'/temp',
    name:'tempPage',
    component:()=>import('../views/temp/tempPage.vue'),
  },
  // 服务优势
  {
    path:'/serve',
    name:'servePage',
    component:()=>import('../views/serve/servePage.vue'),
  },
  // 关于我们
  {
    path:'/ourselves',
    name:'ourselvesPage',
    component:()=>import('../views/ourselves/ourselvesPage.vue'),
  },
    // 联系我们
  {
    path:'/relation',
    name:'relationPage',
    component:()=>import('../views/relation/relationPage.vue'),
  },
  // 人才招聘
  {
    path:'/recruitment',
    name:'recruitmentPage',
    component:()=>import('../views/recruitment/recruitmentPage.vue'),
  },
  // 子页面
  // GO包
  {
    path:'/goBao',
    name:'goBaoPage',
    component:()=>import('../views/goBao/goBaoPage.vue'),
  },
  // 彩票Api
  {
    path:'/lottory',
    name:'lottoryPage',
    component:()=>import('../views/lottory/lottoryPage.vue'),
  },
   // 体育直播
   {
    path:'/sport',
    name:'sportPage',
    component:()=>import('../views/sport/sportPage.vue'),
  },
    // CND
  {
    path:'/cdn',
    name:'cdnPage',
    component:()=>import('../views/cdn/cdnPage.vue'),
  },
    // 产品孵化
  {
    path:'/etp',
    name:'etpPage',
    component:()=>import('../views/etp/etpPage.vue'),
  },
    // 通讯软件
  {
    path:'/software',
    name:'softwarePage',
    component:()=>import('../views/software/softwarePage.vue'),
  },
    // 视讯综合
  {
    path:'/video',
    name:'videoPage',
    component:()=>import('../views/video/videoPage.vue'),
  },
    // GO客服
  {
    path:'/waiter',
    name:'waiterPage',
    component:()=>import('../views/waiter/waiterPage.vue'),
  },
  // 新增
  // 棋牌API
  {
    path:'/chessAndCard',
    name:'chessAndCardPage',
    component:()=>import('../views/chessAndCard/chessAndCardPage.vue'),
  },
    // 老虎机API
    {
      path:'/tiger',
      name:'tigerPage',
      component:()=>import('../views/tiger/tigerPage.vue'),
    },
    // 彩票信用
    {
      path:'/credit',
      name:'creditPage',
      component:()=>import('../views/credit/creditPage.vue'),
    },
    // 通讯软件
    {
      path:'/payment',
      name:'paymentPage',
      component:()=>import('../views/payment/paymentPage.vue'),
    },
  // 开发模板页
  {
    path:'/dev',
    name:'devPage',
    component:()=>import('../views/dev/devPage.vue'),
  },  
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

// 导航守卫 
router.beforeEach((to,from,next)=>{

  next()

})

export default router
