<template>
  <div class="app" id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  methods: {

  }
}
</script>

<style>
html{
  width: 100%;
  height: 100%;
}
body{
  width: 100%;
  height: 100%;
  min-width: 1180px;
}
#app{
  width: 100%;
  height: 100%;
}
@media (min-width:1025px) {
  body{
    min-width: 1180px;
  }
}
@media (max-width:1025px) {
  body{
    min-width: auto;
  }
}
</style>
