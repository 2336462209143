// 简体中文繁体语言包
export default {
  testData: '測試',
  language: '繁體中文',
  route: {
    home: '首頁',
    temp: '模板',
    serve: '服務優勢',
    ourselves: '關於我們',
    recruitment: '人才招聘'
    },
  mailbox: '郵箱',
  price:'價格',
  flow: '流量',
  quantity: '數量',
  applyNow: '立即申請',
  yaer: '年',
  month: '月',
  day: '日',
  a: '個',
  customCharge:'客製收費',
  customization: '客製化',
  languageSetting: '語言設置',
  viewDetails: '查看詳情',
  viewTemp: '查看模板',
  knowDetails: '瞭解詳情',
  GoToTrialPlay: '前往試玩',
  businessRelation: '商務聯繫',
  GoSports: '前往GO體育',
  contactUs: '聯繫我們',
  pleaseLookForwardTo: '試玩功能 敬請期待',
  theme: '主題',
  howToCharge: '請輸入您的問題',
  contactEmail: '聯繫郵箱',
  yourContactEmail: '請輸入您的郵箱',
  problemDescription: '問題描述',
  yourQuestion: '請描述您的問題',
  definiteSendOut: '確定送出',
  homePageLang: {
    productTitle: '綜合生態產品',
    productIntroduce: 'GO包網多年技術沉澱，多款產品共築產品矩陣',
    firmTitle: '合作廠商',
    firmIntroduce: 'GO包網多年技術沉澱，多款產品共築產品矩陣',
    clientTitle: '服務客戶',
    clientIntroduce: '以“客戶成功”爲而核心，真正爲客戶創造價值'
  },
  servePageLang: {
    funTitle: '服務',
    advantageTitle:'優勢',
    funDataList: [
      {
        title: '最豐富的三方遊戲'
      },
      {
        title: '支持多國語言'
      },
      {
        title: '全終端平臺系統'
      },
      {
        title: '靈活全面自動代理系統'
      },
      {
        title: '大數據ai分析'
      },
      {
        title: '量身定製專屬設計'
      }
    ],
    advantageDataList: [
      {
        title: '7*24小時監控'
      },
      {
        title: '多點式雲資料'
      },
      {
        title: '可疑風險預警'
      },
      {
        title: '國安級防火牆'
      },
      {
        title: 'ssl加密憑證'
      },
      {
        title: '數據加密備份'
      },
      {
        title: '抗DDoS攻擊'
      },
      {
        title: '全方位平臺報表管理'
      }
    ]
  },
  tempPageLang: {
    AG: 'AG',
    Macau: '澳門',
    physicalEducation: '體育',
    chessAndCards: '棋牌',
    lottery: '彩票',
    esports: '電競',
    catchfish: '捕鱼',
    realPerson: '真人',
    electron: '電子',
    //  子内容
    Kaifa: '凱發',
    J9: 'J9',
    Hutchison: '和記',
    newLisboa: '新葡京',
    GalaxyMacau: '澳門銀河',
    SunCity: '太陽城',
    MGMMirage: '美高梅',
    Venetian: '威尼斯人',
    GoldenSands: '金沙',
    NewCentury: '開元',
    Yunyu: '雲遊',
    TangDynasty: '大唐',
    TianchengLottery: '天成彩票',
    PhoenixLottery: '鳳凰彩票',
    apricot: '杏彩',
    panAsia: '泛亞',
    LightningFire: '雷火',
    OBfishing: 'OB捕鱼',
    BGfishing: 'BG捕鱼',
    skyhunter: '天際獵人',
    FishingKing3D: '捕鱼王3D',
    FishingKing: '捕鱼王',
    FishingPark: '捕魚樂園',
    OBRealPerson: 'OB真人',
    AGRealPerson: 'AG真人',
    BGRealPerson: 'BG真人',
    OBelectron: 'OB電子',
    AGelectron: 'PG電子',
    LGDelectron: 'LGD電子'
  },
  ourselvesPageLang: {
    title: 'GO集團',
    contentText1: 'GO集團全稱偉大在線組，志在打造博彩業頂尖包網團隊。',
    contentText2: `十年經驗淬鍊，亞洲一流的項目方案解決團隊，積極尋求創新技術及理念。
    擁有專業的產品團隊規劃需求，常年全天候不斷探索市場發展脈絡，提供近萬臺棋牌遊戲娛樂、老虎機、體育真人等平臺，並提供線上存取款。
    一鍵操作，提供核心運營數據，多維分析玩家數據、渠道數據、成本數據和收益數據，幫助營銷人員做出正確的決策。`,
    contentText3: `擁有自主產權的防劫持技術(已有專利申請)，一鍵批量審覈，自動結算，活動發佈簡單高效 。
    整合同步賬號和資料傳輸，達到隨時隨地不間斷娛樂的享受概念。
    所有網站軟硬件資源均獨享，且配置多副本，智慧切換，給您一個可靠、穩定的環境。
    頂尖系統，因非凡而與衆不同。
    期待您的垂詢！`,
    title2: '里程碑'
  },
  sonArticleContent: {
      goBao: {
        title: 'GO包網',
        homeText: '我們以技術爲先導，不斷推廣先進適用技術的應用，推動客戶業務成長，實現共創、共營、共贏',
        rowText1: 'GO博包網項目立項於2012年,累計已發展10餘年,累計服務客戶上百+位技術團隊目前已有百位+ 24小時保障項目穩定運行。開發出60+個不同類型模版，滿足市場90%的需求。如您的想法,市面模板無法滿足,我們將會從交互設計,界面設計到功能定製,我們提供全方位的解決方案,包括PC, H5, IOS籤,服務器,域名等一條龍服務。',
        rowText2: '提供中文、英文、越南文等多國語言版本，以及任意語言定製服務；採用純原生技術開發，徹底解決充值資金丟失、流量劫持等問題，保障網站安全；200多種遊戲選擇，無死角安全監控，全方位保駕護航！',
        netPrice: {
          title: '包網價格',
          openingCharge: {
            title: '開版費',
            dataList: [
              {
                content: '專業團隊技術配置指導開站事宜'
              },
              {
                content: '高級UI設計師協助處理頁面展示效果'
              },
              {
                content: '專業技術團隊高效快捷部署開站'
              },
              {
                content: '免費對接三方支付、短信平臺等'
              },
              {
                content: '運維團隊項目部署，域名解析等'
              }
            ]
          },
          BasicCharge: {
            title: '基本收费',
            content1: '最終洽談後如期交付',
            content2: '原生APP、PC端、H5端',
            content3: '（定製化平臺或者需求及時聯繫商務哦）',
          }
        },
      productAdvantage: {
        title: '產品優勢',
        dataList: [
          {
            title: '快速定製高速產品',
            content: '高端設計品牌服務，技術過硬，更是行業新秀。只要你有流量，我們就能帶您變現！'
          },
          {
            title: '優質的產品體驗',
            content: '行業內最極致移動端三端齊全，多端加密告別劫持，全民推廣無憂。'
          },
          {
            title: '運營服務7*24小時',
            content: '7*24小時專業客服，風控團隊財務團隊acb全程對接，分秒必爭。'
          }
        ]
      }
    },
    integrated: {
      title: '綜合色站',
      homeText: '國產電影福利亂色，日韓av高清，國產電影比較色 日韓歐美國產免費成人高清在線AV電影視頻'
    },
    video: {
      title: '視訊綜合',
      homeText: '提供業界最專業的美女視訊',
      rowText1: `視訊綜合給玩家帶來前所未有的娛樂體驗，是您提升業務的必備產品。
      爲此GO集團與世界頂端的網絡遊戲軟件供應商一起合作，讓娛樂運營商可在自己的白標網頁添加最新的內容。
      同時我們爲玩家提供不同類型的遊戲體驗，無與倫比的圖形和先進的技術，創造一種身臨其境和互動的經驗來吸引玩家的注意力。
      以高規格打造的國際廳配備最先進的設備以及富麗堂皇的裝潢，爲玩家打造絕佳真人視訊遊戲體驗。
      遊戲選擇多樣化，包括備受歡迎的百家樂丶骰寶丶輪盤等，加上全天侯監控管理，爲玩家提供穩定而流暢的遊戲服務。`,
      rowText2: '',
      dataList: [
        {
          pictitle: '經典百家樂',
          title: '經典百家樂',
          cont: `百家樂是世界上其中一款最受歡迎的遊戲。
          首創六張牌先發百家樂，確保遊戲公平，令玩家對平臺的信心大增。
          另外，我們亦獨家研發特色LED桌臺競咪百家樂、25秒極速百家樂，給予玩家一種置身實地賭場的真實快感。`
        },
        {
          pictitle: '競咪百家樂',
          title: '競咪百家樂',
          cont: `競咪互動百家樂強勢推出手動切牌功能，當局額度最大的玩家，即可享此限時20秒的優越功能。
          透過局部放大的視頻及牌上顯示的虛線以輔助切牌，確保牌局絕對公平公正，同時讓玩家自主性大增，真實感比擬實地賭場。`
        },
        {
          pictitle: '保險百家樂',
          title: '保險百家樂',
          cont: '保險百家樂是近年最受玩家歡迎的一個新功能，顧名思義在遊戲中加入保險元素，除了不影響百家樂遊戲的結果外，同時亦能保障玩家不至於輸掉所有的押注金額。'
        },
        {
          pictitle: '龍寶百家樂',
          title: '龍寶百家樂',
          cont: `龍寶會根據所投注一方勝出對方點數的情況，賠率會有所不同，最高彩金爲30倍，且所贏得彩金不抽水。
          若投注其中一方獲勝，並且符合下列兩種之一即可獲得派彩。`
        },
        {
          pictitle: 'VIP百家樂',
          title: 'VIP百家樂',
          cont: 'VIP包桌後可自行控制遊戲節奏，盡享更換荷官、飛牌及換靴等優越功能，成就更高級及自主的娛樂享受。'
        },
        {
          pictitle: '龍虎',
          title: '龍虎',
          cont: '龍虎 加入真人遊戲平臺後，讓更多玩家於電腦以及任何移動裝置連接我們的娛樂平臺，感受猶如置身現場的享樂新體驗，一試自己的運氣！'
        },
        {
          pictitle: '',
          title: '牛牛',
          cont: `牛牛 又名鬥牛，是一款流行於廣東、廣西、湖南以及江浙一帶的傳統棋牌遊戲。
          其中一家爲莊家，其餘爲閒家，發完牌後馬上開牌比牌，所有閒家與莊家一一進行比較確定輸贏。
          遊戲節奏緊湊刺激，規則簡單。`
        },
        {
          pictitle: '21點',
          title: '21點',
          cont: `21點源於法國，只要玩家的撲克牌擁有最高21點數並大於荷官點數便獲勝。
          牌局的最終目的，是要玩家手上擁有的牌總點數達到21點（或最接近21點），然後和荷官比大小。`
        },
        {
          pictitle: '炸金花',
          title: '炸金花',
          cont: `炸金花（詐金花）又稱贏三張，是在全國廣泛流傳的一種民間多人紙牌遊戲。
          玩家以手中的三張牌比大小，具有獨特的比牌規則。`
        },
        {
          pictitle: '輪盤',
          title: '輪盤',
          cont: `最逼真的真人輪盤遊戲。
          透過實時傳遞的現場畫面，加上目不暇給的輪盤丶荷官熟練而專業的技巧，都爲玩家締造一個公平公正的博彩遊戲。
          本遊戲採用國際標準的單零輪盤。 
          玩家可以買單一數字或賭桌上的數字組合`
        },
        {
          pictitle: '骰寶',
          title: '骰寶',
          cont: `真人骰寶遊戲開拓出嶄新領域，付予玩家前所未有的全新感受，令人樂而忘返。
          也叫賭大小，以猜測骰子開出的點數或是點數總合的博彩。
          多元化的投注種類及吸引的賠率，極之受玩家歡迎。`
        },
        {
          pictitle: '多臺遊戲',
          title: '多臺遊戲',
          cont: `在這裏，你可按自己喜好選擇不同類型的遊戲並同時進行下注。
          你只須按‘選擇桌臺’按鈕再安排桌臺顯示的位置便可。`
        }
      ]
    },
    CDN: {
      title: 'CDN',
      homeText: '透過互聯網互相連接的電腦網絡系統，利用最靠近每位用戶的 服務器，更快、更可靠地將圖片、視頻、應用程序及其他文件 發送給用戶',
      rowText1: `作爲有着領先卓越技術的CDN供應商，GO包網早在2012年開始就構建了下一代內容交付架構。
      我們的使命是開發和設計可供儘可能多的人訪問的內容交付解決方案。 
      不僅僅是性能、安全方面的CDN的基礎服務，簡單易用和高性價比對於方能CDN的用戶亦是非常重要。
      我們的CDN只需點擊幾下即可讓您入門，同時還提供多種配置選項，簡單易用可以讓您更專注於核心業務，也正是這樣的簡單易用性讓我們的產品具有極高的性價比，在競爭對手中脫穎而出。作爲有着領先卓越技術的CDN供應商，GO包網早在2012年開始就構建了下一代內容交付架構。
      我們的使命是開發和設計可供儘可能多的人訪問的內容交付解決方案。 
      不僅僅是性能、安全方面的CDN的基礎服務，簡單易用和高性價比對於方能CDN的用戶亦是非常重要。
      我們的CDN只需點擊幾下即可讓您入門，同時還提供多種配置選項，簡單易用可以讓您更專注於核心業務，也正是這樣的簡單易用性讓我們的產品具有極高的性價比，在競爭對手中脫穎而出。`,
      rowText2: '',
      dataList: [
        {
          title: 'CDN內容分發',
          contentText: 'CDN在五大洲擁有三十多個數據中心，其中國內我們擁有三線高防服務器機房，可以緩存您網站中的靜態內容，可自動將用戶指向最快的節點。',
        },
        {
          title: '網站保護',
          contentText: '高防CDN網站保護可使您的網站訪問質量得到保護，可用性大幅提高，在面對DDoS、CC、劫持等常規攻擊的時候，無需擔心。',
        },
        {
          title: 'Web應用防火牆',
          contentText: '提供精確的網站指紋識別技術和高效的處理引擎，結合實時更新的高防防禦策略，覆蓋OWASP主流的攻擊類型，保護您的網站免受黑客攻擊。',
        },
        {
          title: '訪問優化',
          contentText: 'CDN包含一套Web優化功能，可將您的網頁壓縮50%以上，優化包括最新的Web標準，結合我們的高防CDN，可大幅優化用戶訪問速度。',
        },
        {
          title: '無上限的DDos防禦',
          contentText: '無論攻擊的大小，類型或持續時間如何，方能都會爲您在節點邊緣抵擋任何DDOS攻擊，並且沒有上限。',
        },
        {
          title: '人機交互',
          contentText: '具備大數據智能業務防禦能力，支持多維度自定義精準訪問控制、配合人機識別和頻率控制等對抗手段，可智能高效的過濾垃圾訪問，有效防禦 CC 攻擊。',
        }
      ],
      serviceItem: '服務項目',
      chargeDetails: '收費詳情',
      setMealTitle: '套餐與定價',
      setMealText1: '所有版本接可享受無限制接入三級域名，不額外收費!節點遍佈東南亞國家無備案號域名可從香港、韓國、日本進入中國',
      setMealText2: '流量超用收費：● 每1GB收1.12 USD   ● USDT支付手續費爲1%',
      dataTypeList: [
        {
          title: '創業版',
          titleExplain: '適用於5人以內的團隊，享受100GB/月',
          scopeText1: '適用人數少的創業團隊',
          scopeText2: '二級域名適用數量10個',
          scopeText3: '價格適用'
        },
        {
          title: '專業版',
          titleExplain: '適用於中小型團隊，滿足多人協作享受100GB/月',
          scopeText1: '適用於中小型的團隊',
          scopeText2: '二級域名適用數量100個',
          scopeText3: '價格適用'
        },
        {
          title: '商業版',
          titleExplain: '適用於中型公司，滿足個性化需求。',
          scopeText1: '適用於中型上升級別公司',
          scopeText2: '二級域名適用數量300個',
          scopeText3: '專業配套'
        },
        {
          title: '企業版',
          titleExplain: '適用一對一私人定製，滿足個人企業獨一無二的私人定製。',
          scopeText1: '適用於私人定製',
          scopeText2: '二級域聯繫客服諮詢',
          scopeText3: '私人定製'
        }
      ]
    },
    etp: {
      title: '產品孵化',
      homeText: '一個產品從0到1的啓動與實施',
      rowText1: '集團創業中心爲中小型創業者成立的專業服務機構，我們免費提供指導技術業務、管理諮詢等幫幫方案。',
      rowText2: '',
      dataList: [
        {
        title: '產品分析',
        contentText: `包含需求分析、市場分析、競爭對手分析、法規政策分析。
        擬定初步設計方案並提出初步開發週期計劃。`,
        },
        {
        title: '產品評估',
        contentText: '業內部技術負責人對產品申 請進行審覈評估，進行項目初步投資分析。',
        },
        {
        title: '項目經理',
        contentText: '據業界豐富經驗的項目經理，予以產品孵化過程的專業管理，將產品價值最大化。',
        },
        {
        title: '專業開發團隊',
        contentText: '擁有產業豐富開發經驗團隊，以最短時間開發，達到最高品質的產品。',
        },
      ]
    },
    software: {
      title: '通訊軟件',
      homeText: '原生通訊軟件開發，提供使用者更好的聊天體驗',
      rowText1: `客戶端是自由及開放源代碼軟件，但服務端是專有軟件。
      用戶可以相互交換加密與自毀消息，發送照片、視頻等所有類型文件。
      官方提供手機版、桌面版和網頁版等多種平臺客戶端`,
      rowText2: '',
      dataList: [
        {
            title: '貼上',
            text: '即時動態記事貼文，支援多種文件、照片或文字！',
        },
        {
            title: '閱後即焚',
            text: '提前安排寄送時間與內容，定時自動發送',
        },
        {
            title: '快速切換聊天室',
            text: '快速切換聊天室',
        },
        {
            title: '已讀資訊',
            text: '觀看訊息讀取狀態',
        },
        {
            title: '紅包',
            text: '羣發、私聊紅包發送',
        },
        {
            title: '自動語音播放',
            text: '手機靠近即可聽取語音訊息',
        },
      ]
    },
    waiter: {
      title: 'GO客服',
      homeText: '網絡安全就是爲了防範這種信息盜竊和商業競爭攻擊所採取的措施',
      rowText1: `GO客服系統是集軟件、推廣、營銷、管理爲一體的高效客服系統。 
      即時聊天快速接入，訪客詳細信息，智能機器人服務，多客服多平臺對話分配，數據分析保存歷史會話等全面穩定的功能。 
      系統專爲海外客戶優化，Tpds級防護，AI智能防禦攻擊。`,
      rowText2: '',
      dataList: [
        {
            title: '整合簡單 快速部署',
            text: '您只需要簡單的幾步可以使您的網站擁有客服功能，爲您的客戶提供最優服務。'
        },
        {
            title: '界面簡潔 操作方便',
            text: 'GO客服系統，界面簡潔，客服和管理員可以快速學習和使用，系統流程高效、快速穩定。',
        },
        {
            title: '安全高效 私有化部署',
            text: 'GO系統，全面支持私有化部署，幫助你的企業可以更加安全高效的進行客服作業。',
        },
        {
            title: '強大後臺 高效管理',
            text: 'GO系統整合信息管理和客服管理，提高團隊效率，工作質量，同時兼顧客戶體驗充分了解您的客戶。',
        },
        {
            title: '服務保障 強大後盾',
            text: '客服系統爲您提供優質的售後服務，精英團隊爲您解決使用中的一切困難，保障您使用的舒適穩定。',
        },
        {
            title: '多域名多平臺',
            text: '全面支持多域名多平臺簡單部署，讓您高效掌握全局，輕鬆管理多個客服。',
        },
    ]
    },
    //新增修改内容
    sport: {
      title: '體育API',
      homeText: '我們主要提供足球、籃球以及其他一些流行的體育比賽的數據。除了足球和籃球之外，我們還支持棒球、排球和羽毛球等多種體育項目。',
      rowText1: '主要提供足球、籃球以及其他一些流行的體育比賽的數據。除了足球和籃球之外，我們還支持棒球、排球和羽毛球等多種體育項目。 我們的API可以爲您提供包括比賽得分、賽事數據等多種信息。使用我們的API，您可以輕鬆地獲取最新的比賽數據，以及歷史比賽數據和各種有用的統計信息。 無論您是體育網站、體育APP，還是其他體育相關企業，我們都能爲您提供最全面、最準確的體育數據服務，幫助您提高用戶體驗和業務價值。',
      rowText2: '',
      subhead: '提供遊戲類型',
      description: '',
      dataList: [
        {
          title: '英格蘭超級聯賽',
          cont: '通常被稱爲英超，是英格蘭足球最高等級的賽事類別亦是世上最頂級的足球聯賽之一，由英格蘭足球總會於1992年2月20日確立，自1992–93球季面世'
        },
        {
          title: '意大利足球甲組聯賽',
          cont: '意大利足球聯賽系統的第1級別 ，亦是職業聯賽的最高級別，聯賽系統的最高級別和意大利頂級足球聯賽，由意大利足球總會所管理，意甲職業聯盟營運。'
        },
        {
          title: '法國足球甲級聯賽',
          cont: '法國足球聯賽系統的第1級別，亦是職業聯賽的最高級別，聯賽系統的最高級別和法國頂級足球聯賽，由法國足球協會監管下的法國職業足球聯賽所負責監督、組織及管理。'
        },
        {
          title: '德國足球甲級聯賽',
          cont: '德國足球最高等級的賽事類別，由德國足球協會於1962年7月28日在多特蒙德確立，自1963-64賽季面世。'
        },
        {
          title: '西班牙足球甲級聯賽',
          cont: '西班牙足球聯賽系統的第1級別，亦是職業聯賽的最高級別，聯賽系統的最高級別和西班牙頂級足球聯賽。目前西甲有 20 支球隊，最後三名會降至西班牙足球乙級聯賽。'
        },
        {
          title: 'FIFA世界盃',
          cont: '成立於 1904 年，負責監督比利時、丹麥、法國、德國、荷蘭、西班牙國家協會之間的國際比賽、瑞典和瑞士。總部設在瑞士蘇黎世，其成員現在包括211個國家協會。'
        },
        {
          title: '歐洲冠軍聯賽',
          cont: '歐洲足聯主辦的年度足球比賽，代表歐洲俱樂部足球最高榮譽，組織完善高效，被譽爲全世界最高競技水平的俱樂部盃賽，亦是世界上獎金最高的足球賽事和體育賽事之一。'
        },
        {
          title: '中國足球協會超級聯賽',
          cont: '中國足球協會所主辦的最高等級職業足球聯賽。該聯賽開始於2004年，脫胎自原中國足球甲級A組聯賽。'
        },
        {
          title: 'DOTA2國際賽',
          cont: 'Dota 2開發商威樂公司舉行的電子競技比賽，比賽將由十六個受邀請的隊伍完成。這項賽事在2011年5月25日科隆國際遊戲展上首次進行，也是到今爲止獎金額度最高的比賽。'
        },
        {
          title: '英雄聯盟全球總決賽',
          cont: '2017年開始，英雄聯盟全球總決賽擴大規模。外卡巡迴賽被取消，改爲由擴大的英雄聯盟全球總決賽直接包含原外卡巡迴賽的功能。'
        },
        {
          title: '穿越火線職業聯賽',
          cont: '穿越火線在中國大陸地區的頂級賽事。由騰訊遊戲主辦，量子體育承辦。自2012年首屆聯賽舉辦以來，CFPL已經創辦了15個賽季。'
        },
        {
          title: 'CS:GO超級聯賽',
          cont: 'CS:GO超級聯賽。第一屆於2016年3月分開啓。是由國家體育總局體育信息中心主辦，上海華奧電競信息科技有限公司承辦。'
        }
      ]
    },
          // 棋牌api
          chessAndCard: {
            title: '棋牌API',
            homeText: '提供多種經典的棋牌遊戲，包括牛牛、炸金花、龍虎、跑得快、掃雷等遊戲。玩法創新，更加註重遊戲的安全性和可靠性。',
            rowText1: '提供多種經典的棋牌遊戲，包括牛牛、炸金花、龍虎、跑得快、掃雷等遊戲。我們的遊戲界面設計美觀、玩法創新，更加註重遊戲的安全性和可靠性。無論您身在何處，只需要一部手機或電腦，就可以輕鬆進入我們的遊戲大廳，和其他玩家一起競技。',
            rowText2: '',
            subhead: '提供遊戲類型',
            description: '',
            dataList: [
              {
                title: '牛牛',
                cont: '遊戲中使用一副共五十四張的撲克牌，每個玩家手中分配五張牌。玩家需要將手中的五張牌分成三張牌和兩張牌的兩組，使得三張牌的點數之和是10的倍數，剩餘兩張牌的點數之和儘可能大。'
              },
              {
                title: '炸金花',
                cont: '遊戲使用一副共五十四張的撲克牌（包括兩個大小王），每個玩家手中分配三張牌。牌型從大到小依次爲：豹子、順金、金花、順子、對子以及單張牌。'
              },
              {
                title: '鬥神獸',
                cont: '遊戲使用五十四張牌(不含大小王)，以青龍、白虎、朱雀、玄武四個閒家板塊進行遊戲，發牌爲四板塊與莊家發一張牌比較。'
              },
              {
                title: '三公',
                cont: '每個玩家手中分配三張牌，通過比較牌面大小來決定勝負。運用推斷和心理戰術，也有一定的挑戰性。'
              },
              {
                title: '跑得快',
                cont: '戲中使用一副共五十四張的撲克牌，包括大小王。由一名玩家出牌，其他玩家需要依次出牌並按照規則進行跟牌或不跟牌。需要一定的運氣和策略，是一種富有競爭性和娛樂性的卡牌遊戲。'
              },
              {
                title: '十三水',
                cont: '遊戲中使用一副共五十四張的撲克牌，包括大小王。牌型分爲豹子、順子、同花順、同花、對子、高牌六種。'
              },
              {
                title: '鬥地主',
                cont: '一副共五十四張的撲克牌（包括兩個大小王），留下三張底牌，底牌確定地主前不能看，等待確定地主後，底牌歸地主，且明示底牌。'
              },
              {
                title: '奔馳寶馬',
                cont: '遊戲有四個汽車品牌的八個布同賠率的下注板塊，可在八個板塊任意下注。'
              },
              {
                title: '麻將系列',
                cont: '共一百一十二張牌，遊戲一開始只有莊家可以得到十四張牌，其餘十三張牌。'
              },
              {
                title: '牌九',
                cont: '當前有”鍋”、”大牌九”、”小牌九”、”鬼子和天九王”四種玩法。玩家需要具備一定的策略性和判斷力，以便在遊戲中獲勝。'
              },
              {
                title: '百家樂',
                cont: '採用八副牌共416張進行遊戲，開局莊閒個發兩張牌，依據普牌規則決定是否補牌，補牌結束將所有牌點數加總取個位數，更接近9一方獲勝。'
              },
              {
                title: '掃雷',
                cont: '有”莊與閒”、"發紅包"、”搶紅包”、”中雷賠付”四種玩法，掃雷遊戲通常具有一定的遊戲性和競爭性'
              }
            ],
            dataListTwo: [
              {
                title: '百人三公'
              },
              {
                title: '搶莊經典牛'
              },
              {
                title: '搶莊點子牛'
              },
              {
                title: '百人牛牛'
              },
              {
                title: '鍋底點子牛'
              },
              {
                title: '無限公牛'
              },
              {
                title: '百人鬥神獸'
              },
              {
                title: '鍋底經典牛'
              },
              {
                title: '跑得快'
              },
              {
                title: '百人龍虎'
              },
              {
                title: '炸金花'
              },
              {
                title: '三公暗一'
              },
              {
                title: '奔馳寶馬'
              },
              {
                title: '三公全暗'
              },
              {
                title: '三公比金花'
              },
              {
                title: '百家樂'
              },
              {
                title: '紅中麻將(兩人)'
              },
              {
                title: '紅中麻將(四人)'
              }
            ]
          },
      // 彩票API
      lottory: {
        title: '彩票API',
        homeText: 'API包含了哈希彩、快開彩、境外彩及低頻彩，可以幫助用戶實現準確、高效、便捷的彩票出租需求。',
        rowText1: 'API包含了哈希彩、快開彩、境外彩及低頻彩，可以幫助用戶實現準確、高效、便捷的彩票出租需求。我們的API提供了多種數據接口，可根據用戶的需求進行定製化的數據服務。我們的API數據全面、準確、及時，可以幫助用戶瞭解市場動態、分析市場趨勢、優化彩票出租業務。',
        rowText2: '',
        dataList: [
          {
            title: '哈希彩',
            content: '哈希值彩票是一種基於區塊鏈技術的全新彩票玩法。開獎過程也是通過區塊鏈技術進行的，系統會隨機選取一條區塊鏈，由於區塊鏈的不可篡改性，這種彩票玩法可以保證彩票結果的公平和公正。'
          },
          {
            title: '快開彩',
            content: '是一種基於數字技術的高頻彩票遊戲，玩法類似於傳統彩票的“即開型”遊戲。開獎時間非常快，通常在幾分鐘內就能得到開獎結果。'
          },
          {
            title: '境外彩',
            content: '是指在中國以外的其他國家地區開展的彩票遊戲。這些彩票遊戲可能會提供比本地彩票更高的獎金、更多的玩法以及更多的選擇。'
          },
          {
            title: '低頻彩',
            content: '該類遊戲通常是通過購買數字或符號等形式的彩票，在開獎時，根據所選數字或符號與開獎結果的匹配程度，來決定是否中獎的一種遊戲。'
          }
        ]
      },
      // 老虎机API
      tiger: {
        title: '老虎機API',
        homeText: '我們的API，您可以輕鬆地創建自己的老虎機遊戲。',
        rowText1: 'API是由我們自己的團隊進行研發的，具有很高的靈活性和可擴展性。使用我們的API，您可以輕鬆地創建自己的老虎機遊戲。',
        dataList: [
          {
            title: '主題老虎機',
            contentText: '基於各種不同的主題，包括古代神話、動物王國和歷史時期等等。'
          },
          {
            title: '多線老虎機',
            contentText: '單次遊戲中，玩家可用多條賠付線增加獲勝機會。在任何一條賠付線上命中符號即可獲得獎勵。'
          },
          {
            title: '傳統老虎機',
            contentText: '要在轉輪上命中一組匹配的符號來獲得獎勵。通常有幾種不同的符號和賠付線類型可以在遊戲中使用。'
          }
        ]
      },
      // 彩票信用网
      credit: {
        title: '彩票信用網',
        homeText: '支持多種彩票類型，包括哈希彩、極速時時彩、極速賽車及六合彩等等。',
        rowText1: '支持多種彩票類型，包括哈希彩、極速時時彩、極速賽車及六合彩等等。不同的彩票類型提供了不同的玩法和獎金，用戶可以根據自己的喜好和投注策略選擇不同的彩票種類和投注方式。',
        dataList: [
          {
            title: '香港六合彩',
            contentText: '是香港地區唯一的合法彩票。由香港賽馬會以香港馬會獎券有限公司的名義開彩。開獎日期不固定，於北京時間晚上9:30開獎。',
          },
          {
            title: '幸運飛艇',
            contentText: '幸運飛艇是由馬耳他共和國瓦萊塔福利聯合委員會獨家發行。每日開獎180期，每5分鐘開獎1次。每期開獎結果都會通過多種渠道進行公示，確保公正公平。',
          },
          {
            title: '加拿大卑斯',
            contentText: '開獎結果來源於加拿⼤彩票公司BCLC的KENO開獎數據。開獎時間夏令與冬令時間不同，每3.5分開獎，每日開獎395期。',
          },
          {
            title: '斯洛伐克',
            contentText: '採用斯洛伐克共和國財政部獨家授權-TIPOS國家樂彩公司發行的KENO快速開獎彩票。於北京時間10:55～次日5:45開獎，每5分開獎。',
          },
          {
            title: '極速賽車',
            contentText: '極速賽車爲引進歐洲博彩公司開獎設備，由菲律賓博彩執照公司審覈監控。於北京時間7:59～次日7:57。每100秒開獎，每日開獎864期。',
          },
          {
            title: 'MD5時時彩',
            contentText: 'MD5時時彩爲引進歐洲博彩公司開獎設備，由菲律賓博彩執照公司審覈監控。於北京時間0:00～23:59開獎，每120秒開獎，每日開獎720期。',
          },
          {
            title: '哈西28',
            contentText: '獎號來源是在Tron區塊鏈上的一筆真實交易，取交易所在的區塊哈希值。每筆交易歷程都可通過錢包查詢得到，保證號源數據絕對的公平、公正且不可篡改。',
          },
          {
            title: '以太坊賽車',
            contentText: '以太坊區塊鏈上所進行的交易，交易哈希是一個唯一的66字符的標識符。抓取固定時間內一筆未確認交易信息的哈希值作爲隨機種子。',
          }
        ]
      },
      // 平安支付
      payment: {
        title: '平安支付',
        homeText: '支付工具支持使用銀行卡進行人民幣支付，讓您的支付過程更加便捷和快速。',
        rowText1: '我們的支付工具支持使用銀行卡進行人民幣支付，讓您的支付過程更加便捷和快速。我們的支付系統安全可靠，能夠保障您的支付安全和資金安全。目前支持USDT收款及人民幣支付。您可以使用平安支付向他人支付人民幣或收取USDT，還可以方便地將USDT兌換成人民幣。',
        dataList: [
          {
            title: '安全性高',
            text: '其付款和交易過程受到嚴格的加密和安全措施保護。',
          },
          {
            title: '方便快捷',
            text: '支付過程非常簡便，無需繁瑣的銀行轉賬或匯款流程。',
          },
          {
            title: '退款保障',
            text: '如我坊責任導致雙向司法凍結，我方承諾全額賠付！',
          },
          {
            title: '費用低廉',
            text: '低於市場匯率，歐意買價匯率，代付1.5%。',
          }
        ]
      },
      // 人才招聘
      recruitment: {
        title: '人力資源(HRBP)',
        homeText: '我們致力於打造一個有活力、開放、多元化的企業文化，鼓勵員工自我發展，爲員工提供廣闊的職業發展空間。'
      }
  },
          // 新增人才招聘
        recruitmentLang: {
          title: '人才招聘',
          rowText1: `在華創公司，我們致力於打造一個有活力、開放、多元化的企業文化，鼓勵員工自我發展，爲員工提供廣闊的職業發展空間。作爲HR Business Partner，您將與各級管理層、員工和業務部門建立良好的關係，推動企業的戰略實施，爲員工和企業的雙贏不斷努力。經過長期的磨合，和團隊塑造，團隊招募能力超過月30+人數，能在人員供應上，帶來不可估量的幫助。`,
          dataList: [
            {
              title: '更好的人力資源管理',
              content: '確保組織在人力資源方面遵守最佳實踐，並協助制定和執行政策和程序。'
            },
            {
              title: '戰略性合作',
              content: '管理人員緊密合作，以確保人力資源策略與組織戰略保持一致，並支持業務目標的實現。'
            },
            {
              title: '增強員工體驗',
              content: '員工在工作中獲得滿意度和幸福感，並提供支持和發展機會，以增強員工對組織的忠誠度。'
            },
            {
              title: '增強組織績效',
              content: '可以幫助組織通過提高員工績效和發展計劃來實現業務目標，並通過監測關鍵績效指標來確定成功的策略。'
            },
            {
              title: '反應更快',
              content: '可以更快地反應並解決員工和管理人員的問題，從而幫助組織避免潛在的人力資源問題和風險。'
            }
          ]
        }
}