// 马来文语言包
export default {
    testData: 'ujian',
    language: 'Bahasa Cina Ringkas',
    route: {
      home: 'Laman Utama',
      temp: 'Templat',
      serve: 'Kelebihan perkhidmatan',
      ourselves: 'Tentang kami',
      recruitment: 'Pemerolehan bakat'
      },
    mailbox: 'Peti Mel',
    price:'Harga',
    flow: 'kadar aliran',
    quantity: 'Kuantiti',
    applyNow: 'Mohon sekarang',
    yaer: 'Tahun',
    month: 'Bulan',
    day: 'Hari',
    a: 'Sekeping',
    customCharge:'Caj tersuai',
    customization: 'Penyesuaian',
    languageSetting: 'Tetapan Bahasa',
    viewDetails: 'Lihat Butiran',
    viewTemp: 'Lihat Templat',
    knowDetails: 'Ketahui lebih lanjut',
    GoToTrialPlay: 'Pergi untuk mencuba',
    businessRelation: 'Hubungan perniagaan',
    GoSports: 'Pergi ke Sukan GO',
    contactUs: 'Hubungi kami',
    pleaseLookForwardTo: 'Ciri demo Nantikan',
    theme: 'Tema',
    howToCharge: 'Sila masukkan soalan anda',
    contactEmail: 'Hubungi peti mel',
    yourContactEmail: 'Sila masukkan peti mel anda',
    problemDescription: 'Huraian masalah',
    yourQuestion: 'Sila huraikan masalah anda',
    definiteSendOut: 'Pastikan untuk menghantar',
    homePageLang: {
      productTitle: 'Produk ekologi yang komprehensif',
      productIntroduce: 'Rangkaian pakej GO telah mengumpulkan teknologi selama bertahun-tahun, dan pelbagai produk telah membina matriks produk',
      firmTitle: 'Pengilang rakan kongsi',
      firmIntroduce: 'Rangkaian pakej GO telah mengumpulkan teknologi selama bertahun-tahun, dan pelbagai produk telah membina matriks produk',
      clientTitle: 'Melayan pelanggan',
      clientIntroduce: 'Dengan "kejayaan pelanggan" sebagai teras, ia benar-benar mencipta nilai untuk pelanggan'
    },
    servePageLang: {
      funTitle: 'Perkhidmatan',
      advantageTitle:'Kelebihan',
      funDataList: [
        {
          title: 'Permainan tripartit terkaya'
        },
        {
          title: 'Sokongan untuk pelbagai bahasa'
        },
        {
          title: 'Sistem platform terminal penuh'
        },
        {
          title: 'Sistem ejen automatik yang fleksibel dan komprehensif'
        },
        {
          title: 'Analisis AI Big Data'
        },
        {
          title: 'Reka bentuk eksklusif yang disesuaikan'
        }
      ],
      advantageDataList: [
        {
          title: '7 * 24 jam pemantauan'
        },
        {
          title: 'Data awan multipoint'
        },
        {
          title: 'Amaran risiko yang mencurigakan'
        },
        {
          title: 'Firewall Keselamatan Negara'
        },
        {
          title: 'Kelayakan Penyulitan SSL'
        },
        {
          title: 'Cadangan penyulitan data'
        },
        {
          title: 'Tahan serangan DDoS'
        },
        {
          title: 'Pengurusan laporan platform yang komprehensif'
        }
      ]
    },
    tempPageLang: {
      AG: 'AG',
      Macau: 'Makau',
      physicalEducation: 'Sukan',
      chessAndCards: 'Catur',
      lottery: 'Loteri',
      esports: 'E-sukan',
      catchfish: 'Memancing',
      realPerson: 'Orang sebenar',
      electron: 'Elektron',
      //  子内容
      Kaifa: 'Hyflux',
      J9: 'J9',
      Hutchison: 'Hutchison',
      newLisboa: 'Grand Lisboa',
      GalaxyMacau: 'Macau Galaxy',
      SunCity: 'Bandar Raya Sun',
      MGMMirage: 'MGM',
      Venetian: 'Venice',
      GoldenSands: 'Pasir',
      NewCentury: 'Kaiyuan',
      Yunyu: 'Menjelajah',
      TangDynasty: 'Bandar namaCity in Quebec Canada',
      TianchengLottery: 'Tiancheng Lottery',
      PhoenixLottery: 'Loteri Phoenix',
      apricot: 'Warna aprikot',
      panAsia: 'Pan-Asia',
      LightningFire: 'Guruh',
      OBfishing: 'OB memancing',
      BGfishing: 'BG memancing',
      skyhunter: 'Pemburu Langit',
      FishingKing3D: 'Fishing King 3D',
      FishingKing: 'Memancing raja',
      FishingPark: 'Taman Memancing',
      OBRealPerson: 'OB orang sebenar',
      AGRealPerson: 'AG Real Man',
      BGRealPerson: 'BG Real People',
      OBelectron: 'Elektronik OB',
      AGelectron: 'PG Electronics',
      LGDelectron: 'Elektronik LGD'
    },
    ourselvesPageLang: {
      title: 'Kumpulan GO',
      contentText1: 'GO Group, yang dikenali sebagai Great Online Group, berhasrat untuk membina pasukan label putih teratas dalam industri permainan.',
      contentText2: `Sepuluh tahun pengalaman, pasukan penyelesaian projek kelas pertama di Asia, secara aktif mencari teknologi dan idea inovatif. I
      a mempunyai pasukan produk profesional untuk merancang keperluan, sentiasa meneroka konteks pembangunan pasaran sepanjang tahun, menyediakan hampir 10,000 hiburan permainan papan, mesin slot, orang sebenar sukan dan platform lain, dan menyediakan deposit dan pengeluaran dalam talian. 
      Operasi satu klik, menyediakan data operasi teras, analisis pelbagai dimensi data pemain, data saluran, data kos dan data pendapatan, membantu pemasar membuat keputusan yang tepat.`,
      contentText3: `Teknologi anti-rampasan dengan hak harta bebas (permohonan paten telah dibuat), semakan kelompok satu klik, penyelesaian automatik, pelepasan aktiviti yang mudah dan cekap. M
      engintegrasikan pemindahan akaun dan data segerak untuk mencapai konsep hiburan tanpa gangguan pada bila-bila masa, di mana sahaja. S
      emua perisian dan sumber perkakasan laman web adalah eksklusif, dan berbilang salinan dikonfigurasi, dan pertukaran pintar memberi anda persekitaran yang boleh dipercayai dan stabil. S
      istem canggih yang membezakan diri mereka dari yang luar biasa. 
      Menantikan siasatan anda!`,
      title2: 'Pencapaian'
    },
    sonArticleContent: {
        goBao: {
          title: 'Label Putih GO',
          homeText: `Kami mengambil teknologi sebagai panduan, terus mempromosikan penerapan teknologi canggih dan terpakai, menggalakkan pertumbuhan perniagaan pelanggan, dan mencapai penciptaan bersama, kerjasama dan menang-menang`,
          rowText1: `Projek GO Bowen ditubuhkan pada tahun 2012, telah dibangunkan selama lebih dari 10 tahun, berkhidmat beratus-ratus + pelanggan, dan pasukan teknikal mempunyai 100 +, memastikan operasi projek yang stabil 24 jam sehari. M
          embangunkan 60+ jenis templat yang berbeza, memenuhi 90% keperluan pasaran. 
          Jika anda fikir, templat pasaran tidak dapat dipenuhi, kami akan dari reka bentuk interaksi, reka bentuk antara muka hingga penyesuaian fungsi, kami menyediakan pelbagai penyelesaian, termasuk PC, H5, tandatangan IOS, pelayan, nama domain dan perkhidmatan sehenti yang lain.`,
          rowText2: `Menyediakan versi bahasa Cina, Inggeris, Vietnam dan bahasa Cina yang lain, serta sebarang perkhidmatan penyesuaian bahasa; M
          enggunakan pembangunan teknologi asli tulen, menyelesaikan sepenuhnya masalah kehilangan dana cas semula, rampasan lalu lintas, dan lain-lain, dan memastikan keselamatan laman web; 
          Lebih daripada 200 pilihan permainan, tiada pemantauan keselamatan buntu, pengiring sepanjang masa!`,
          netPrice: {
            title: 'Harga label putih',
            openingCharge: {
              title: 'Yuran permulaan',
              dataList: [
                {
                  content: 'Konfigurasi teknikal pasukan profesional untuk membimbing pembukaan stesen'
                },
                {
                  content: 'Pereka bentuk UI kanan membantu persembahan halaman'
                },
                {
                  content: 'Pasukan teknikal profesional menggunakan laman web ini dengan cekap dan cepat'
                },
                {
                  content: 'Dok percuma pembayaran pihak ketiga, platform SMS, dll'
                },
                {
                  content: 'Penggunaan projek pasukan O&M, resolusi nama domain, dsb'
                }
              ]
            },
            BasicCharge: {
              title: 'Caj asas',
              content1: 'Selepas rundingan akhir, penghantaran dihantar mengikut jadual',
              content2: 'APP asli, PC, H5',
              content3: '(Platform tersuai atau perniagaan hubungan permintaan dalam masa)',
            }
          },
        productAdvantage: {
          title: 'Kelebihan produk',
          dataList: [
            {
              title: 'Sesuaikan produk berkelajuan tinggi dengan cepat',
              content: `Perkhidmatan jenama reka bentuk mewah, teknologi yang sangat baik, tetapi juga baru dalam industri. 
              Selagi anda mempunyai lalu lintas, kami dapat mengewangkan anda!`
            },
            {
              title: 'Pengalaman produk berkualiti',
              content: 'Terminal mudah alih paling ekstrem dalam industri ini telah melengkapkan tiga terminal, penyulitan berbilang terminal untuk dirampas, dan seluruh orang mempromosikan tanpa bimbang.'
            },
            {
              title: 'Khidmat operasi 7*24 jam',
              content: 'Perkhidmatan pelanggan profesional 7 * 24 jam, pasukan kawalan risiko pasukan kewangan ACB dok penuh, setiap tuduhan kedua.'
            }
          ]
        }
      },
      integrated: {
        title: 'Stesen warna bersepadu',
        homeText: 'Kebajikan filem domestik warna huru-hara, AV HD Jepun dan Korea, warna perbandingan filem domestik Jepun, Korea Selatan, Eropah dan Amerika Syarikat video filem AV dalam talian HD dewasa percuma'
      },
      video: {
        title: 'Sintesis video',
        homeText: 'Menyediakan video kecantikan yang paling profesional dalam industri',
        rowText1: `Penyepaduan video membawa pemain pengalaman hiburan yang belum pernah terjadi sebelumnya dan merupakan produk yang mesti dimiliki untuk anda meningkatkan perniagaan anda. U
        ntuk tujuan ini, GO Group telah bekerjasama dengan penyedia perisian permainan dalam talian teratas di dunia untuk membolehkan pengendali hiburan menambah kandungan terkini ke halaman label putih mereka. P
        ada masa yang sama, kami menyediakan pemain dengan pelbagai jenis pengalaman permainan, grafik yang tiada tandingan dan teknologi canggih, mewujudkan pengalaman mendalam dan interaktif untuk menarik perhatian pemain. D
        ewan antarabangsa spesifikasi tinggi dilengkapi dengan peralatan canggih dan hiasan yang indah untuk mencipta pengalaman permainan video langsung yang hebat untuk pemain. 
        Pelbagai pilihan permainan, termasuk baccarat, sic bo, rolet, dan lain-lain, serta pengurusan pemantauan sepanjang masa, menyediakan pemain dengan perkhidmatan permainan yang stabil dan lancar.`,
        rowText2: '',
        dataList: [
          {
            pictitle: 'Baccarat klasik',
            title: 'Baccarat klasik',
            cont: `Baccarat adalah salah satu permainan paling popular di dunia. B
            accarat pertama enam kad pertama dicipta untuk memastikan permainan yang adil dan meningkatkan keyakinan pemain terhadap platform. 
            Di samping itu, kami juga telah membangunkan baccarat pertandingan meja LED eksklusif dan baccarat kelajuan 25 saat, memberikan pemain keseronokan sebenar berada di kasino langsung.`
          },
          {
            pictitle: 'Jingmi Baccarat',
            title: 'Jingmi Baccarat',
            cont: `Jingmi Interactive Baccarat telah melancarkan fungsi pemotongan kad manual, yang boleh dinikmati oleh pemain dengan kuota terbesar 20 saat. 
            Melalui video yang diperbesarkan sebahagiannya dan garis putus-putus yang dipaparkan pada kad untuk membantu pemotongan kad, permainan ini benar-benar adil dan saksama, dan pada masa yang sama, autonomi pemain sangat meningkat, dan realisme sama dengan kasino sebenar.`
          },
          {
            pictitle: 'Insurans Baccarat',
            title: 'Insurans Baccarat',
            cont: 'Insurans Baccarat adalah salah satu ciri baru yang paling popular dalam beberapa tahun kebelakangan ini, seperti namanya, menambah elemen insurans pada permainan, bukan sahaja tidak menjejaskan hasil permainan baccarat, tetapi juga melindungi pemain daripada kehilangan semua jumlah pertaruhan.'
          },
          {
            pictitle: 'Longbaccarat',
            title: 'Longbaccarat',
            cont: `Dragon Treasure akan mempunyai peluang yang berbeza bergantung kepada bilangan mata yang dimenangi oleh satu sisi pertaruhan, dengan pembayaran maksimum 30x dan tiada kemenangan margin. 
            Jika anda bertaruh pada salah satu pihak yang menang dan memenuhi salah satu daripada dua kriteria berikut, anda akan menerima pembayaran.`
          },
          {
            pictitle: 'VIP Baccarat',
            title: 'VIP Baccarat',
            cont: 'Selepas meja VIP, anda boleh mengawal rentak permainan sendiri, dan menikmati fungsi unggul seperti menukar peniaga, kad terbang dan menukar but, untuk mencapai hiburan yang lebih maju dan bebas.'
          },
          {
            pictitle: 'Harimau Naga',
            title: 'Harimau Naga',
            cont: `Dragon Tiger Selepas menyertai platform permainan langsung, biarkan lebih ramai pemain menyambung ke platform hiburan kami di komputer mereka dan mana-mana peranti mudah alih, dan rasakan pengalaman baru berada di tempat kejadian, dan cuba nasib mereka!`
          },
          {
            pictitle: 'Lembu lembu',
            title: 'Lembu lembu',
            cont: `Niu Niu Juga dikenali sebagai bullfighting, Niu Niu ialah permainan papan tradisional yang popular di Guangdong, Guangxi, Hunan, Jiangsu dan Zhejiang. S
            alah satunya ialah peniaga, selebihnya adalah pemain, dan kad dimainkan sebaik sahaja kad ditangani, dan semua pemain membandingkan dengan peniaga satu demi satu untuk menentukan kemenangan atau kerugian. 
            Permainan ini ketat dan menarik, dengan peraturan mudah.`
          },
          {
            pictitle: 'Pukul 21',
            title: 'Pukul 21',
            cont: `21 mata berasal dari Perancis dan menang selagi kad permainan pemain mempunyai maksimum 21 mata dan lebih besar daripada mata peniaga. 
            Matlamat utama tangan adalah untuk mempunyai sejumlah 21 mata (atau paling dekat dengan 21 mata) dan kemudian bandingkan saiz dengan peniaga.`
          },
          {
            pictitle: 'Bunga emas goreng',
            title: 'Bunga emas goreng',
            cont: `Bunga Emas Goreng (Bunga Emas Palsu), juga dikenali sebagai Win Three Cards, adalah permainan kad berbilang pemain rakyat yang tersebar luas di seluruh negara. 
            Pemain mempunyai peraturan unik untuk membandingkan saiz tiga kad di tangan mereka.`
          },
          {
            pictitle: 'Roulette',
            title: 'Roulette',
            cont: `Permainan rolet kehidupan hidup yang paling realistik. M
            elalui rakaman langsung masa nyata, ditambah pula dengan roda rolet yang memeningkan, kemahiran peniaga dan profesional, semuanya mewujudkan permainan pertaruhan yang adil dan saksama untuk pemain. P
            ermainan ini menggunakan rolet sifar tunggal standard antarabangsa. 
            Pemain boleh membeli satu nombor atau gabungan nombor daripada jadual.`
          },
          {
            pictitle: 'Sic Bo',
            title: 'Sic Bo',
            cont: `Live Sic Bo memecahkan padang baru dan memberi pemain perasaan baru yang tidak pernah dilihat sebelum ini, menjadikan mereka menyeronokkan. J
            uga dikenali sebagai saiz pertaruhan, pertaruhan untuk meneka bilangan mata yang ditarik oleh dadu atau jumlah mata. 
            Pelbagai jenis pertaruhan dan peluang menarik sangat popular dengan pemain.`
          },
          {
            pictitle: 'Pelbagai permainan',
            title: 'Pelbagai permainan',
            cont: `Di sini anda boleh memilih pelbagai jenis permainan mengikut keinginan anda dan meletakkan pertaruhan pada masa yang sama. 
            Apa yang perlu anda lakukan ialah tekan butang 'Pilih Jadual' untuk mengatur kedudukan jadual.`
          }
        ]
      },
      CDN: {
        title: 'CDN',
        homeText: `Sistem rangkaian komputer yang disambungkan melalui Internet untuk menghantar gambar, video, aplikasi dan fail lain kepada pengguna dengan lebih cepat dan lebih dipercayai, menggunakan pelayan yang paling dekat dengan setiap pengguna`,
        rowText1: `Sebagai penyedia CDN dengan teknologi terkemuka, GO White Label Label telah membina seni bina penghantaran kandungan generasi akan datang sejak tahun 2012. M
        isi kami adalah untuk membangunkan dan mereka bentuk penyelesaian penghantaran kandungan yang boleh diakses oleh seberapa ramai orang yang mungkin. I
        a bukan sahaja perkhidmatan asas CDN dari segi prestasi dan keselamatan, tetapi juga mudah dan kos efektif untuk pengguna CDN. 
        CDN kami memulakan anda dengan hanya beberapa klik, dan dengan pelbagai pilihan konfigurasi, kemudahan penggunaan membolehkan anda memberi tumpuan kepada perniagaan teras anda, dan kesederhanaan dan kemudahan penggunaan inilah yang membezakan produk kami daripada pesaing dengan nisbah prestasi harga yang hebat.`,
        rowText2: '',
        dataList: [
          {
            title: 'Pengedaran kandungan CDN',
            contentText: `CDN mempunyai lebih daripada 30 pusat data di lima benua, di mana kami mempunyai bilik pelayan anti-pesawat tiga peringkat di China, yang boleh cache kandungan statik di laman web anda dan secara automatik menunjuk pengguna ke nod terpantas.`,
          },
          {
            title: 'Perlindungan Laman Web',
            contentText: `Perlindungan laman web CDN anti-DDoS dapat melindungi kualiti akses laman web anda dan meningkatkan ketersediaan laman web anda, jadi anda tidak perlu risau ketika menghadapi serangan konvensional seperti DDoS, CC, dan rampasan.`,
          },
          {
            title: 'Firewall Aplikasi Web',
            contentText: `Menyediakan teknologi pengecaman cap jari laman web yang tepat dan enjin pemprosesan yang cekap, digabungkan dengan strategi anti-pertahanan yang dikemas kini masa nyata, meliputi jenis serangan arus perdana OWASP dan melindungi laman web anda daripada penggodam.`,
          },
          {
            title: 'Pengoptimuman akses',
            contentText: 'CDN termasuk sekumpulan ciri pengoptimuman web yang boleh memampatkan halaman web anda lebih daripada 50%, termasuk piawaian web terkini, digabungkan dengan CDN Anti-DDoS kami untuk mengoptimumkan kelajuan akses pengguna.',
          },
          {
            title: 'Pertahanan DDos tanpa had',
            contentText: 'TERLEPAS DARI SAIZ, JENIS, ATAU TEMPOH SERANGAN, IA AKAN MENANGKIS SEBARANG SERANGAN DDOS DI TEPI NOD UNTUK ANDA, DAN TIDAK ADA HAD ATAS.',
          },
          {
            title: 'Interaktif',
            contentText: `Ia mempunyai keupayaan pertahanan perkhidmatan pintar data besar, menyokong kawalan akses tepat tersuai pelbagai dimensi, dan bekerjasama dengan tindak balas seperti pengiktirafan manusia-mesin dan kawalan frekuensi, yang dapat secara bijak dan cekap menapis akses sampah dan berkesan mempertahankan terhadap serangan CC.`,
          }
        ],
        serviceItem: 'Item perkhidmatan',
        chargeDetails: 'Maklumat bayaran',
        setMealTitle: 'Pakej & Harga',
        setMealText1: 'Semua versi boleh menikmati akses tanpa had ke nama domain peringkat ketiga tanpa caj tambahan! Nod terletak di negara-negara Asia Tenggara, dan nama domain tanpa nombor pemfailan ICP boleh memasuki China dari Hong Kong, Korea Selatan, dan Jepun',
        setMealText2: 'Caj lebihan data: ● $ 1.12 setiap 1GB ● Bayaran pembayaran USDT adalah 1%',
        dataTypeList: [
          {
            title: 'Versi keusahawanan',
            titleExplain: 'Sesuai untuk kumpulan 5 atau kurang, nikmati 100GB/bulan',
            scopeText1: 'Sesuai untuk pasukan keusahawanan dengan sebilangan kecil orang',
            scopeText2: 'Bilangan nama domain peringkat kedua ialah 10',
            scopeText3: 'Harga dikenakan'
          },
          {
            title: 'Versi pro',
            titleExplain: 'Sesuai untuk pasukan kecil dan sederhana, memenuhi keperluan kerjasama pelbagai orang dan menikmati 100GB/bulan',
            scopeText1: 'Untuk pasukan bersaiz kecil hingga sederhana',
            scopeText2: 'Bilangan nama domain peringkat kedua ialah 100',
            scopeText3: 'Harga dikenakan'
          },
          {
            title: 'Versi perniagaan',
            titleExplain: 'Sesuai untuk syarikat bersaiz sederhana untuk memenuhi keperluan individu.',
            scopeText1: 'Untuk syarikat menaik taraf bersaiz sederhana',
            scopeText2: 'Bilangan nama domain peringkat kedua ialah 300',
            scopeText3: 'Pakej profesional'
          },
          {
            title: 'Edisi Perusahaan',
            titleExplain: 'Sesuai untuk penyesuaian peribadi satu-ke-satu, untuk memenuhi penyesuaian peribadi unik perusahaan individu.',
            scopeText1: 'Sesuai untuk penyesuaian peribadi',
            scopeText2: 'Perkhidmatan pelanggan hubungan domain peringkat kedua',
            scopeText3: 'Penyesuaian peribadi'
          }
        ]
      },
      etp: {
        title: 'Inkubasi produk',
        homeText: 'Pelancaran dan pelaksanaan produk dari 0 hingga 1',
        rowText1: `Pusat Keusahawanan Kumpulan adalah organisasi perkhidmatan profesional yang ditubuhkan oleh usahawan kecil dan sederhana, dan kami menyediakan bimbingan perniagaan teknikal percuma, perundingan pengurusan dan program bantuan lain.`,
        rowText2: '',
        dataList: [
          {
          title: 'Analisis produk',
          contentText: `Termasuk analisis permintaan, analisis pasaran, analisis pesaing, analisis dasar pengawalseliaan. 
          Merangka pelan perancangan awal dan mencadangkan pelan kitaran pembangunan awal.`,
          },
          {
          title: 'Penilaian produk',
          contentText: 'Orang teknikal yang bertanggungjawab terhadap ulasan industri dan menilai aplikasi produk dan menjalankan analisis pelaburan awal projek.',
          },
          {
          title: 'Pengurus projek',
          contentText: 'Menurut pengurus projek yang berpengalaman dalam industri, proses inkubasi produk secara profesional berjaya memaksimumkan nilai produk.',
          },
          {
          title: 'Pasukan pembangunan profesional',
          contentText: 'Dengan pasukan yang mempunyai pengalaman pembangunan yang kaya dalam industri, kami membangunkan produk dengan kualiti tertinggi dalam masa yang singkat.',
          },
        ]
      },
      software: {
        title: 'Perisian komunikasi',
        homeText: 'Pembangunan perisian komunikasi asli untuk menyediakan pengguna dengan pengalaman sembang yang lebih baik',
        rowText1:  `Pelanggan adalah perisian percuma dan sumber terbuka, tetapi pelayan adalah perisian proprietari. P
        engguna boleh bertukar-tukar mesej yang disulitkan dan merosakkan diri antara satu sama lain, menghantar foto, video dan semua jenis fail. 
        Pegawai ini menyediakan versi mudah alih, versi desktop dan versi web pelanggan platform`,
        rowText2: '',
        dataList: [
          {
              title: 'Melekat',
              text: 'Catatan nota dinamik masa nyata dengan sokongan untuk pelbagai dokumen, foto atau teks!',
          },
          {
              title: 'Bakar segera selepas membaca',
              text: 'Susun masa dan kandungan penghantaran terlebih dahulu, dan hantar secara automatik secara berkala',
          },
          {
              title: 'Tukar bilik sembang dengan cepat',
              text: 'Tukar bilik sembang dengan cepat',
          },
          {
              title: 'Maklumat yang dibaca',
              text: 'Tonton status bacaan mesej',
          },
          {
              title: 'Sampul merah',
              text: 'Hantar sampul merah dalam sembang pukal dan peribadi',
          },
          {
              title: 'Main balik suara automatik',
              text: 'Anda boleh mendengar mesej suara apabila telefon anda hampir',
          },
        ]
      },
      waiter: {
        title: 'Perkhidmatan pelanggan GO',
        homeText: 'Keselamatan siber adalah langkah yang diambil untuk mencegah kecurian maklumat dan serangan persaingan komersial seperti itu',
        rowText1: `Sistem perkhidmatan pelanggan GO adalah sistem perkhidmatan pelanggan yang cekap yang mengintegrasikan perisian, promosi, pemasaran dan pengurusan. F
        ungsi yang komprehensif dan stabil seperti akses pantas sembang segera, butiran pelawat, perkhidmatan robot pintar, perkhidmatan berbilang pelanggan, pengedaran perbualan berbilang platform, analisis data dan perbualan sejarah yang disimpan. 
        Sistem ini dioptimumkan untuk pelanggan luar negara, perlindungan peringkat Tpds, pertahanan pintar AI terhadap serangan.`,
        rowText2: '',
        dataList: [
          {
              title: 'Penyepaduan ringkas dan penggunaan pantas',
              text: 'Anda hanya memerlukan beberapa langkah mudah untuk menjadikan laman web anda mempunyai fungsi perkhidmatan pelanggan untuk memberikan perkhidmatan terbaik kepada pelanggan anda.'
          },
          {
              title: 'Antara muka yang mudah dan operasi mudah',
              text: 'Sistem perkhidmatan pelanggan GO, antara muka yang mudah, perkhidmatan pelanggan dan pentadbir dapat belajar dan menggunakan dengan cepat, proses sistem adalah cekap, cepat dan stabil.',
          },
          {
              title: 'Penggunaan peribadi yang selamat dan cekap',
              text: 'Sistem GO, menyokong sepenuhnya penggunaan peribadi, membantu perusahaan anda dapat menjalankan operasi perkhidmatan pelanggan dengan lebih selamat dan cekap.',
          },
          {
              title: 'Pengurusan cekap bahagian belakang yang kuat',
              text: 'GO mengintegrasikan pengurusan maklumat dan pengurusan perkhidmatan pelanggan untuk meningkatkan kecekapan pasukan, kualiti kerja, dan pengalaman pelanggan untuk memahami sepenuhnya pelanggan anda.',
          },
          {
              title: 'Jaminan perkhidmatan yang kukuh',
              text: `Sistem perkhidmatan pelanggan menyediakan anda dengan perkhidmatan selepas jualan berkualiti tinggi, dan pasukan elit menyelesaikan semua kesukaran yang digunakan untuk anda untuk memastikan keselesaan dan kestabilan anda.`,
          },
          {
              title: 'Pelbagai domain dan berbilang platform',
              text: 'Menyokong sepenuhnya nama berbilang domain dan penggunaan mudah berbilang platform, membolehkan anda memahami keadaan keseluruhan dengan cekap dan dengan mudah menguruskan pelbagai perkhidmatan pelanggan.',
          },
      ]
      },
      //新增修改内容
      sport: {
        title: 'API Sukan',
        homeText: 'Kami terutamanya menyediakan data mengenai bola sepak, bola keranjang dan sukan popular lain. Selain bola sepak dan bola keranjang, kami menyokong pelbagai sukan seperti besbol, bola tampar, dan badminton.',
        rowText1: 'Ia terutamanya menyediakan data mengenai bola sepak, bola keranjang, dan pertandingan sukan popular lain. Selain bola sepak dan bola keranjang, kami menyokong pelbagai sukan seperti besbol, bola tampar, dan badminton. API kami boleh memberi anda pelbagai maklumat, termasuk skor perlawanan, data acara dan banyak lagi. Menggunakan API kami, anda boleh mendapatkan data padanan terkini dengan mudah, serta data padanan sejarah dan pelbagai statistik berguna. Sama ada anda laman web sukan, aplikasi sukan atau perusahaan berkaitan sukan yang lain, kami boleh menyediakan perkhidmatan data sukan yang paling komprehensif dan tepat untuk membantu anda meningkatkan pengalaman pengguna dan nilai perniagaan.',
        rowText2: '',
        subhead: 'Sediakan jenis permainan',
        description: '',
        dataList: [
          {
            title: 'Liga Perdana Inggeris',
            cont: `Sering dirujuk sebagai Liga Perdana Inggeris, ia adalah tahap tertinggi bola sepak Inggeris dan salah satu liga bola sepak teratas di dunia, yang ditubuhkan oleh Persekutuan Bola Sepak Inggeris pada 20 Februari 1992 dan dilancarkan sejak musim 1992-93`
          },
          {
            title: 'Primera A Itali',
            cont: 'Tahap pertama sistem liga bola sepak Itali, tahap tertinggi liga profesional, tahap tertinggi sistem liga dan liga bola sepak Itali teratas, diuruskan oleh kelab bola sepak Itali dan dikendalikan oleh liga profesional Serie A.'
          },
          {
            title: 'Serie A Perancis',
            cont: `Tahap pertama sistem liga bola sepak Perancis, tahap tertinggi liga profesional, tahap tertinggi sistem liga dan liga bola sepak teratas di Perancis, diawasi, dianjurkan dan diuruskan oleh Liga Bola Sepak Profesional Perancis di bawah pengawasan Persatuan Bola Sepak Perancis.`
          },
          {
            title: `Bundesliga Jerman`,
            cont: `Tahap persaingan tertinggi dalam bola sepak Jerman telah ditubuhkan oleh Persatuan Bola Sepak Jerman di Dortmund pada 28 Julai 1962 dan telah wujud sejak musim 1963-64.`
          },
          {
            title: 'Primera Sepanyol División',
            cont: `Tahap 1 sistem liga bola sepak Sepanyol, ia juga merupakan tahap tertinggi liga profesional, tahap tertinggi sistem liga dan liga bola sepak teratas di Sepanyol. 
            La Liga kini mempunyai 20 pasukan, dengan tiga perlawanan akhir diturunkan ke divisyen kedua bola sepak Sepanyol.`
          },
          {
            title: 'Piala Dunia FIFA',
            cont: `Ditubuhkan pada tahun 1904, ia menyelia pertandingan antarabangsa antara persatuan kebangsaan di Belgium, Denmark, Perancis, Jerman, Belanda, Sepanyol, Sweden dan Switzerland. 
            Beribu pejabat di Zurich, Switzerland, keahliannya kini termasuk 211 persatuan kebangsaan.`
          },
          {
            title: 'Liga Juara-Juara UEFA',
            cont: `Pertandingan bola sepak tahunan yang ditaja oleh UEFA mewakili penghormatan tertinggi bola sepak kelab Eropah, dianjurkan dengan baik dan cekap, dan dikenali sebagai piala kelab kompetitif tertinggi di dunia, dan salah satu acara bola sepak hadiah tertinggi dan acara sukan di dunia.`
          },
          {
            title: 'Liga Super Persatuan Bola Sepak Cina',
            cont: `Liga bola sepak profesional peringkat tertinggi yang ditaja oleh Persatuan Bola Sepak Cina. 
            Liga ini bermula pada tahun 2004 dan dilahirkan daripada bekas Liga Bola Sepak Cina A.`
          },
          {
            title: 'Dota 2 Antarabangsa',
            cont: `Kejohanan e-sukan yang dianjurkan oleh pemaju Dota 2, Wilo akan disempurnakan oleh enam belas pasukan jemputan. 
            Kejohanan ini diadakan buat kali pertama di Pameran Sukan Antarabangsa Cologne pada 25 Mei 2011 dan mempunyai hadiah wang tunai tertinggi setakat ini.`
          },
          {
            title: 'Liga Legends Pusingan Akhir Dunia',
            cont: `Sejak 2017, Kejohanan Dunia League of Legends telah diperluaskan. 
            Wild Card Tour telah dibatalkan dan sebaliknya League of Legends World Finals yang diperluas secara langsung memasukkan ciri Wild Card Tour asal.`
          },
          {
            title: 'Liga Pro CrossFire',
            cont: `CrossFire di acara teratas di tanah besar China. D
            ihoskan oleh Tencent Games dan dihoskan oleh Quantum Sports. 
            Sejak kejohanan sulung diadakan pada tahun 2012, CFPL telah disediakan selama 15 musim.`
          },
          {
            title: 'CS:GO Liga Super',
            cont: `CS:GO Liga Super. E
            disi pertama dilancarkan pada Mac 2016. 
            Ia dihoskan oleh Pusat Maklumat Sukan Pentadbiran Am Sukan Republik Rakyat China dan dijalankan oleh Shanghai Huaao E-sports Information Technology Co., Ltd.`
          }
        ]
      },
            // 棋牌api
      chessAndCard: {
        title: 'API Catur',
        homeText: 'Menyediakan pelbagai permainan papan klasik, termasuk Niu Niu, Bunga Emas Goreng, Naga dan Harimau, Run Fast, Penyapu Periuk Api dan permainan lain. Permainan inovatif, memberi perhatian lebih kepada keselamatan dan kebolehpercayaan permainan.',
        rowText1: 'Menyediakan pelbagai permainan papan klasik, termasuk Niu Niu, Bunga Emas Goreng, Naga dan Harimau, Run Fast, Penyapu Periuk Api dan permainan lain. Antara muka permainan kami direka dengan indah, permainan inovatif, dan memberi lebih perhatian kepada keselamatan dan kebolehpercayaan permainan. Tidak kira di mana anda berada, semua yang anda perlukan adalah telefon bimbit atau komputer untuk memasuki lobi permainan kami dengan mudah dan bersaing dengan pemain lain.',
        rowText2: '',
        subhead: 'Sediakan jenis permainan',
        description: '',
        dataList: [
          {
            title: 'Lembu lembu',
            cont: 'Dek lima puluh empat kad bermain digunakan, dengan lima kad diberikan kepada setiap pemain. Pemain perlu membahagikan lima kad di tangannya kepada dua set tiga kad dan dua kad, supaya jumlah mata tiga kad adalah gandaan 10, dan jumlah mata dua kad yang tinggal adalah sebesar mungkin.'
          },
          {
            title: 'Bunga emas goreng',
            cont: 'Permainan ini menggunakan dek lima puluh empat kad bermain (termasuk dua raja), dengan tiga kad diedarkan di tangan setiap pemain. Kad-kad itu, dari terbesar hingga terkecil: harimau, shunjin, bunga emas, lurus, pasangan, dan kad tunggal.'
          },
          {
            title: 'Melawan binatang',
            cont: 'Permainan ini menggunakan 54 kad (tidak termasuk raja), dan permainan ini dimainkan di empat papan pemain Qinglong, Harimau Putih, Suzaku, dan Xuanwu, dan kad ditangani sebagai empat jubin untuk dibandingkan dengan peniaga peniaga.'
          },
          {
            title: 'Sangong',
            cont: 'Setiap pemain diberikan tiga kad di tangan mereka, dan pemenang diputuskan dengan membandingkan saiz kad. Penggunaan kesimpulan dan taktik psikologi juga mencabar.'
          },
          {
            title: 'Jalankan dengan pantas',
            cont: 'Dek lima puluh empat kad bermain digunakan, termasuk raja-raja besar dan kecil. Kad dimainkan oleh satu pemain, dan pemain lain perlu bermain kad secara bergilir-gilir dan mengikut peraturan untuk bermain atau tidak bermain. Ia memerlukan nasib dan strategi tertentu, dan merupakan permainan kad yang kompetitif dan menghiburkan.'
          },
          {
            title: 'Tiga belas perairan',
            cont: 'Dek 54 kad bermain digunakan, termasuk raja-raja besar dan kecil. Terdapat enam jenis kad: harimau, lurus, siram, siram, pasangan, dan kad tinggi.'
          },
          {
            title: 'Lawan tuan tanah',
            cont: 'Dek 54 kad bermain (termasuk dua raja), meninggalkan tiga kad lubang, kad lubang tidak dapat dilihat sehingga tuan tanah ditentukan, dan setelah menunggu tuan tanah ditentukan, kad lubang pergi ke tuan tanah, dan kad lubang ditunjukkan dengan jelas.'
          },
          {
            title: 'Mercedes-Benz BMW',
            cont: 'Permainan ini mempunyai lapan bahagian pertaruhan dengan kemungkinan dari empat jenama kereta, dan anda boleh bertaruh pada mana-mana lapan sektor.'
          },
          {
            title: 'Siri Mahjong',
            cont: 'Terdapat 112 kad secara keseluruhan, dan pada permulaan permainan hanya peniaga mendapat empat belas kad, baki tiga belas kad.'
          },
          {
            title: 'Pai Sembilan',
            cont: 'Pada masa ini, terdapat empat cara untuk bermain "periuk", "kad besar sembilan", "kad kecil sembilan", "hantu dan sembilan raja syurga". Pemain perlu mempunyai strategi dan pertimbangan untuk menang dalam permainan.'
          },
          {
            title: 'Baccarat',
            cont: 'Sebanyak 416 kad dimainkan dengan lapan dek kad, dan peniaga pembukaan ditangani dua kad, dan keputusan untuk membuat kad diputuskan mengikut peraturan kad umum, dan akhir kad selesai dengan menambah semua mata kad untuk mengambil digit tunggal, dan sisi lebih dekat dengan 9 kemenangan.'
          },
          {
            title: 'Minesweeper',
            cont: 'Terdapat empat cara untuk bermain "Zhuang dan masa lapang", "menghantar sampul merah", "merebut sampul merah" dan "membayar guruh", dan permainan minesweeping biasanya mempunyai tahap permainan dan persaingan tertentu.'
          }
        ],
        dataListTwo: [
          {
            title: 'Seratus orang dan tiga duke'
          },
          {
            title: 'Ambil lembu klasik'
          },
          {
            title: 'Rebut lembu idea periuk'
          },
          {
            title: 'Beratus-ratus orang lembu'
          },
          {
            title: 'Daging lembu pip bawah periuk'
          },
          {
            title: 'Lembu jantan tanpa had'
          },
          {
            title: 'Beratus-ratus orang melawan binatang ilahi'
          },
          {
            title: 'Daging lembu klasik di bahagian bawah periuk'
          },
          {
            title: 'Jalankan dengan pantas'
          },
          {
            title: 'Beratus orang harimau naga'
          },
          {
            title: 'Bunga emas goreng'
          },
          {
            title: 'Tiga Gong Gelap Satu'
          },
          {
            title: 'Mercedes-Benz BMW'
          },
          {
            title: 'Ketiga-tiga duke gelap'
          },
          {
            title: 'Tiga gong daripada bunga emas'
          },
          {
            title: 'Baccarat'
          },
          {
            title: 'Mahjong Merah (dua)'
          },
          {
            title: 'Mahjong Merah (4 orang)'
          }
        ]
      },
      // 彩票API
      lottory: {
        title: 'API loteri',
        homeText: 'API termasuk loteri hash, cabutan cepat, loteri luar negara dan loteri frekuensi rendah, yang boleh membantu pengguna mencapai keperluan sewa loteri yang tepat, cekap dan mudah.',
        rowText1: 'API termasuk loteri hash, cabutan cepat, loteri luar negara dan loteri frekuensi rendah, yang boleh membantu pengguna mencapai keperluan sewa loteri yang tepat, cekap dan mudah. API kami menyediakan pelbagai antara muka data untuk menyesuaikan perkhidmatan data mengikut keperluan pengguna. Data API kami komprehensif, tepat dan tepat pada masanya, yang dapat membantu pengguna memahami dinamik pasaran, menganalisis trend pasaran, dan mengoptimumkan perniagaan sewa loteri.',
        rowText2: '',
        dataList: [
          {
            title: 'Warna cincangan',
            content: 'Loteri Hash adalah loteri baru berdasarkan teknologi blockchain. Proses loteri juga dijalankan melalui teknologi blockchain, sistem secara rawak akan memilih blockchain, kerana ketidakmampuan blockchain, permainan loteri ini dapat memastikan keadilan dan keadilan hasil loteri.'
          },
          {
            title: 'Lukis dengan cepat',
            content: 'Ia adalah permainan loteri frekuensi tinggi berdasarkan teknologi digital yang bermain seperti permainan "segera" loteri tradisional. Masa cabutan sangat cepat, dan hasilnya biasanya tersedia dalam masa beberapa minit.'
          },
          {
            title: 'Loteri asing',
            content: 'Ia merujuk kepada permainan loteri yang dimainkan di negara dan wilayah lain selain China. Permainan loteri ini mungkin menawarkan hadiah yang lebih tinggi, lebih banyak cara untuk bermain, dan lebih banyak pilihan daripada loteri tempatan.'
          },
          {
            title: 'Warna frekuensi rendah',
            content: 'Permainan jenis ini biasanya merupakan permainan di mana tiket loteri dalam bentuk nombor atau simbol dibeli, dan apabila cabutan ditarik, nombor atau simbol yang dipilih sepadan dengan keputusan loteri untuk memutuskan sama ada untuk memenangi loteri.'
          }
        ]
      },
      // 老虎机API
      tiger: {
        title: 'API Slot',
        homeText: 'Dengan API kami, anda boleh membuat permainan slot anda sendiri dengan mudah.',
        rowText1: 'API dibangunkan oleh pasukan kami sendiri dan sangat fleksibel dan berskala. Menggunakan API kami, anda boleh membuat permainan slot anda sendiri dengan mudah.',
        dataList: [
          {
            title: 'Slot bertema',
            contentText: 'Berdasarkan pelbagai tema yang berbeza, termasuk mitologi kuno, kerajaan haiwan, dan tempoh sejarah, untuk menamakan beberapa.'
          },
          {
            title: 'Slot berbilang talian',
            contentText: 'Dalam satu permainan, pemain boleh menggunakan berbilang payline untuk meningkatkan peluang mereka untuk menang. Tekan simbol pada mana-mana payline untuk mendapatkan ganjaran.'
          },
          {
            title: 'Mesin slot tradisional',
            contentText: 'Untuk memukul satu set simbol yang sepadan pada gulungan untuk mendapatkan ganjaran. Biasanya terdapat beberapa simbol dan jenis payline yang berbeza yang boleh digunakan dalam permainan.'
          }
        ]
      },
      // 彩票信用网
      credit: {
        title: 'Rangkaian Kredit Loteri',
        homeText: 'Menyokong pelbagai jenis loteri, termasuk loteri hash, jam kelajuan, perlumbaan kelajuan, loteri Mark Six, dll.',
        rowText1: 'Menyokong pelbagai jenis loteri, termasuk loteri hash, jam kelajuan, perlumbaan kelajuan, loteri Mark Six, dll. Jenis loteri yang berbeza menawarkan cara yang berbeza untuk bermain dan bonus, dan pengguna boleh memilih jenis loteri dan kaedah pertaruhan yang berbeza mengikut keutamaan dan strategi pertaruhan mereka.',
        dataList: [
          {
            title: 'Loteri Hong Kong Mark Six',
            contentText: 'Ia adalah satu-satunya loteri undang-undang di Hong Kong. Cabutan telah dilukis oleh Kelab Joki Hong Kong di bawah nama Hong Kong Jockey Club Lotteries Limited. Tarikh cabutan tidak ditetapkan, dan cabutan akan diadakan pada jam 9:30 malam waktu Beijing.',
          },
          {
            title: 'Kapal udara bertuah',
            contentText: 'Lucky Airship dikeluarkan secara eksklusif oleh Suruhanjaya Bersama untuk Kebajikan Valletta Republik Malta. Terdapat 180 cabutan sehari, 1 cabutan setiap 5 minit. Keputusan setiap cabutan akan diumumkan melalui pelbagai saluran bagi memastikan keadilan dan keadilan.',
          },
          {
            title: 'Kanada Columbia',
            contentText: 'Keputusan loteri diperoleh daripada data loteri KENO dari syarikat loteri Kanada BCLC. Masa cabutan adalah berbeza dari masa musim sejuk, dan hadiah dibahagikan setiap 3.5 dan cabutan adalah 395 tempoh sehari.',
          },
          {
            title: 'Slovakia',
            contentText: 'Tiket loteri Cabutan Cepat KENO, yang dikeluarkan oleh Syarikat Loteri Kebangsaan TIPOS, yang diberi kuasa secara eksklusif oleh Kementerian Kewangan Republik Slovakia, digunakan. Loteri akan dikeluarkan pada 10:55 ~ 5:45 pada hari berikutnya waktu Beijing, dan hadiah akan dibahagikan setiap 5.',
          },
          {
            title: 'Perlumbaan kelajuan',
            contentText: 'Untuk memperkenalkan peralatan loteri dari syarikat permainan Eropah, Extreme Racing disemak dan dipantau oleh Syarikat Lesen Permainan Filipina. 7:59~7:57 keesokan harinya waktu Beijing. Setiap 100 saat seri, 864 seri setiap hari.',
          },
          {
            title: 'Warna MD5 Jam',
            contentText: 'Loteri MD5 adalah pengenalan peralatan loteri dari syarikat permainan Eropah, yang diaudit dan dipantau oleh syarikat lesen permainan Filipina. Cabutan dilukis pada 0:00~23:59 waktu Beijing, setiap 120 saat, dan 720 cabutan diadakan setiap hari.',
          },
          {
            title: 'Hasi 28',
            contentText: 'Sumber nombor hadiah adalah transaksi sebenar pada blockchain Tron, mengambil hash blok di mana transaksi itu terletak. Setiap sejarah transaksi boleh ditanya melalui dompet, memastikan bahawa data sumber benar-benar adil, saksama dan tidak boleh diganggu.',
          },
          {
            title: 'Perlumbaan Ethereum',
            contentText: 'Untuk transaksi yang dibuat pada blockchain Ethereum, hash transaksi adalah pengecam 66 aksara yang unik. Ambil hash transaksi yang tidak disahkan dalam masa yang ditetapkan sebagai benih rawak.',
          }
        ]
      },
      // 平安支付
      payment: {
        title: 'Bayaran selamat',
        homeText: 'Alat pembayaran menyokong pembayaran RMB dengan kad bank, menjadikan proses pembayaran anda lebih mudah dan cepat.',
        rowText1: 'Alat pembayaran kami menyokong pembayaran RMB dengan kad bank, menjadikan proses pembayaran anda lebih mudah dan pantas. Sistem pembayaran kami selamat dan terjamin, memastikan pembayaran anda selamat dan dana anda selamat. Pada masa ini, kutipan USDT dan pembayaran RMB disokong. Anda boleh menggunakan Ping An Pay untuk membayar atau mengumpul USDT daripada orang lain, dan anda boleh menukar USDT kepada RMB dengan mudah.',
        dataList: [
          {
            title: 'Keselamatan yang tinggi',
            text: 'Proses pembayaran dan transaksinya dilindungi oleh langkah penyulitan dan keselamatan yang ketat.',
          },
          {
            title: 'Mudah dan pantas',
            text: 'Proses pembayaran adalah mudah dan tidak memerlukan pemindahan bank atau kiriman wang yang rumit.',
          },
          {
            title: 'Jaminan pulangan wang',
            text: 'Sekiranya liabiliti kami membawa kepada pembekuan kehakiman dua hala, kami berjanji untuk membayar sepenuhnya!',
          },
          {
            title: 'Kos rendah',
            text: 'Lebih rendah daripada kadar pertukaran pasaran, kadar pertukaran harga belian Eropah dan Itali, 1.5% bagi pihak pembayaran.',
          }
        ]
      },
      // 人才招聘
      recruitment: {
        title: 'Sumber Manusia (HRBP)',
        homeText: 'Kami komited untuk mewujudkan budaya korporat yang dinamik, terbuka dan pelbagai, menggalakkan pembangunan diri pekerja dan menyediakan pekerja dengan ruang pembangunan kerjaya yang luas.'
      }
    },
        // 新增人才招聘
        recruitmentLang: {
          title: 'Pemerolehan bakat',
          rowText1: `Di Huachuang, kami komited untuk mewujudkan budaya korporat yang dinamik, terbuka dan pelbagai, menggalakkan pembangunan diri pekerja dan menyediakan pekerja dengan ruang pembangunan kerjaya yang luas. Sebagai Rakan Perniagaan HR, anda akan membina hubungan yang baik dengan semua peringkat pengurusan, pekerja dan unit perniagaan, menggalakkan pelaksanaan strategi syarikat, dan terus berusaha untuk situasi menang-menang untuk pekerja dan perusahaan. Selepas jangka panjang dan membentuk pasukan, kapasiti pengambilan pasukan melebihi 30+ orang sebulan, yang boleh membawa bantuan yang tidak dapat diukur dalam bekalan kakitangan.`,
          dataList: [
            {
              title: 'Pengurusan sumber manusia yang lebih baik',
              content: 'Memastikan organisasi mematuhi amalan terbaik dalam sumber manusia dan membantu dalam pembangunan dan pelaksanaan dasar dan prosedur.'
            },
            {
              title: 'Kerjasama strategik',
              content: 'Pengurus bekerjasama rapat untuk memastikan strategi HR sejajar dengan strategi organisasi dan menyokong pencapaian objektif perniagaan.'
            },
            {
              title: 'Meningkatkan pengalaman pekerja',
              content: 'Pekerja mendapat kepuasan dan kebahagiaan di tempat kerja dan menyediakan peluang sokongan dan pembangunan untuk mengukuhkan kesetiaan pekerja kepada organisasi.'
            },
            {
              title: 'Meningkatkan prestasi organisasi',
              content: 'Boleh membantu organisasi mencapai matlamat perniagaan dengan meningkatkan prestasi pekerja dan program pembangunan, dan mengenal pasti strategi untuk berjaya dengan memantau petunjuk prestasi utama.'
            },
            {
              title: 'Tindak balas yang lebih pantas',
              content: 'Isu pekerja dan pengurusan boleh diselesaikan dengan lebih cepat, membantu organisasi mengelakkan isu dan risiko HR yang berpotensi'
            }
          ]
        }
  }