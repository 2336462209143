// 越南文语言包
export default {
  testData: 'Kiểm tra',
  language: 'Tiếng Việt',
  route: {
    home: 'Trang đầu',
    temp: 'Mẫu đó',
    serve: 'Lợi thế dịch vụ',
    ourselves: 'Về chúng ta',
    recruitment: 'Tuyển dụng nhân lực'
    },
  mailbox: 'Hộp thư',
  price:'Giá cả',
  flow: 'Dòng chảy',
  quantity: 'Số lượng',
  applyNow: 'Áp dụng ngay lập tức',
  yaer: 'năm',
  month: 'tháng',
  day: 'Vào ngày',
  a: 'Một người',
  customCharge:'Chi phí khách hàng',
  customization: 'Tùy chỉnh',
  languageSetting: 'Thiết lập ngôn ngữ',
  viewDetails: 'Xem chi tiết',
  viewTemp: 'Xem mẫu',
  knowDetails: 'Tìm hiểu chi tiết',
  GoToTrialPlay: 'Để thử nghiệm',
  businessRelation: 'Liên hệ kinh doanh',
  GoSports: 'Đi đến GO sports',
  contactUs: 'Liên lạc với chúng tôi',
  pleaseLookForwardTo: 'Tính năng thử nghiệm xin vui lòng mong đợi',
  theme: 'Chủ đề',
  howToCharge: 'Vui lòng nhập câu hỏi của bạn',
  contactEmail: 'Email liên lạc',
  yourContactEmail: 'Hãy nhập email của bạn',
  problemDescription: 'Mô tả vấn đề',
  yourQuestion: 'Hãy miêu tả vấn đề của bạn',
  definiteSendOut: 'Xác định gửi',
  homePageLang: {
    productTitle: 'Sản phẩm sinh thái tích hợp',
    productIntroduce: 'GO net trong nhiều năm qua công nghệ, nhiều sản phẩm tổng hợp ma trận sản phẩm',
    firmTitle: 'Nhà sản xuất hợp tác',
    firmIntroduce: 'GO net trong nhiều năm qua công nghệ, nhiều sản phẩm tổng hợp ma trận sản phẩm',
    clientTitle: 'Dịch vụ khách hàng',
    clientIntroduce: 'Với "khách hàng thành công" là trung tâm, thực sự tạo ra giá trị cho khách hàng'
  },
  servePageLang: {
    funTitle: 'Dịch vụ',
    advantageTitle:'Lợi thế',
    funDataList: [
      {
        title: 'Trò chơi ba chiều giàu có nhất'
      },
      {
        title: 'Hỗ trợ đa ngôn ngữ'
      },
      {
        title: 'Hệ thống nền tảng cuối đầy đủ'
      },
      {
        title: 'Hệ thống ủy nhiệm hoàn toàn linh hoạt'
      },
      {
        title: 'Phân tích al dữ liệu lớn'
      },
      {
        title: 'Thiết kế tùy chỉnh'
      }
    ],
    advantageDataList: [
      {
        title: 'Theo dõi 24/7'
      },
      {
        title: 'Dữ liệu đám mây nhiều điểm'
      },
      {
        title: 'Cảnh báo nguy cơ khả nghi'
      },
      {
        title: 'Tường lửa an ninh quốc gia'
      },
      {
        title: 'Chứng từ mật mã SSL'
      },
      {
        title: 'Dữ liệu dự phòng được mã hóa'
      },
      {
        title: 'Chống tấn công DDoS'
      },
      {
        title: 'Quản lý báo cáo toàn diện nền tảng'
      }
    ]
  },
  tempPageLang: {
    AG: 'AG',
    Macau: 'Ma Cao',
    physicalEducation: 'Thể thao',
    chessAndCards: 'Lĩnh vực',
    lottery: 'Xổ số',
    esports: 'gaming',
    catchfish: 'Đánh bắt cá',
    realPerson: 'Người thật',
    electron: 'Điện tử',
    //  子内容
    Kaifa: 'KaiFa',
    J9: 'J9',
    Hutchison: 'Và còn',
    newLisboa: 'Grand lisboa',
    GalaxyMacau: 'Dải ngân hà ma cao',
    SunCity: 'Thành phố mặt trời',
    MGMMirage: 'MGM',
    Venetian: 'Người Venice',
    GoldenSands: 'kinshasa',
    NewCentury: 'KaiYuan',
    Yunyu: 'Đi vòng',
    TangDynasty: 'Đại đường',
    TianchengLottery: 'Ngày thắng xổ số',
    PhoenixLottery: 'Phoenix xổ số',
    apricot: 'Hạnh nhân play-doh',
    panAsia: 'FanYa',
    LightningFire: 'Ray lửa',
    OBfishing: 'OB câu cá',
    BGfishing: 'BG câu cá',
    skyhunter: 'skychaser',
    FishingKing3D: 'Vua câu cá 3D',
    FishingKing: 'Vua câu cá',
    FishingPark: 'Công viên câu cá',
    OBRealPerson: 'OB thật.',
    AGRealPerson: 'AG real.',
    BGRealPerson: 'BG người thật',
    OBelectron: 'OB electronics',
    AGelectron: 'PG electron',
    LGDelectron: 'LGD electron'
  },
  ourselvesPageLang: {
    title: 'GO group',
    contentText1: 'GO Group gọi là Great Online Group, với mục đích xây dựng một đội bóng bao bì hàng đầu của sòng bạc.',
    contentText2: `Mười năm kinh nghiệm, giải pháp dự án hàng đầu ở châu á, tích cực tìm kiếm các công nghệ và ý tưởng sáng tạo.
    Có một đội ngũ sản phẩm chuyên nghiệp lên kế hoạch nhu cầu, liên tục khám phá thị trường 24/7 phát triển xung quanh năm, cung cấp gần 10.000 trò chơi giải trí, máy đánh bạc, thể thao thực tế và nền tảng khác, và cung cấp tiền gửi và rút tiền trực tuyến.
    Một phím, cung cấp dữ liệu hoạt động cốt lõi, phân tích đa chiều dữ liệu của người chơi, dữ liệu kênh, dữ liệu chi phí và lợi nhuận dữ liệu, để giúp các nhà tiếp thị đưa ra các quyết định đúng.`,
    contentText3: `Công nghệ chống xâm nhập sở hữu độc lập (đã đăng ký bằng sáng chế), một phím kiểm toán khối lượng, tự động giải quyết, hoạt động phát hành đơn giản và hiệu quả.
    Kết hợp các tài khoản đồng bộ và truyền tải thông tin để đạt được khái niệm thưởng thức giải trí bất cứ lúc nào, bất cứ nơi nào.
    Tất cả các tài nguyên phần mềm và phần cứng của trang web độc quyền, và cấu hình nhiều bản SAO, chuyển đổi thông minh, cung cấp cho bạn một môi trường vững chắc và ổn định.
    Những hệ thống hàng đầu, khác biệt bởi sự khác biệt.
    Mong muốn của bạn để đặt câu hỏi!`,
    title2: 'Cột mốc'
  },
  sonArticleContent: {
      goBao: {
        title: 'GO net',
        homeText: 'Chúng tôi là công nghệ tiên phong, tiếp tục phát triển ứng dụng công nghệ tiên tiến, thúc đẩy sự phát triển kinh doanh của khách hàng, đạt được sự sáng tạo, hoạt động chung, win-win',
        rowText1: `GO blog mạng dự án vào năm 2012, đã phát triển hơn 10 năm, tích lũy hàng trăm khách hàng +, đội ngũ kỹ thuật hiện nay có 100 +, 24 giờ để đảm bảo sự ổn định của dự án.
        Phát triển 60+ các mẫu khác nhau, đáp ứng 90% nhu cầu của thị trường.
        Nếu bạn nghĩ rằng, mẫu thị trường không thể đáp ứng, chúng tôi sẽ thiết kế tương tác, thiết kế giao diện để tùy biến chức năng, chúng tôi cung cấp một loạt các giải pháp, bao gồm cả PC, H5, kiểm tra IOS, máy chủ, tên miền và các dịch vụ một con rồng.`,
        rowText2: `Cung cấp các phiên bản đa ngôn ngữ như tiếng trung quốc, tiếng anh, việt nam và các dịch vụ tùy chỉnh bất kỳ ngôn ngữ;
        Bằng cách sử dụng các phát triển công nghệ nguyên bản, hoàn toàn giải quyết các vấn đề như mất tiền, cướp giao thông, bảo vệ an ninh trang web;
        Hơn 200 lựa chọn trò chơi, không có giám sát an ninh chết, tất cả các hộ tống!`,
        netPrice: {
          title: 'Giá lưới',
          openingCharge: {
            title: 'Lệ phí mở',
            dataList: [
              {
                content: 'Cấu hình kỹ thuật của đội ngũ chuyên nghiệp hướng dẫn việc khởi hành'
              },
              {
                content: 'Các nhà thiết kế UI cao cấp giúp xử lý hiệu ứng trưng bày trang'
              },
              {
                content: 'Đội kỹ thuật chuyên nghiệp triển khai nhanh và hiệu quả'
              },
              {
                content: 'Miễn phí với các khoản thanh toán ba bên, nền tảng tin nhắn văn bản, vv'
              },
              {
                content: 'Đội điều hành triển khai dự án, phân tích tên miền, vân vân'
              }
            ]
          },
          BasicCharge: {
            title: 'Lệ phí cơ bản',
            content1: 'Giao hàng đúng thời hạn sau cuộc đàm phán cuối cùng',
            content2: 'Ứng dụng gốc, PC, H5',
            content3: '(tùy chỉnh nền tảng hoặc nhu cầu liên hệ kinh doanh)',
          }
        },
      productAdvantage: {
        title: 'Lợi thế sản phẩm',
        dataList: [
          {
            title: 'Tùy biến sản phẩm tốc độ cao',
            content: `Thiết kế cao cấp thương hiệu dịch vụ, kỹ thuật tốt, là một tân binh trong ngành công nghiệp.
            Miễn là bạn có lưu lượng truy cập, chúng tôi có thể đưa bạn tiền mặt!`
          },
          {
            title: 'Kinh nghiệm sản phẩm chất lượng cao',
            content: `Điện thoại di động tối đa trong ngành công nghiệp ba hoàn thành, mã hóa nhiều điểm tạm biệt vụ cướp, tất cả mọi người để quảng bá một mối lo lắng.`
          },
          {
            title: 'Dịch vụ hoạt động 7*24 giờ',
            content: `7*24 giờ dịch vụ khách hàng chuyên nghiệp, kiểm soát rủi ro nhóm tài chính đội hỗ trợ ACB toàn bộ kết nối, tất cả thời gian.`
          }
        ]
      }
    },
    integrated: {
      title: 'Tổng hợp màu station',
      homeText: 'Phim phúc lợi xã hội trung quốc, nhật bản và hàn quốc av hd, phim trung quốc màu sắc nhật bản và hàn quốc, mỹ và mỹ sản xuất miễn phí cao trực tuyến av phim video'
    },
    video: {
      title: 'Video tổng hợp',
      homeText: 'Cung cấp video đẹp nhất trong ngành công nghiệp',
      rowText1: `Video tổng hợp mang lại cho người chơi một trải nghiệm giải trí chưa từng có, là sản phẩm cần thiết để cải thiện doanh nghiệp của bạn.
      Để làm điều này, GO group hợp tác với các nhà cung cấp phần mềm game online hàng đầu thế giới để các nhà điều hành giải trí có thể thêm nội dung mới nhất vào trang trắng của họ.
      Cùng lúc đó, chúng tôi cung cấp cho người chơi những trải nghiệm trò chơi khác nhau, những đồ họa vô song và những công nghệ tiên tiến để tạo ra một trải nghiệm sống và tương tác để thu hút sự chú ý của người chơi.
      Phòng hội trường quốc tế với kích thước cao được trang bị với thiết bị tối tân nhất và trang trí trang trí tuyệt vời để tạo cho người chơi một trải nghiệm video thực tế tuyệt vời.
      Sự đa dạng của các lựa chọn trò chơi, bao gồm baccarat, sibo, bánh xe, và quản lý giám sát tất cả các ngày, cung cấp cho người chơi một dịch vụ trò chơi ổn định và linh hoạt.`,
      rowText2: '',
      dataList: [
        {
          pictitle: 'Baccarat cổ điển',
          title: 'Baccarat cổ điển',
          cont: `Baccarat là một trong những trò chơi được yêu thích nhất trên thế giới.
          Người chơi đầu tiên chơi baccarat với sáu lá bài để đảm bảo rằng trò chơi là công bằng, làm tăng niềm tin của người chơi trong nền tảng.
          Ngoài ra, chúng tôi cũng độc quyền phát triển các đặc điểm LED bàn đua miccarat, tốc độ baccarat 25 giây, cung cấp cho người chơi một niềm vui thực sự để ở trong một sòng bạc.`
        },
        {
          pictitle: 'Đua mbaccarat',
          title: 'Đua mbaccarat',
          cont: `Baccarat mega mạnh mẽ giới thiệu chức năng cắt thẻ thủ công, giới hạn thời gian 20 giây cho những người chơi lớn nhất.
          Thông qua một khuếch đại cục bộ của video và thẻ hiển thị đường gạch gạch để trợ giúp cắt thẻ, đảm bảo hoàn toàn công bằng và công bằng, trong khi cho phép người chơi tự động tăng lên, thực tế hơn so với các casino.`
        },
        {
          pictitle: 'Bảo hiểm baccarat',
          title: 'Bảo hiểm baccarat',
          cont: 'Bảo hiểm baccarat là một tính năng mới phổ biến nhất của người chơi trong những năm gần đây.'
        },
        {
          pictitle: 'Long bảo baccarat',
          title: 'Long bảo baccarat',
          cont: `Long bảo sẽ tùy thuộc vào các cá cược một bên thắng điểm, tỷ lệ sẽ khác nhau, tối đa là 30 lần tiền thưởng, và giành chiến thắng tiền thưởng không bơm nước.
          Nếu một trong hai người này thắng, bạn sẽ nhận được giải thưởng.`
        },
        {
          pictitle: 'Vips baccarat',
          title: 'Vips baccarat',
          cont: 'Sau khi bàn VIP có thể kiểm soát nhịp điệu của trò chơi, thưởng thức các chức năng ưu việt như thay thế, bay thẻ và thay đổi giày dép, thực hiện một giải trí cao cấp và tự động thưởng thức.'
        },
        {
          pictitle: 'Long Hu',
          title: 'Long Hu',
          cont: `Sau khi tham gia vào nền tảng trò chơi thực tế, cho phép nhiều người chơi trên máy tính và bất kỳ thiết bị di động nào liên kết với nền tảng giải trí của chúng tôi, cảm thấy như là một trải nghiệm vui chơi mới, thử vận may của bạn!`
        },
        {
          pictitle: 'NiuNiu',
          title: 'NiuNiu',
          cont: `Niu niu cũng được gọi là bullfighting, là một phổ biến trong quảng đông, quảng tây, hunan và giang trạch dân và zhe truyền thống của trò chơi cờ.
          Một trong số đó là người chia bài, còn lại là người nhàn rỗi, sau khi chia bài ngay lập tức mở bài, tất cả nhàn rỗi và người chia bài so sánh xác định thắng hay thua.
          Trò chơi với tốc độ cực kỳ thú vị và luật lệ đơn giản.`
        },
        {
          pictitle: '21 điểm',
          title: '21 điểm',
          cont: `Xì dách bắt nguồn từ pháp, chỉ cần một người chơi poker có số 21 điểm cao nhất và nhiều hơn điểm trung bình sẽ thắng.
          Mục đích cuối cùng của trò chơi, là để người chơi có tổng số điểm của bài 21 điểm (hoặc gần nhất là 21 điểm), và sau đó so sánh với kích thước của Dutch.`
        },
        {
          pictitle: 'Chiên vàng',
          title: 'Chiên vàng',
          cont: `Fried golden flower (gian lận golden flower), cũng được gọi là chiến thắng ba, là một trò chơi bài dân sự được phát hành rộng rãi trên toàn quốc.
          Người chơi có kích thước bằng ba lá bài trong tay, có một luật chơi độc đáo.`
        },
        {
          pictitle: 'LunPan',
          title: 'LunPan',
          cont: `Trò chơi roulette thực sự.
          Thông qua những hình ảnh trực tiếp được truyền đi, cùng với những kỹ năng cao và chuyên nghiệp của roulette, để tạo ra một trò chơi cá cược công bằng và công bằng cho người chơi.
          Trò chơi này bằng cách sử dụng một bánh xe số không đơn tiêu chuẩn quốc tế.
          Người chơi có thể mua một số hoặc một tổ hợp số ở bàn.`
        },
        {
          pictitle: 'Tou có bollywood.',
          title: 'Tou có bollywood.',
          cont: `Dice bo trò chơi mở ra một lĩnh vực mới, để cung cấp cho người chơi một cảm giác mới chưa từng có, vui vẻ trở lại.
          Cũng được gọi là đánh cược kích thước, bằng cách đoán các số điểm trên xúc xắc hoặc tổng số điểm cá cược.
          Các loại cá cược đa dạng và tỉ lệ thu hút rất phổ biến.`
        },
        {
          pictitle: 'Nhiều trò chơi',
          title: 'Nhiều trò chơi',
          cont: `Ở đây, bạn có thể chọn các loại trò chơi tùy thích và đặt cược cùng lúc.
          Bạn chỉ cần bấm nút 'chọn bàn' để sắp xếp lại vị trí của bàn.`
        }
      ]
    },
    CDN: {
      title: 'CDN',
      homeText: `Gửi hình ảnh, video, ứng dụng và các tập tin khác cho người dùng nhanh hơn và đáng tin cậy hơn bằng hệ thống máy tính kết nối với nhau qua Internet, sử dụng máy chủ gần gũi nhất với mỗi người dùng`,
      rowText1: `Là một nhà cung cấp công nghệ CDN tiên phong, GO package net đã xây dựng kiến trúc giao tiếp nội dung thế hệ tiếp theo vào năm 2012.
      Nhiệm vụ của chúng tôi là phát triển và thiết kế các giải pháp phân phối nội dung cho càng nhiều người càng tốt.
      Không chỉ hiệu quả, an ninh của các dịch vụ cơ bản CDN, đơn giản và dễ sử dụng và hiệu quả cao cho người dùng CDN là rất quan trọng.
      CDN của chúng tôi chỉ cần nhấp chuột vài lần có thể cho phép bạn nhập cuộc, cũng cung cấp một loạt các tùy chọn cấu hình, đơn giản và dễ sử dụng có thể cho phép bạn tập trung nhiều hơn vào các doanh nghiệp cốt lõi, đó là đơn giản và dễ sử dụng làm cho sản phẩm của chúng tôi có hiệu quả rất cao, nổi bật trong tay cạnh tranh.`,
      rowText2: '',
      dataList: [
        {
          title: 'Phân phối nội dung CDN',
          contentText: `CDN có hơn 30 trung tâm dữ liệu trên 5 châu lục, trong đó chúng tôi có phòng máy chủ bảo vệ cao ba tuyến trong nước, bạn có thể cache nội dung tĩnh trong trang web của bạn, có thể tự động chỉ người dùng đến nút nhanh nhất.`,
        },
        {
          title: 'Bảo vệ trang web',
          contentText: `Bảo vệ CDN bảo vệ cao có thể làm cho chất lượng truy cập trang web của bạn được bảo vệ, khả năng sử dụng được cải thiện đáng kể, khi đối mặt với DDoS, CC, hack và các cuộc tấn công thông thường khác, không cần phải lo lắng.`,
        },
        {
          title: 'Tường lửa ứng dụng Web',
          contentText: `Cung cấp một công nghệ xác định dấu vân tay chính xác và một động cơ xử lý hiệu quả, kết hợp với chiến lược phòng thủ cao cập nhật thời gian thực, bao gồm các loại tấn công chính thống của OWASP và bảo vệ trang web của bạn khỏi các hacker.`,
        },
        {
          title: 'Truy cập tối ưu hóa',
          contentText: `CDN bao gồm một tập hợp các chức năng tối ưu hóa Web, bạn có thể nén nhiều hơn 50% của trang Web của bạn, tối ưu hóa bao gồm các tiêu chuẩn Web mới nhất, kết hợp với CDN bảo vệ cao của chúng tôi, có thể tối ưu hóa đáng kể tốc độ truy cập của người dùng.`,
        },
        {
          title: 'Phòng thủ DDos không có giới hạn',
          contentText: 'Bất kể kích cỡ, loại hay thời gian của cuộc tấn công, nó sẽ giúp bạn chống lại bất kỳ cuộc tấn công DDOS nào ở rìa nút mà không có giới hạn.',
        },
        {
          title: 'Tương tác giữa con người và con người',
          contentText: `Có khả năng phòng thủ kinh doanh thông minh dữ liệu lớn, hỗ trợ đa chiều tùy chỉnh chính xác điều khiển truy cập, phối hợp với máy nhận dạng và điều khiển tần số và các phương tiện đối đầu, thông minh và hiệu quả lọc rác truy cập, phòng thủ chống tấn công CC.`,
        }
      ],
      serviceItem: 'Dự án dịch vụ',
      chargeDetails: 'Chi phí chi tiết',
      setMealTitle: 'Gói và giá cả',
      setMealText1: 'Tất cả các phiên bản có thể cung cấp truy cập không giới hạn vùng miền ba, không tính phí! Các nút trên khắp các nước đông nam á không có tên miền có thể nhập vào trung quốc từ hồng kông, hàn quốc, nhật bản',
      setMealText2: 'Lưu lượng truy cập phí: 1, 12 USD cho mỗi 1GB USDT phải trả phí 1%',
      dataTypeList: [
        {
          title: 'Kinh doanh phiên bản',
          titleExplain: 'Cho các nhóm dưới 5 người, thưởng thức 100GB/ tháng',
          scopeText1: 'Cho những nhóm nhỏ hơn',
          scopeText2: 'Tên miền thứ hai cho 10',
          scopeText3: 'Giá cả được áp dụng'
        },
        {
          title: 'Phiên bản chuyên nghiệp',
          titleExplain: 'Áp dụng cho các nhóm nhỏ và nhỏ, đáp ứng nhiều người hợp tác để tận hưởng 100GB/ tháng',
          scopeText1: 'Cho các nhóm nhỏ và nhỏ',
          scopeText2: 'Tên miền thứ hai cho 100 ứng dụng',
          scopeText3: 'Giá cả được áp dụng'
        },
        {
          title: 'Phiên bản thương mại',
          titleExplain: 'Cho các công ty trung bình, đáp ứng nhu cầu cá nhân hóa.',
          scopeText1: 'Cho các công ty cấp cao trung bình',
          scopeText2: 'Tên miền thứ hai cho 300 ứng dụng',
          scopeText3: 'Hỗ trợ chuyên nghiệp'
        },
        {
          title: 'Phiên bản enterprise',
          titleExplain: 'Tùy biến cá nhân, cá nhân, cá nhân, cá nhân.',
          scopeText1: 'Cho việc tùy biến cá nhân',
          scopeText2: 'Khu vực thứ hai liên hệ với tư vấn khách hàng',
          scopeText3: 'Tùy biến cá nhân'
        }
      ]
    },
    etp: {
      title: 'Sản phẩm nở',
      homeText: 'Một sản phẩm bắt đầu và thực hiện từ 0 đến 1',
      rowText1: `Nhóm doanh nghiệp trung tâm thành lập các dịch vụ chuyên nghiệp cho doanh nghiệp nhỏ và nhỏ, chúng tôi cung cấp miễn phí hướng dẫn công nghệ kinh doanh, tư vấn quản lý và các chương trình khác để giúp đỡ.`,
      rowText2: '',
      dataList: [
        {
        title: 'Phân tích sản phẩm',
        contentText: `Bao gồm phân tích nhu cầu, phân tích thị trường, phân tích đối thủ cạnh tranh, phân tích quy định và chính sách.
        Lập kế hoạch thiết kế sơ bộ và lập kế hoạch chu kỳ phát triển sơ bộ.`,
        },
        {
        title: 'Đánh giá sản phẩm',
        contentText: `Giám đốc công nghệ trong ngành công nghiệp để kiểm tra và đánh giá sản phẩm, dự án đầu tư sơ bộ phân tích.`,
        },
        {
        title: 'Quản lý dự án',
        contentText: 'Theo kinh nghiệm của người quản lý dự án trong ngành công nghiệp, quản lý chuyên nghiệp của quá trình ấp trứng sản phẩm, tối đa hóa giá trị sản phẩm.',
        },
        {
        title: 'Đội ngũ phát triển chuyên nghiệp',
        contentText: 'Có một đội ngũ kinh nghiệm phát triển trong ngành công nghiệp, trong thời gian ngắn nhất để phát triển, đạt được chất lượng cao nhất của sản phẩm.',
        },
      ]
    },
    software: {
      title: 'Phần mềm giao tiếp',
      homeText: 'Phát triển phần mềm giao tiếp gốc để cung cấp cho người dùng trải nghiệm trò chuyện tốt hơn',
      rowText1: `Bên khách là phần mềm mã nguồn mở và tự do, nhưng bên phục vụ là phần mềm độc quyền.
      Người dùng có thể trao đổi tin nhắn mã hóa và tự hủy với nhau, gửi ảnh, video và tất cả các loại tập tin khác.
      Phiên bản cung cấp phiên bản điện thoại, phiên bản màn hình và trang web chính thức đợi khách hàng nhiều nền tảng khác nhau`,
      rowText2: '',
      dataList: [
        {
            title: 'Đeo thẻ',
            text: 'Ghi nhớ nhanh chóng, hỗ trợ nhiều tập tin, hình ảnh hoặc văn bản!',
        },
        {
            title: 'Sau khi đọc nó sẽ bị cháy',
            text: 'Sắp xếp thời gian và nội dung gửi trước, thời gian tự động gửi',
        },
        {
            title: 'Chuyển phòng chat nhanh lên',
            text: 'Chuyển phòng chat nhanh lên',
        },
        {
            title: 'Đọc thông tin',
            text: 'Xem trạng thái đọc thông tin',
        },
        {
            title: 'đề',
            text: 'Nhóm tóc, cá nhân trò chuyện phong bì màu đỏ gửi',
        },
        {
            title: 'Phát âm tự động',
            text: 'Khi điện thoại di động đến gần, bạn có thể nghe được tin nhắn',
        },
      ]
    },
    waiter: {
      title: 'Đi dịch vụ khách hàng',
      homeText: 'An ninh mạng là những biện pháp chống lại việc trộm cắp thông tin và cạnh tranh thương mại',
      rowText1: `GO dịch vụ khách hàng là một phần mềm, quảng cáo, tiếp thị, quản lý như một hệ thống khách hàng hiệu quả.
      Truy cập chat nhanh chóng, khách hàng chi tiết, dịch vụ robot thông minh, nhiều dịch vụ khách hàng và nhiều nền tảng hội thoại phân tích lưu trữ lịch sử hội thoại và các chức năng ổn định toàn diện.
      Hệ thống được thiết kế để tối ưu hóa cho khách hàng ở nước ngoài, Tpds lớp bảo vệ, AI thông minh phòng thủ tấn công.`,
      rowText2: '',
      dataList: [
        {
            title: 'Triển khai nhanh và đơn giản',
            text: 'Bạn chỉ cần vài bước đơn giản để trang web của bạn có chức năng dịch vụ khách hàng, cung cấp dịch vụ tối ưu cho khách hàng của bạn.'
        },
        {
            title: 'Giao diện đơn giản và thuận tiện',
            text: 'GO dịch vụ khách hàng hệ thống, giao diện đơn giản, dịch vụ khách hàng và quản lý có thể nhanh chóng học tập và sử dụng, hệ thống hiệu quả, nhanh chóng và ổn định.',
        },
        {
            title: 'Triển khai an toàn và hiệu quả',
            text: 'Hệ thống đi, hỗ trợ tư nhân hóa, giúp các doanh nghiệp của bạn có thể làm việc khách hàng an toàn và hiệu quả hơn.',
        },
        {
            title: 'Một nền tảng vững chắc và quản lý hiệu quả',
            text: 'Đi hệ thống tích hợp quản lý thông tin và quản lý khách hàng, cải thiện hiệu quả của đội, chất lượng công việc, cùng với kinh nghiệm khách hàng để hiểu khách hàng của bạn.',
        },
        {
            title: 'Dịch vụ bảo vệ được hỗ trợ mạnh mẽ',
            text: 'Dịch vụ khách hàng hệ thống cung cấp dịch vụ hậu mãi chất lượng cho bạn, đội ngũ ưu tú giải quyết tất cả các khó khăn trong việc sử dụng, bảo đảm an toàn tiện nghi và ổn định sử dụng của bạn.',
        },
        {
            title: 'Đa tên miền đa nền tảng',
            text: 'Hỗ trợ đa domain đa nền tảng đơn giản triển khai, cho phép bạn nắm bắt hiệu quả tổng thể, dễ dàng quản lý nhiều dịch vụ khách hàng.',
        },
    ]
    },
    //新增修改内容
    sport: {
      title: 'Thể thao API',
      homeText: 'Chúng tôi chủ yếu cung cấp dữ liệu về bóng đá, bóng rổ và một số môn thể thao phổ biến khác. Ngoài bóng đá và bóng rổ, chúng tôi cũng ủng hộ nhiều môn thể thao như bóng chày, bóng chuyền và cầu lông.',
      rowText1: 'Chủ yếu là cung cấp dữ liệu về bóng đá, bóng rổ và một số môn thể thao phổ biến khác. Ngoài bóng đá và bóng rổ, chúng tôi cũng ủng hộ nhiều môn thể thao như bóng chày, bóng chuyền và cầu lông. API của chúng tôi có thể cung cấp cho bạn một loạt các thông tin bao gồm các điểm, dữ liệu của các cuộc thi, và nhiều hơn nữa. Sử dụng API của chúng tôi, bạn có thể dễ dàng truy cập vào dữ liệu mới nhất của các cuộc thi, cũng như dữ liệu lịch sử của các cuộc thi và tất cả các loại thông tin thống kê hữu ích. Dù bạn là một trang web thể thao, một ứng dụng thể thao, hay một doanh nghiệp thể thao nào khác, chúng tôi có thể cung cấp cho bạn một dịch vụ đầy đủ và chính xác nhất về dữ liệu thể thao để giúp bạn cải thiện kinh nghiệm người dùng và giá trị kinh doanh.',
      rowText2: '',
      subhead: 'Cung cấp các loại trò chơi',
      description: '',
      dataList: [
        {
          title: 'English premier league',
          cont: 'Thường được gọi là premier league, một môn thể thao cao cấp nhất của bóng đá anh quốc và là một trong những giải đấu bóng đá hàng đầu thế giới, được thành lập vào ngày 20-2-1992 bởi hiệp hội bóng đá anh quốc, mùa bóng 1992-93 ra mắt'
        },
        {
          title: 'Đội bóng đá ý',
          cont: `Cấp 1 của hệ thống giải đấu bóng đá ý, cũng là cấp cao nhất của các giải đấu chuyên nghiệp, cấp cao nhất của hệ thống giải đấu và giải đấu bóng đá đầu tiên của ý, được quản lý bởi hiệp hội bóng đá ý, hoạt động của các giải đấu chuyên nghiệp ý.`
        },
        {
          title: 'Giải hạng nhất của bóng đá pháp',
          cont: 'Cấp 1 của hệ thống liên minh pháp bóng đá, cũng là cấp cao nhất của các liên minh chuyên nghiệp, cấp cao nhất của hệ thống liên minh và các giải pháp hàng đầu của liên minh pháp bóng đá, giám sát, tổ chức và quản lý bởi hiệp hội pháp bóng đá giải đấu chuyên nghiệp pháp dưới sự giám sát của hiệp hội pháp bóng đá.'
        },
        {
          title: 'Giải hạng nhất của bóng đá đức',
          cont: 'Hạng mục cao nhất của bóng đá đức được hiệp hội bóng đá đức xác định ở Dortmund ngày 28 tháng 7 năm 1962, xuất hiện từ mùa giải 1963-64.'
        },
        {
          title: 'Bóng đá tây ban nha hạng a',
          cont: `Cấp 1 của hệ thống liên minh bóng đá tây ban nha cũng là cấp cao nhất của các liên minh chuyên nghiệp, cấp cao nhất của hệ thống liên minh và các liên minh bóng đá tây ban nha.
          Hiện nay có 20 đội ở la liga và ba đội cuối cùng sẽ bị hạ xuống giải hạng hai của bóng đá tây ban nha.`
        },
        {
          title: 'FIFA World Cup',
          cont: `Được thành lập vào năm 1904, giám sát các cuộc thi quốc tế giữa hội đồng quốc gia bỉ, đan mạch, đức, pháp, hà LAN, tây ban nha, thụy điển và thụy sĩ.
          Trụ sở ở Zurich, thụy sĩ, hiện nay gồm 211 hiệp hội quốc gia.`
        },
        {
          title: 'Liên minh vô địch châu âu',
          cont: `Giải đấu bóng đá của uefa được tổ chức hàng năm, đại diện cho danh dự cao nhất của các câu lạc bộ bóng đá châu âu, được tổ chức tốt và hiệu quả, được biết đến như là một trong những giải thưởng cao nhất thế giới của bóng đá và các sự kiện thể thao.`
        },
        {
          title: 'Hiệp hội bóng đá trung quốc super league',
          cont: `Liên minh bóng đá chuyên nghiệp cấp cao nhất được tổ chức bởi hiệp hội bóng đá trung quốc.
          Giải đấu này bắt đầu vào năm 2004, xuất phát từ giải bóng đá trung quốc hạng A.`
        },
        {
          title: 'Giải quốc tế DOTA2',
          cont: `Cuộc thi đấu điện tử được tổ chức bởi các nhà phát triển Dota 2, Weller, được tổ chức bởi 16 đội được mời.
          Sự kiện này được tổ chức lần đầu tiên vào ngày 25 tháng 5 năm 2011 tại triển lãm trò chơi quốc tế ở cologne, và cũng là giải thưởng cao nhất cho đến nay.`
        },
        {
          title: 'Vòng chung kết của league of legends',
          cont: `Bắt đầu từ năm 2017, vòng chung kết toàn cầu của league of legends sẽ được mở rộng.
          Tour du lịch thẻ ngoài đã bị hủy bỏ và chuyển sang vòng chung kết toàn cầu mở rộng của league of legends trực tiếp bao gồm các chức năng của tour du lịch thẻ ngoài ban đầu.`
        },
        {
          title: 'Giải đấu chuyên nghiệp trên đường ray',
          cont: `Vượt qua làn đạn tại các giải đấu hàng đầu của trung quốc.
          Được tổ chức bởi tencent game, thể thao lượng tử.
          CFPL đã tổ chức 15 mùa giải kể từ khi giải đầu tiên được tổ chức năm 2012.`
        },
        {
          title: 'CS:GO super league',
          cont: `CS:GO super league.
          Lần đầu tiên bắt đầu vào tháng 3 năm 2016.
          Được tổ chức bởi cơ quan thông tin thể thao của cơ quan quản lý thể thao quốc gia, công ty TNHH công nghệ thông tin của thượng hải huaao.`
        }
      ]
    },
          // 棋牌api
      chessAndCard: {
        title: 'Cờ API',
        homeText: 'Cung cấp một loạt các trò chơi cờ cổ điển, bao gồm các trò chơi như niu niu, chiên vàng, long hổ, chạy nhanh, tháo gỡ mìn. Cách chơi đổi mới, chú ý nhiều hơn đến sự an toàn và đáng tin cậy của trò chơi.',
        rowText1: 'Cung cấp một loạt các trò chơi cờ cổ điển, bao gồm các trò chơi như niu niu, chiên vàng, long hổ, chạy nhanh, tháo gỡ mìn. Chúng tôi thiết kế giao diện game thẩm mỹ, sáng tạo, chú ý nhiều hơn đến sự an toàn và đáng tin cậy của trò chơi. Dù bạn ở đâu, bạn chỉ cần một chiếc điện thoại di động hoặc máy tính để có thể dễ dàng bước vào hội trường game hall của chúng tôi và cạnh tranh với những người chơi khác.',
        rowText2: '',
        subhead: 'Cung cấp các loại trò chơi',
        description: '',
        dataList: [
          {
            title: 'NiuNiu',
            cont: 'Trong trò chơi, một bộ gồm 54 lá bài được chia cho mỗi người chơi năm lá. Người chơi cần chia năm lá bài ra thành ba lá và hai lá để tổng số điểm của ba lá là gấp 10, và tổng số điểm của hai lá còn lại là lớn nhất có thể.'
          },
          {
            title: 'Chiên vàng',
            cont: 'Trò chơi sử dụng một bộ tổng số 54 thẻ (bao gồm cả hai vua), mỗi người chơi được phân phối ba thẻ. Từ nhỏ đến lớn, theo thứ tự: báo, shun jin, vàng hoa, shunzi, duozi và giấy nhỏ.'
          },
          {
            title: 'Con thú chiến đấu',
            cont: 'Trò chơi sử dụng 54 thẻ (không bao gồm vua), chơi trò chơi với rồng xanh, hổ trắng, chu tước, xuanwu bốn đĩa miễn phí, chia bài bốn tấm và chia bài.'
          },
          {
            title: 'SanGong',
            cont: 'Mỗi người chơi được chia ba lá bài để so sánh kích cỡ của lá bài để quyết định thắng hay thua. Suy luận và chiến thuật tâm lý cũng là một thách thức.'
          },
          {
            title: 'Chạy nhanh',
            cont: 'Trong vở kịch, sử dụng 54 lá bài, bao gồm cả wang. Một người chơi chơi bài, những người chơi khác phải chơi bài theo thứ tự và theo đúng luật chơi hoặc không chơi bài. Cần một số may mắn và chiến lược, là một trò chơi bài cạnh tranh và giải trí.'
          },
          {
            title: '13 nước',
            cont: 'Sử dụng một bộ bài 54, bao gồm cả wang. Thương hiệu được chia thành báo, shunzi, đồng huashun, cùng một bông hoa, cặp, cao sáu.'
          },
          {
            title: 'Chiến đấu với chủ nhà',
            cont: 'Một đôi chơi thẻ tổng cộng 54 (bao gồm cả hai vua), để lại ba thẻ, thẻ xác định chủ nhà không thể nhìn thấy trước khi, chờ đợi để xác định chủ nhà, chủ nhà thẻ, và rõ ràng.'
          },
          {
            title: 'Benz BMW',
            cont: 'Trò chơi có bốn thương hiệu xe ô tô với tám thẻ đặt cược, có thể đặt bất kỳ trên tám mảng.'
          },
          {
            title: 'Mạt chược series',
            cont: 'Tổng cộng 112 lá bài.'
          },
          {
            title: 'PaiJiu',
            cont: 'Hiện tại có "nồi", "lớn 9", "nhỏ 9", "devil và chín vua" bốn trò chơi. Người chơi cần phải có chiến lược và óc phán đoán để chiến thắng.'
          },
          {
            title: 'baccarat',
            cont: 'Tám bộ bài tổng số 416 trò chơi, bắt đầu miễn phí hai thẻ, dựa trên các quy tắc phổ biến để quyết định bổ sung, bổ sung kết thúc tất cả các thẻ cộng với tổng số một chữ số, gần gũi hơn với 9 bên giành chiến thắng.'
          },
          {
            title: 'SaoLei',
            cont: '"Trang trại và nhàn rỗi", "mái tóc màu đỏ", "lấy một phong bì màu đỏ", "trả tiền cho lei" bốn trò chơi, mine-clearance game thường có một số trò chơi và cạnh tranh'
          }
        ],
        dataListTwo: [
          {
            title: 'Một trăm người, ba công tước'
          },
          {
            title: 'Cướp cổ điển gia súc'
          },
          {
            title: 'Cướp ý tưởng gia súc'
          },
          {
            title: 'Một trăm con bò'
          },
          {
            title: 'Ý tưởng thịt bò'
          },
          {
            title: 'Vô hạn bò'
          },
          {
            title: 'Một trăm người chống lại quái thú'
          },
          {
            title: 'Thịt bò cổ điển'
          },
          {
            title: 'Chạy nhanh'
          },
          {
            title: 'Một trăm con rồng và hổ'
          },
          {
            title: 'Chiên vàng'
          },
          {
            title: 'Ba công và một'
          },
          {
            title: 'Benz BMW'
          },
          {
            title: 'Ba công tối tăm'
          },
          {
            title: 'Ba công bằng vàng'
          },
          {
            title: 'baccarat'
          },
          {
            title: 'Mạt chược màu đỏ (hai người)'
          },
          {
            title: 'Mạt chược màu đỏ (bốn người)'
          }
        ]
      },
      // 彩票API
      lottory: {
        title: 'Xổ số API',
        homeText: 'API chứa các hash color, nhanh chóng mở các màu, các màu ở nước ngoài và tần số thấp màu sắc, có thể giúp người dùng đạt được các nhu cầu cho thuê xổ số chính xác, hiệu quả và thuận tiện.',
        rowText1: 'API chứa các hash color, nhanh chóng mở các màu, các màu ở nước ngoài và tần số thấp màu sắc, có thể giúp người dùng đạt được các nhu cầu cho thuê xổ số chính xác, hiệu quả và thuận tiện. API của chúng tôi cung cấp một loạt giao diện dữ liệu được tùy biến theo nhu cầu của người dùng. Dữ liệu API của chúng tôi là đầy đủ, chính xác, kịp thời, có thể giúp người dùng hiểu động lực của thị trường, phân tích các xu hướng của thị trường, tối ưu hóa các doanh nghiệp cho thuê xổ số.',
        rowText2: '',
        dataList: [
          {
            title: 'hashikam',
            content: 'Xổ số hắt là một trò chơi xổ số hoàn toàn mới dựa trên công nghệ blockchain. Quá trình xổ số là thông qua công nghệ khu vực chuỗi, hệ thống sẽ chọn ngẫu nhiên một khu vực chuỗi, bởi vì khu vực chuỗi không thể bị thay đổi, trò chơi xổ số này có thể đảm bảo kết quả xổ số xổ số là công bằng và công bằng.'
          },
          {
            title: 'Nhanh chóng mở màu',
            content: 'Là một trò xổ số tần số cao dựa trên kỹ thuật số, chơi tương tự như trò chơi xổ số truyền thống. Giải thưởng diễn ra rất nhanh, thường chỉ trong vài phút.'
          },
          {
            title: 'Màu sắc bên ngoài',
            content: 'Là các trò xổ số ở các nước khác ngoài trung quốc. Những trò xổ số này có thể cung cấp nhiều tiền thưởng hơn, nhiều trò chơi hơn và nhiều sự lựa chọn hơn so với xổ số địa phương.'
          },
          {
            title: 'Tần số thấp màu',
            content: 'Loại trò chơi này thường là một trò chơi quyết định có thắng hay không bằng cách mua xổ số số hoặc biểu tượng, chẳng hạn như một dạng xổ số, trong khi xổ số được xổ số, dựa trên số hoặc biểu tượng được chọn phù hợp với kết quả xổ số.'
          }
        ]
      },
      // 老虎机API
      tiger: {
        title: 'API máy đánh bạc',
        homeText: 'API của chúng tôi, bạn có thể dễ dàng tạo ra trò chơi máy đánh bạc của riêng bạn.',
        rowText1: 'API được phát triển bởi nhóm của chúng tôi, với độ linh hoạt cao và khả năng mở rộng. Sử dụng API của chúng tôi, bạn có thể dễ dàng tạo ra trò chơi máy đánh bạc của riêng bạn.',
        dataList: [
          {
            title: 'Máy đánh bạc chủ đề',
            contentText: 'Dựa trên nhiều chủ đề khác nhau, kể cả thần thoại cổ, vương quốc động vật và thời kỳ lịch sử.'
          },
          {
            title: 'Máy đánh bạc nhiều dây',
            contentText: 'Trong một trò chơi, người chơi có thể tăng cơ hội chiến thắng bằng nhiều dòng bồi thường. Phần thưởng là đánh dấu trên bất kỳ đường dây bồi thường nào.'
          },
          {
            title: 'Máy đánh bạc truyền thống',
            contentText: 'Để đánh vào bánh xe để được thưởng. Thường thì có rất nhiều biểu tượng và kiểu dây bồi thường khác nhau có thể dùng trong trò chơi.'
          }
        ]
      },
      // 彩票信用网
      credit: {
        title: 'Xổ số tín dụng mạng',
        homeText: 'Hỗ trợ một loạt các loại xổ số, bao gồm hash color, speedspeed instant color, speedcar và xổ số 6 và nhiều hơn nữa.',
        rowText1: 'Hỗ trợ một loạt các loại xổ số, bao gồm hash color, speedspeed instant color, speedcar và xổ số 6 và nhiều hơn nữa. Các loại xổ số khác nhau cung cấp các trò chơi và tiền thưởng khác nhau, người dùng có thể chọn các loại xổ số khác nhau và các cách cá cược khác nhau tùy theo sở thích và chiến lược cá cược của họ.',
        dataList: [
          {
            title: 'Hong Kong xổ số',
            contentText: 'Là xổ số hợp pháp duy nhất ở hồng kông. Được mở ra bởi hội đua ngựa hồng kông nhân danh công ty TNHH vé số Hong Kong jockey club. Ngày xổ số không cố định, tại 9:30 giờ tại bắc kinh.',
          },
          {
            title: 'Khí cầu may mắn',
            contentText: 'Khí cầu may mắn được ban hành độc quyền bởi hội đồng phúc lợi chung valletta cộng hòa Malta. Giải thưởng 180 lần mỗi ngày, mỗi 5 phút một lần. Mỗi đợt xổ số sẽ được công bố qua nhiều phương tiện để đảm bảo công bằng.',
          },
          {
            title: 'Canada Bess',
            contentText: 'Kết quả xổ số được lấy từ dữ liệu xổ số KENO của công ty xổ số Canada BCLC. Giải thưởng mùa hè và mùa đông thời gian là khác nhau, mỗi 3.5 riêng biệt giải thưởng, mỗi ngày số 395.',
          },
          {
            title: 'Slovakia',
            contentText: 'Sử dụng giấy phép độc quyền của bộ tài chính cộng hòa Slovakia - xổ số xổ số được phát hành bởi TIPOS national lottery. 10:55 giờ bắc kinh ~ 5:45 ngày tiếp theo giải thưởng, mỗi 5 giải.',
          },
          {
            title: 'Đua xe tốc độ cao',
            contentText: 'Speedcar được kiểm tra và giám sát bởi các công ty giấy phép cờ bạc phillipines. Ở bắc kinh 7:59 ~ 7:57 ngày hôm sau. Xổ số 864 mỗi 100 giây.',
          },
          {
            title: 'MD5 luôn luôn màu',
            contentText: 'MD5 luôn luôn màu để giới thiệu các thiết bị của các công ty cờ bạc ở châu âu, kiểm tra và giám sát bởi các công ty giấy phép cờ bạc việt nam. Giải thưởng lúc 0:00 ~ 23:59 giờ bắc kinh, giải thưởng mỗi 120 giây 720 lần một ngày.',
          },
          {
            title: 'Hassi 28',
            contentText: 'Nguồn gốc của một số giải thưởng là một giao dịch thực sự trên một chuỗi khu vực Tron, lấy các khu vực hóa-hê ở khu vực giao dịch. Mỗi giao dịch có thể được truy vấn qua ví, đảm bảo dữ liệu nguồn là hoàn toàn công bằng, công bằng và không thể bị thay đổi.',
          },
          {
            title: 'Xe đua etheric fang',
            contentText: 'Giao dịch được thực hiện trên chuỗi khối etheric fong là một dấu định danh 66 ký tự duy nhất. Lấy giá trị hột của một thông tin giao dịch chưa được xác nhận trong thời gian cố định như một hạt giống ngẫu nhiên.',
          }
        ]
      },
      // 平安支付
      payment: {
        title: 'Thanh toán hòa bình',
        homeText: 'Công cụ thanh toán hỗ trợ sử dụng thẻ ngân hàng thanh toán nhân dân tệ, làm cho quá trình thanh toán của bạn dễ dàng hơn và nhanh hơn.',
        rowText1: 'Công cụ thanh toán của chúng tôi hỗ trợ sử dụng thẻ ngân hàng thanh toán nhân dân tệ, làm cho quá trình thanh toán của bạn dễ dàng hơn và nhanh hơn. Hệ thống thanh toán của chúng tôi an toàn và đáng tin cậy, có thể đảm bảo an ninh thanh toán và tài chính của bạn. Hỗ trợ thanh toán USDT và thanh toán nhân dân tệ. Bạn có thể sử dụng thanh toán hòa bình để thanh toán nhân dân tệ hoặc tính phí USDT, cũng có thể dễ dàng trao đổi USDT thành nhân dân tệ.',
        dataList: [
          {
            title: 'An ninh cao',
            text: 'Quá trình thanh toán và giao dịch của nó được bảo vệ bởi mật mã và an ninh nghiêm ngặt.',
          },
          {
            title: 'Thuận tiện và nhanh chóng',
            text: 'Quá trình thanh toán rất đơn giản, không cần quá trình chuyển tiền hay chuyển khoản ngân hàng phức tạp.',
          },
          {
            title: 'Hoàn lại bảo hiểm',
            text: 'Nếu trách nhiệm của chúng tôi dẫn đến việc đóng băng tư pháp hai chiều, chúng tôi hứa bồi thường đầy đủ!',
          },
          {
            title: 'Giá rẻ',
            text: 'Dưới tỷ giá hối đoái thị trường, tỷ giá hối đoái, 1.5%.',
          }
        ]
      },
      // 人才招聘
      recruitment: {
        title: 'Nguồn nhân lực (HRBP)',
        homeText: 'Chúng tôi CAM kết xây dựng một văn hóa doanh nghiệp năng động, cởi mở, đa dạng, khuyến khích nhân viên phát triển bản thân, cung cấp cho nhân viên một không gian rộng mở để phát triển sự nghiệp.'
      }
  },
      // 新增人才招聘
      recruitmentLang: {
        title: 'Tuyển dụng nhân lực',
        rowText1: `Tại trung quốc, chúng tôi CAM kết xây dựng một văn hóa kinh doanh năng động, cởi mở, đa dạng, khuyến khích nhân viên phát triển bản thân, cung cấp cho nhân viên một không gian rộng mở để phát triển sự nghiệp. Là một đối tác kinh doanh của HR, bạn sẽ thiết lập một mối quan hệ tốt với quản lý, nhân viên và khu vực kinh doanh ở tất cả các cấp, thúc đẩy thực hiện chiến lược của doanh nghiệp, cho nhân viên và doanh nghiệp cùng nhau có lợi không ngừng làm việc chăm chỉ. Sau một thời gian dài, và hình thành nhóm, nhóm tuyển dụng khả năng hơn 30+ số người trong tháng, có thể cung cấp nhân viên, mang lại sự giúp đỡ không thể đo lường được.`,
        dataList: [
          {
            title: 'Quản lý nhân lực tốt hơn',
            content: 'Đảm bảo các tổ chức tuân thủ các thực hành tốt nhất về nguồn nhân lực và hỗ trợ trong việc phát triển và thi hành các chính sách và thủ tục.'
          },
          {
            title: 'Hợp tác chiến lược',
            content: 'Các nhà quản lý làm việc chặt chẽ để đảm bảo sự phù hợp giữa chiến lược nguồn nhân lực và chiến lược tổ chức và hỗ trợ đạt được mục tiêu kinh doanh.'
          },
          {
            title: 'Nâng cao trải nghiệm của nhân viên',
            content: 'Nhân viên đạt được sự thỏa mãn và hạnh phúc trong công việc và cung cấp cơ hội hỗ trợ và phát triển để tăng lòng trung thành của nhân viên với tổ chức.'
          },
          {
            title: 'Để tăng cường hiệu suất của các tổ chức',
            content: 'Có thể giúp các tổ chức đạt được các mục tiêu kinh doanh bằng cách nâng cao hiệu suất nhân viên và phát triển các kế hoạch, và bằng cách giám sát các chỉ số hiệu suất chủ chốt để xác định các chiến lược thành công.'
          },
          {
            title: 'Phản ứng nhanh hơn',
            content: 'Có thể phản ứng và giải quyết các vấn đề của nhân viên và quản lý một cách nhanh chóng, và do đó giúp các tổ chức tránh được các vấn đề và rủi ro tiềm ẩn về nguồn nhân lực.'
          }
        ]
      }  
}